import * as React from "react"
import { NavLink, useParams } from "react-router-dom"
import { Box, Heading, Text, VStack, Button, Flex } from "@chakra-ui/react"
import { ArrowBackIcon } from "@chakra-ui/icons"
import { map, isEmpty } from "lodash"
import { useFirestore } from "reactfire"
import { format } from "date-fns"

import { collection, doc, query, limit, orderBy } from "firebase/firestore"
import { useFirestoreDocumentData, useFirestoreQueryData } from "@react-query-firebase/firestore"

import { usePrefix } from "../../contexts/prefix-provider"

import { BlogPostSkeleton } from "./blog-post-skeleton"
import { PostTextBlocks } from "./post-text-blocks"
import { PostBlockquoteBlock } from "./post-blockquote-block"
import { PostHorizontalLine } from "./post-horizontal-line"
import { PostImages } from "./post-images"
import { PostSticker } from "./post-sticker"
import { PostOutlink } from './post-outlink'
import { BlogListItem } from "../blogs/blog-list-item"

import { ContactUsButton } from "../contact-us-button/contact-us-button"


const authors = {
    ElMarket: "엘마켓",
    Mozzimong: "모찌몽베베",
    Dream: "꿈을더하다"
}

export function BlogPost() {

    // const prefix = usePrefix()
    const { id } = useParams()
    const prefix = usePrefix()

    const db = useFirestore()

    const colRef = collection(db, "blogs-v2")
    const docRef = doc(colRef, id)
    const featuredQ = query(colRef, orderBy("date", "desc"), limit(4))

    const featured = useFirestoreQueryData([ "blogs-v2", "featured" ], featuredQ, {}, {
        select(snapshot) {
            // console.log("featured", snapshot)
            return snapshot.map(doc => ({ ...doc, date: doc.date.toDate() }))
        }
    })

    const post = useFirestoreDocumentData([ "blogs-v2", id ], docRef, {}, {
        select(data) {
            return { ...data, date: data.date.toDate() }
        }
    })

    const contentKey = `blogs-v2/${id}/content`
    const contentQuery = query(collection(db, contentKey))

    const content = useFirestoreQueryData([ contentKey ], contentQuery)


    if (post.isLoading) return <BlogPostSkeleton/>

    const buildContent = content => {
        return map(content, (block, id) => {
                switch (block.type) {
                    case "text":
                        return <PostTextBlocks key={id} block={block}/>
                    case "blockquote":
                        return <PostBlockquoteBlock key={id} block={block}/>
                    case "horizontal-line":
                        return <PostHorizontalLine key={id} block={block}/>
                    case "image":
                        return <PostImages key={id} block={block}/>
                    case "sticker":
                        return <PostSticker key={id} block={block}/>
                    case "outlink":
                        return <PostOutlink key={id} block={block}/>
                    default:
                        return <Box key={id} w={"full"} h={6} bg={"red.200"} mb={1}>{block.type}</Box>
                }
            }
        )
    }

    return <Box>

        <Box
            className={"post-container"}
            as={"article"}
            textAlign={"center"}
            px={[ 3, 6, 8 ]}
            py={{ base: 8 }}
            mx={"auto"}
            w={[ "full" ]}
            maxW={"3xl"}
            wordBreak={"keep-all"}
        >
            <Heading
                className={"post-title"}
                fontSize={"2xl"}
                lineHeight={1.4}
                fontWeight={"medium"}
                letterSpacing={"tight"}
                mb={4}
            >{`꿈을더하다 - ${post.data.title}`}</Heading>
            <Text
                className={"post-author"}
                fontSize={"md"}
                fontWeight={"medium"}
                pb={2}
            >{authors[post.data.market]}</Text>
            <Text
                fontSize={"sm"}
                color={"muted"}
                mb={10}
            >{format(post.data.date, "yyyy. MM. dd. hh:mm")}</Text>
            {!(content.isIdle || content.isLoading) && <Box className={'post-content'}>
                {
                    buildContent(content.data)
                }
            </Box>}

        </Box>
        {!featured.isLoading && !isEmpty(featured.data) &&
            <Box
                maxW={"2xl"}
                mx={"auto"}
                pb={6}>
                <Text
                    className={"post-author"}
                    fontSize={{ base: "md", md: "lg" }}
                    fontWeight={"medium"}
                    p={3}
                >추천 포스트</Text>
                <VStack
                    className={"blog-list-stack"}
                    spacing={1}
                    display={{ base: "flex" }}>
                    {
                        featured.data
                            .filter(blog => blog.logNo !== id)
                            .map((blog, idx) =>
                                <BlogListItem
                                    key={idx}
                                    blog={blog}/>
                            )
                    }
                </VStack>
            </Box>
        }
        <Flex
            maxW={"2xl"}
            mx={"auto"}
            pt={3}
            pb={{base: 2, md: 4, lg: 6}}
            px={{ base: 4, md: 0 }}
            justify={"space-between"}
        >
            <Button as={NavLink} to={prefix("/blog")} minW={"48%"} mb={2} leftIcon={<ArrowBackIcon/>}>Read More</Button>
            <ContactUsButton
                justify={"center"}
                w={"auto"}
                ml={0}
                minW={"48%"}
                btnProps={{ w: "full", size: "md" }}>
                바로 상담하기 💬
            </ContactUsButton>
        </Flex>
    </Box>
}