import * as React from "react"
import { Box } from "@chakra-ui/react"
import { SectionHeader } from "../section/section-header"

import { InstructorProfile } from "./instructor-profile"

import imgs from "../../assets/instructors/min"

export function Instructors() {

    const instructors = [
        {
            img: imgs.t01,
            imgProps: {
                alt: "장애진 강사",
                // transform: "scaleX(-1)"
            },
            bg: imgs.bg01,
            name: "장애진",
            title: "공구강사",
            quote: "인스타 공구! 업체컨택부터 진행까지, 회사가 알아서 나를 찾아오게 하는 방법을 배우세요! 안되면 될 때까지 가르쳐 드립니다.",
            color: "blue",
            qualifications: [
                "인스타 공구 매출 총 3억",
                "SNS마케팅전문가 1급",
                "CS강사 자격증",
                "호텔 영업부 경력 3년",
            ]
        },
        {
            img: imgs.t02,
            imgProps: {
                alt: "윤은영 강사"
            },
            bg: imgs.bg02,
            name: "윤은영",
            title: "사진강사",
            quote: "인스타그램은 사진공유 플랫폼입니다. 감성있는 나만의 피드, 하나부터 열까지 쉽고 빠르게 알려드릴게요~! 사진촬영법을 몰라도 괜찮아요! 저만 믿으세요♡",
            color: "brown",
            qualifications: [
                "꿈을더하다 전속 사진작가",
                "SNS마케팅전문가",
                "1급마케팅 지도사",
            ]
        },
        {
            img: imgs.t03,
            imgProps: {
                alt: "이주리 강사",
                pl: { base: 6 }
            },
            bg: imgs.bg01,
            name: "이주리",
            title: "인스타강사",
            quote: "속도보다는 방향이 더 중요합니다! 올바른 방향으로 키우는 인스타 마케팅 방법을 배워가세요 ^^ " +
                "인스타를 전혀 몰라도 괜찮습니다! 하나부터 열까지 꼼꼼히 알려드릴께요 ^^",
            color: "blue",
            qualifications: [
                "SNS마케팅전문가 1급",
                "마케팅지도사 자격증",
            ]
        },
        {
            img: imgs.t04,
            bg: imgs.bg02,
            name: "김유라",
            title: "인스타강사",
            quote: "인스타 키우는 방법 어렵지않아요! " +
                "평소에 인스타 안하시는분들&왕초보도 가능합니다! " +
                "기초부터 탄탄하게 알려드릴게요!",
            color: "brown",
            qualifications: [
                "SNS마케팅전문가 1급",
                "마케팅지도사 자격증",
            ],
            imgProps: {
                alt: "김유라 강사",
                transform: {
                    base: "scaleX(-1)",
                    lg: "none"
                }
            }
        },
        {
            img: imgs.t05,
            imgProps: {
                alt: "조선희 강사"
            },
            bg: imgs.bg01,
            name: "조선희",
            title: "판매강사",
            quote: "SNS공구마켓 운영 5년의 경력으로 " +
                "마켓 경험이 처음이어도, 감이 없어도 상품선정, 판매방법, 고객응대까지 공간제약 없이 판매하는 방법에 대해 교육해 드리겠습니다 ~!",
            color: "blue",
            qualifications: [
                "SNS마케팅전문가 1급",
                "쇼핑몰관리사 1급",
                "고객상담사 1급",
            ]
        },
        {
            img: imgs.t06,
            imgProps: {
                alt: "이지혜 강사"
            },
            bg: imgs.bg02,
            name: "이지혜",
            title: "판매강사",
            quote: "판매초보부터~심화까지! " +
                "고객의 심리를 알면,매출은 오릅니다! " +
                "판매가 잘되는 마케팅전략을 교육해요 :)",
            color: "brown",
            qualifications: [
                "SNS마케팅전문가 1급",
                "전) 호텔/리조트 인하우스 마케터",
                "전) 지방선거 SNS마케팅홍보팀",
                "현) 소상공인 마케팅 대행",
            ]
        },
        {
            img: imgs.t00,
            imgProps: {
                alt: "공구매니저"
            },
            bg: imgs.bg01,
            name: "",
            title: "공구매니저",
            quote: "인스타공구 여럽지 않아요! 배우고자 하는 마음만 가지고 있다면 누구나 이룰 수 있도록 도와드릴게요:)",
            color: "blue",
            qualifications: [
                "SNS마케팅전문가 1급",
                "POP예쁜글씨지도사자격증",
                "인스타공구 1년만에 매출 2천 이상",
            ]
        },
    ]

    return <Box
        className={"instructors-section"}
        as={"section"}
        bg={"bg2.50"}
        textAlign={"center"}
        py={{ base: 8, md: 12 }}
        px={{ base: 4 }}
        mx={"auto"}
        maxW={"4xl"}
    >
        <SectionHeader
            title={"꿈을더하다 강사진"}
            description={"수많은 판매왕을 배출한 일등공신! 여러분을 성공으로 이끌 강사진을 소개합니다."}
        />
        {
            instructors.map((instructor, i) => <InstructorProfile key={i} {...instructor} />)
        }
    </Box>
}