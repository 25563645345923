
import * as React from "react"
import { useLocation } from "react-router-dom"
import { Box } from "@chakra-ui/react"

export function ScrollNoScrollBar({ children, ...rest}) {
    const location = useLocation()
    const ref = React.useRef()

    React.useLayoutEffect(() => {
        if (!ref.current) return
        ref.current.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <Box
            w={"full"}
            h={"full"}
            overflow={"hidden"}
        >
            <Box
                ref={ref}
                w={"full"}
                h={"full"}
                overflowY={"scroll"}
                pr={"17px"}
                boxSizing={"content-box"}
                {...rest}
            >
                { children }
            </Box>
        </Box>
    )
}