import curriculum00avif from "./1-on-1_담당매니저.avif"
import curriculum00jpg from "./1-on-1_담당매니저.jpg"
import curriculum00webp from "./1-on-1_담당매니저.webp"
import curriculum01avif from "./교육카페.avif"
import curriculum01png from "./교육카페.png"
import curriculum01webp from "./교육카페.webp"
import curriculum02avif from "./인스타_마케팅_교육.avif"
import curriculum02jpg from "./인스타_마케팅_교육.jpg"
import curriculum02webp from "./인스타_마케팅_교육.webp"
import curriculum03avif from "./판매기초_심화교육.avif"
import curriculum03jpg from "./판매기초_심화교육.jpg"
import curriculum03webp from "./판매기초_심화교육.webp"

export const imgs = {
    manager: {
        avif: curriculum00avif,
        src: curriculum00jpg,
        webp: curriculum00webp,
    },
    educafe: {
        avif: curriculum01avif,
        src: curriculum01png,
        webp: curriculum01webp,
    },
    instaMarketing: {
        avif: curriculum02avif,
        src: curriculum02jpg,
        webp: curriculum02webp,
    },
    salesBasics: {
        avif: curriculum03avif,
        src: curriculum03jpg,
        webp: curriculum03webp,
    },
}
export default imgs
