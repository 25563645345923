import grandOpening00avif from "./grand_opening.avif"
import grandOpening00png from "./grand_opening.png"
import grandOpening00webp from "./grand_opening.webp"
import grandOpening01avif from "./모바일_쇼핑몰.avif"
import grandOpening01png from "./모바일_쇼핑몰.png"
import grandOpening01webp from "./모바일_쇼핑몰.webp"

export const imgs = {
    label: {
        avif: grandOpening00avif,
        src: grandOpening00png,
        webp: grandOpening00webp,
    },
    image: {
        avif: grandOpening01avif,
        src: grandOpening01png,
        webp: grandOpening01webp,
    },
}
export default imgs
