import { InputControl } from "formik-chakra-ui"
import PropTypes from "prop-types"

export function CustomInputControl(props) {
    let { label, helperText, placeholder } = props
    const { name, strings, schema, inputProps = {}, ...rest } = props
    const { spec } = schema?.fields[name] || { spec: { meta: {} } }

    label = label || spec.label || strings?.labels[name]
    helperText =
        helperText || spec.meta?.helperText || strings?.helperTexts[name]
    placeholder =
        placeholder || spec.meta?.placeholder || strings?.placeholders[name]

    return (
        <InputControl
            name={name}
            label={label}
            isRequired={schema?.fields[name]?.exclusiveTests.required}
            helperText={helperText}
            inputProps={{
                ...inputProps,
                placeholder,
                bg: "gray.50",
                variant: "outline",
            }}
            {...rest}
        />
    )
}

CustomInputControl.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    schema: PropTypes.object.isRequired,
}
