export const kakao = {
    channelUrl: "http://pf.kakao.com/_xiEMRs",
    chatUrl: "http://pf.kakao.com/_xiEMRs/chat",
    openChatUrl: "https://open.kakao.com/o/shEMRaIe",
    appKey: "6c04177402b7f0e2c64c611f9d38abce",
}

function getChatUrl(referer) {
    // return kakao.openChatUrl

    const url = new URL(kakao.chatUrl)
    referer = referer || window.location.href.split("?")[0]

    url.search = new URLSearchParams({
        api_ver: "1.1",
        kakao_agent: `sdk/1.39.16 os/javascript sdk_type/javascript lang/ko-KR device/MacIntel origin/${referer}`,
        app_key: kakao.appKey,
        referer,
    })

    return url.toString()
}

kakao.getChatUrl = getChatUrl

// (() => {
//     let url = new URL(kakao.chatUrl),
//         referer = "https://m.blog.naver.com/byebye10";
//
//     url.search =
//         new URLSearchParams({
//             api_ver: "1.1",
//             kakao_agent: `sdk/1.39.16 os/javascript sdk_type/javascript lang/ko-KR device/MacIntel origin/${referer}`,
//             app_key: kakao.appKey,
//             referer
//         })
//
//     console.log(url.toString())
//     return url.toString()
//     })()
