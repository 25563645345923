import supportTeam00avif from "./helpers.avif"
import supportTeam00png from "./helpers.png"
import supportTeam00webp from "./helpers.webp"

export const imgs = {
    support: {
        avif: supportTeam00avif,
        src: supportTeam00png,
        webp: supportTeam00webp,
    },
}
export default imgs
