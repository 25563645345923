import * as yup from "yup"

import { useValidation } from "../../../validation/use-validation"
import { useCollection } from "../../../collections/use-collection"

import { useMetadata } from "../../../contexts/metadata-provider"

export function useUsers() {
    const name = "authUsers"
    const colName = "users"
    const validation = useValidation()
    const { strings, setStrings } = useCollection()
    const metadata = useMetadata()

    const constants = {
        strings: {
            ko: {
                users: {
                    modelLabel: {
                        admin: "User",
                        dreammore: "User",
                        members: "User",
                        shop: "User",
                    },
                    title: {
                        admin: "Users",
                        dreammore: "User Profile",
                        members: "Users",
                        shop: "Users",
                    },
                    section: "Collections",
                    addNewTitle: "New User",
                    onSave: "{modelLabel} Saved",
                    onUpdate: "{modelLabel} Updated",
                    userProfile: {
                        basicInfoTitle: "Name & Profile Picture",
                        setMarketName: "Create your market name...",
                        subscribedOn: "Subscribed",
                        joinedOn: "Joined",
                        editButtonLabel: "Edit",
                    },

                    formGroupTitles: {},
                    headers: {},
                    labels: {
                        // basic info
                        name: "Name",
                        email: "Email",
                        mobile: "Mobile Phone Number",

                        // references
                        shop_id: "Shop ID",

                        // dreammore
                        market_name: "Market Name",

                        // subscriptions

                        // pausing a subscription
                        subscription_is_paused: "Subscription Paused",
                        subscription_pause_date: "Date Paused",
                        no_of_days_paused: "No. of Days Paused",
                        no_of_pause_days_remaining:
                            "No. of Pause Days Remaining",
                    },
                    placeholders: {},
                    helperTexts: {
                        market_name: "e.g., ElMarket, MozzimongBebe",
                    },
                    errorMessages: {
                        marketNameExists: "Market name already exists",
                        mobileExists: "Phone number already exists",
                    },
                },
            },
            en: {
                users: {
                    modelLabel: {
                        admin: "User",
                        dreammore: "User",
                        members: "User",
                        shop: "User",
                    },
                    title: {
                        admin: "Users",
                        dreammore: "User Profile",
                        members: "Users",
                        shop: "Users",
                    },
                    section: "Collections",

                    addNewTitle: "New User",
                    onSave: "{modelLabel} Saved",
                    onUpdate: "{modelLabel} Updated",
                    userProfile: {
                        basicInfoTitle: "Name & Profile Picture",
                        setMarketName: "Create your market name...",
                        subscribedOn: "Subscribed",
                        joinedOn: "Joined",
                        editButtonLabel: "Edit",
                    },
                    formGroupTitles: {},
                    headers: {},
                    labels: {
                        // basic info
                        name: "Name",
                        email: "Email",
                        mobile: "Mobile Phone Number",

                        // references
                        shop_id: "Shop ID",

                        // dreammore
                        market_name: "Market Name",

                        // subscriptions

                        // pausing a subscription
                        subscription_is_paused: "Subscription Paused",
                        subscription_pause_date: "Date Paused",
                        no_of_days_paused: "No. of Days Paused",
                        no_of_pause_days_remaining:
                            "No. of Pause Days Remaining",
                    },
                    placeholders: {},
                    helperTexts: {
                        market_name: "e.g., ElMarket, MozzimongBebe",
                    },
                    errorMessages: {
                        marketNameExists: "Market name already exists",
                        mobileExists: "Phone number already exists",
                    },
                },
            },
        },
    }

    function getSchema() {
        const { labels } = strings.current.users

        const schema = yup.object().shape({
            email: validation
                .defaultRequiredString()
                .email()
                .label(labels.email)
                .meta({}),
            mobile: validation
                .defaultRequiredString()
                .koreanMobile()
                .label(labels.mobile)
                .meta({}),
            name: validation
                .defaultRequiredString()
                .label(labels.name)
                .meta({}),
        })

        return metadata.addMetadata(schema)
    }

    return {
        name,
        colName,
        constants,
        strings,
        setStrings,
        getSchema,
    }
}
