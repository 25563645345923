import * as React from "react"
import { Box, Flex, AspectRatio, Skeleton } from "@chakra-ui/react"

export function BlogCardSkeleton() {
    return (
        <Box
            as={"article"}
            className={"blog-container"}
            position={"relative"}
            overflow={"hidden"}
            borderColor={"gray.200"}
            transition={"all 0.2s ease 0s"}
            bgColor={"gray.50"}
            rounded={"md"}
            shadow={"md"}
            textAlign={"left"}
            wordBreak={"keep-all"}
        >
            <Flex className={"blog-card"} direction={"column"}>
                <AspectRatio ratio={1}>
                    <Skeleton
                        startColor="bg2.50"
                        endColor="bg2.200"
                        w={"full"}
                        minW={"320px"}
                        h={"full"}
                        minH={"320px"}
                    />
                </AspectRatio>

                <Flex
                    className={"blog-details"}
                    direction={"column"}
                    py={[5, 6]}
                    px={3}
                >
                    <Flex
                        className={"blog-details-header"}
                        justify={"flex-end"}
                    >
                        <Skeleton
                            startColor="bg2.50"
                            endColor="bg2.200"
                            w={"25%"}
                            h={"14px"}
                            mb={2}
                        />
                    </Flex>
                    <Skeleton
                        startColor="bg2.50"
                        endColor="bg2.200"
                        w={"full"}
                        h={"20px"}
                        mb={1}
                    />
                    <Skeleton
                        startColor="bg2.50"
                        endColor="bg2.200"
                        w={"80%"}
                        h={"20px"}
                    />

                    <Box h={"16px"} />
                    <Skeleton
                        startColor="bg2.50"
                        endColor="bg2.200"
                        w={"full"}
                        h={"16px"}
                        mb={1}
                    />
                    <Skeleton
                        startColor="bg2.50"
                        endColor="bg2.200"
                        w={"full"}
                        h={"16px"}
                        mb={6}
                    />
                    <Flex
                        className={"blog-footer"}
                        align={"baseline"}
                        justify={"space-between"}
                        fontSize={"sm"}
                        color={"gray.600"}
                    >
                        <Skeleton
                            startColor="bg2.50"
                            endColor="bg2.200"
                            w={"30%"}
                            h={"16px"}
                            mb={3}
                        />
                        <Skeleton
                            startColor="bg2.50"
                            endColor="bg2.200"
                            w={"15%"}
                            h={"16px"}
                            mb={3}
                        />
                    </Flex>
                </Flex>
            </Flex>
        </Box>
    )
}
