import * as React from "react"
import {
    Box,
    Flex,
    VStack,
    HStack,
    AspectRatio,
    Skeleton,
} from "@chakra-ui/react"

export function TestimonialListItemSkeleton() {
    return (
        <Box
            as={"article"}
            className={"skeleton-container"}
            position={"relative"}
            overflow={"hidden"}
            transition={"all 0.2s ease 0s"}
            bgColor={"gray.50"}
            w={"full"}
        >
            <VStack
                className={"testimonial-card"}
                align={"flex-start"}
                py={[2, 6]}
                px={{ base: 4, md: 6, lg: 8 }}
            >
                <HStack
                    className={"testimonial-details-main"}
                    justify={"space-between"}
                    align={"flex-start"}
                    w={"full"}
                >
                    <Flex w={["33%", "25%"]} justify={"center"}>
                        <AspectRatio
                            w={{ base: "120px", md: "140px", lg: "160px" }}
                            ratio={1}
                        >
                            <Skeleton
                                startColor="bg2.50"
                                endColor="bg2.200"
                                w={{ base: "120px", md: "140px", lg: "160px" }}
                                h={{ base: "120px", md: "140px", lg: "160px" }}
                            />
                        </AspectRatio>
                    </Flex>
                    <VStack
                        className={"testimonial-details"}
                        w={["67%", "75%"]}
                        align={"flex-start"}
                        spacing={1}
                    >
                        <Flex w={"full"} justify={"flex-end"}>
                            <Skeleton
                                startColor="bg2.50"
                                endColor="bg2.200"
                                w={"20%"}
                                h={"16px"}
                                mb={3}
                            />
                        </Flex>
                        <Skeleton
                            startColor="bg2.50"
                            endColor="bg2.200"
                            w={"full"}
                            h={"20px"}
                        />
                        <Skeleton
                            startColor="bg2.50"
                            endColor="bg2.200"
                            w={"80%"}
                            h={"20px"}
                        />
                        <Box h={"8px"} />
                        <Skeleton
                            startColor="bg2.50"
                            endColor="bg2.200"
                            w={"full"}
                            h={"16px"}
                        />
                        <Skeleton
                            startColor="bg2.50"
                            endColor="bg2.200"
                            w={"full"}
                            h={"16px"}
                        />
                    </VStack>

                </HStack>
                <Flex
                    className={"testimonial-footer"}
                    align={"baseline"}
                    justify={"space-between"}
                    fontSize={"sm"}
                    color={"gray.600"}
                    w={"full"}
                >
                    <Skeleton
                        startColor="bg2.50"
                        endColor="bg2.200"
                        w={"30%"}
                        h={"16px"}
                        mb={3}
                    />
                    <Skeleton
                        startColor="bg2.50"
                        endColor="bg2.200"
                        w={"15%"}
                        h={"16px"}
                        mb={3}
                    />
                </Flex>
            </VStack>
        </Box>
    )
}
