import * as React from "react"
import { useLocation, useParams } from "react-router-dom"
import { Box, Flex, Heading, SimpleGrid, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import { Helmet } from "react-helmet-async"
import { useFirestoreDocumentData, useFirestoreQueryData } from "@react-query-firebase/firestore"
import { collection, doc, orderBy, query, where } from "firebase/firestore"
import { useFirestore } from "reactfire"
import { isEmpty, map } from "lodash"

import { usePrefix } from "../../contexts/prefix-provider"
import { TestimonialSkeleton } from "./testimonial-skeleton"
import { OptimizedCollageView } from "../testimonials/optimized-collage-view"
import { Author } from "../blogs/author"
import { ButtonContainer } from "../../../components/common/button-container"
import { ReadNextButton } from "../testimonials/read-next-button"
import { ContactUsButton } from "../contact-us-button/contact-us-button"
import { TestimonialListItem } from "../testimonials/testimonial-list-item"
import { SkeletonOptimizedImage } from "../../../components/optimized-image/skeleton-optimized-image"

export function Testimonial() {
    const prefix = usePrefix()
    const { tid } = useParams()
    const { href, host } = useLocation()

    const db = useFirestore()
    const colRef = collection(db, "testimonials")
    const docRef = doc(colRef, tid)

    const testimonial = useFirestoreDocumentData(["testimonials", tid], docRef)

    const featuredQ = query(colRef, where("featured", "==", true), orderBy("date", "desc"))

    const featured = useFirestoreQueryData([ "testimonials", "featured" ], featuredQ, {}, {
    })

    const width = useBreakpointValue({ base: "500px", md: "650px"})
    const height = useBreakpointValue({ base: ["200px", "100px"], md: ["260px", "130px"]})

    if (testimonial.isLoading) return <TestimonialSkeleton />

    return (
        <Box>
            <Helmet prioritizeSeoTags>
                <title>
                    꿈을더하다 -{" "}
                    {testimonial.data.title || "수강생의 100% 리얼 후기"}
                </title>
                <meta property="og:url" content={href} />
                <meta property={"og:type"} content={"article"} />
                <meta
                    property={"article:section"}
                    content={"수강생의 100% 리얼 후기"}
                />
                <meta
                    property="og:description"
                    content={"꿈을더하다는 오직 수강생의 성과로 증명합니다"}
                />
                <meta
                    property="og:image"
                    content={`https://${host}/dream-more.jpg`}
                />
            </Helmet>
            <Box
                className={"testimonial-container"}
                as={"article"}
                textAlign={"left"}
                px={[4, 6, 8]}
                pt={{ base: 6 }}
                pb={{ base: 2 }}
                mx={"auto"}
                w={["full", "2xl", "3xl"]}
                maxW={"3xl"}
                wordBreak={"keep-all"}
            >
                <Flex
                    className={"testimonial-header"}
                    direction={"column"}
                    m={0}
                >
                    <Box
                        className={"testimonial-header-container"}
                        borderBottom={"1px"}
                        borderColor={"gray.200"}
                        p={[0, 4]}
                        py={4}
                        mb={[4]}
                    >
                        <Heading
                            className={"testimonial-title"}
                            as={"h1"}
                            pb={[4]}
                            fontSize={{ base: "xl", md: "3xl", lg: "4xl" }}
                            fontWeight="bold"
                            lineHeight="1.15"
                            color={"berry.400"}
                        >
                            {testimonial.data.alias_title}
                        </Heading>
                        {testimonial.data.subtitle
                            .split("\n")
                            .map((sub, i) => (
                                <Heading
                                    className={"testimonial-subtitle"}
                                    key={i}
                                    as={"h2"}
                                    fontSize={"md"}
                                    fontWeight={"base"}
                                    color={"gray.500"}
                                    pb={1}
                                >
                                    {sub}
                                </Heading>
                            ))}
                    </Box>
                </Flex>
                <Flex
                    className={"testimonial-body"}
                    direction={"column"}
                    px={[0, null, null, 4]}
                    w={"full"}
                    mb={8}
                >
                    <Box
                        className={"testimonial-collage-container"}
                        mb={6}
                        mx={"auto"}
                        maxW={"3xl"}
                        w={"full"}
                    >
                        <OptimizedCollageView
                            key={testimonial.data.tid}
                            testimonial={testimonial.data}
                            width={width}
                            height={height}
                        />
                    </Box>
                    <Flex className={"testimonial-author-container"} mb={6}>
                        <Author
                            className={"testimonial-author"}
                            author={testimonial.data.name.slice(0, 1).padEnd(testimonial.data.name.length, "*")}
                            sx={{
                                "& .author-name": {
                                    color: "secondary.400",
                                    fontSize: "lg",
                                },
                            }}
                        />
                    </Flex>
                    <Box
                        className={"testimonial-text-container"}
                        maxW={"3xl"}
                        mx={"auto"}
                        pr={[0, 0, 6, 10]}
                        mb={[4, 4, 6]}
                        sx={{
                            "& p": {
                                mb: 4,
                                fontWeight: 300,
                                fontSize: "1rem",
                                lineHeight: 1.7,
                            },
                        }}
                    >
                        {testimonial.data.body.split("\n").map((line, e) => (
                            <Text
                                className={"testimonial-text"}
                                key={e}
                                color={"gray.700"}
                                letterSpacing={"tighter"}
                                fontSize={"md"}
                            >
                                {line}
                            </Text>
                        ))}
                    </Box>
                    <SimpleGrid
                        className={"testimonial-images-list"}
                        columns={[1]}
                        spacing={[3, 4]}
                        mb={6}
                    >
                        {map(testimonial.data.images, (img, idx) => (
                            <SkeletonOptimizedImage
                                key={`img_${idx}`}
                                img={img}
                                imgProps={{
                                    w: "full",
                                    h: "auto",
                                }}
                                // skeletonProps={{h: "400px"}}
                            />
                        ))}
                    </SimpleGrid>
                    <ButtonContainer columns={1} px={[0,6,8]} pt={[0,4,6]} pb={{base: 4, md: 8}} maxW={"xl"}>
                        <ContactUsButton
                            w={"full"}
                            btnProps={{
                                w: "full",
                                rounded: "md",
                                shadow: "md",
                            }}
                        >
                            성공후기 주인공이 되어보세요! 💬
                        </ContactUsButton>
                    </ButtonContainer>

                </Flex>

            </Box>
            {!featured.isLoading && !isEmpty(featured.data) &&
                <Box
                    maxW={"2xl"}
                    mx={"auto"}
                    pb={6}>
                    <Text
                        className={"post-author"}
                        fontSize={{ base: "md", md: "lg" }}
                        fontWeight={"medium"}
                        p={3}
                    >추천 성공후기</Text>
                    <VStack
                        className={"testimonial-list-stack"}
                        spacing={1}
                        display={{ base: "flex" }}>
                        {
                            featured.data
                                .filter(testimonial => testimonial.tid !== tid)
                                .map((testimonial, idx) =>
                                    <TestimonialListItem
                                        key={idx}
                                        testimonial={testimonial}/>
                                )
                        }
                    </VStack>
                </Box>
            }
            <ButtonContainer columns={1} px={[4,6,8]} pt={[2,4,6]} pb={{base: 4, md: 8}} maxW={"xl"} >
                <ReadNextButton
                    link={prefix(`/testimonials`)}
                />
            </ButtonContainer>
        </Box>
    )
}