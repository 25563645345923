import * as React from "react"
import { NavLink } from "react-router-dom"
import { Box, Stack, Button } from "@chakra-ui/react"
import { Recommendation } from "./recommendation"

import { SectionHeader } from "../section/section-header"

import imgs from "../../assets/recommendations/min"
import { ButtonContainer } from "../../../components/common/button-container"

import { usePrefix } from "../../contexts/prefix-provider"

export function Recommendations() {
    const prefix = usePrefix()

    return (
        <Box
            className={"recommendations-section"}
            as={"section"}
            bg={"bg2.50"}
            textAlign={"center"}
            py={{ base: 8, md: 12 }}
            px={{ base: 4 }}
        >
            <Box
                className={"recommendations-container"}
                mx={"auto"}
                maxW={"4xl"}
            >
                <SectionHeader
                    title={"인스타마켓, 이런분들께 추천해요!"}
                    description={
                        "누구나 어렵지 않게 도전할 수 있습니다. 지금 바로 도전해보세요!"
                    }
                />
                <Stack
                    py={{ base: 4 }}
                    className={"recommendations-stack"}
                    direction={{ base: "column", md: "row" }}
                >
                    <Recommendation
                        img={imgs.wfh}
                        titleNo={"01"}
                        features={[
                            "경력 단절 여성",
                            "본업과 육아의 밸런스를 맞추며 틈틈이 용돈을 벌고 싶은 분",
                            "출퇴근이 자유로운 삶을 꿈꾸며 투잡에 도전하고 싶으신 분",
                        ]}
                    />
                    <Recommendation
                        img={imgs.wlb}
                        titleNo={"02"}
                        features={[
                            "소자본으로 안전하게 창업하길 원하시는 분",
                            "내가 좋아하는 일을 하며 경제적 자유를 꿈꾸는 분",
                            "인스타를 활용해 내 마켓을 운영해보고 싶은데 방법을 모르는 분",
                        ]}
                    />
                </Stack>
                <ButtonContainer
                    columns={1}
                    px={[2, 8, 8]}
                    pt={[0, 4, 6]}
                    pb={{ base: 4, md: 8 }}
                    maxW={"xl"}
                >
                    <Button
                        w={"full"}
                        as={NavLink}
                        to={prefix("/testimonials")}
                        rounded={"md"}
                        shadow={"md"}
                        size={"lg"}
                    >
                        공구판매왕 성공후기 보러가기! 💬
                    </Button>
                </ButtonContainer>
            </Box>
        </Box>
    )
}
