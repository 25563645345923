import * as React from "react"
import { Box, Skeleton, VStack } from "@chakra-ui/react"

export function TestimonialSkeleton() {
    return (
        <Box
            h={"100vh"}
            px={[6, 6, 8]}
            py={{ base: 8 }}
            mx={"auto"}
            w={["full"]}
            maxW={"3xl"}
        >
            <VStack align={"left"}>
                <Skeleton
                    startColor="bg2.50"
                    endColor="bg2.200"
                    w={"96%"}
                    h={"22px"}
                />
                <Skeleton
                    startColor="bg2.50"
                    endColor="bg2.200"
                    w={"70%"}
                    h={"22px"}
                    mb={3}
                />
                <Box h={"20px"} />
                <Skeleton
                    startColor="bg2.50"
                    endColor="bg2.200"
                    w={"90%"}
                    h={"18px"}
                />
                <Skeleton
                    startColor="bg2.50"
                    endColor="bg2.200"
                    w={"80%"}
                    h={"18px"}
                />
                <Box h={"4px"} />
                <Skeleton
                    startColor="bg2.50"
                    endColor="bg2.200"
                    w={"90%"}
                    h={"18px"}
                />
                <Skeleton
                    startColor="bg2.50"
                    endColor="bg2.200"
                    w={"65%"}
                    h={"18px"}
                />

                <Box h={"40px"} />
                <Skeleton
                    startColor="bg2.50"
                    endColor="bg2.200"
                    p={16}
                    w={"full"}
                    h={"300px"}
                />
            </VStack>
        </Box>
    )
}