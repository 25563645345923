import { NavLink } from "react-router-dom"
import { Button } from "@chakra-ui/react"

export function ReadNextButton({ link }) {
    return (
        <Button
            onClick={(e) => {
                e.currentTarget.blur()
            }}
            as={NavLink}
            to={link}
            size={"lg"}
            colorScheme={"brand"}
            fontSize={"lg"}
            pr={2}
            rounded={"md"}
            shadow={"md"}
        >
            성공후기 더 보러가기{" "}
        </Button>
    )
}
