import React from "react"

export function useCollection() {
    const strings = React.useRef({})

    function setStrings(localized) {
        strings.current = localized
    }

    return {
        strings,
        setStrings,
    }
}
