import * as React from "react"
import { Button, Flex } from "@chakra-ui/react"
import { NavLink } from "react-router-dom"

import { kakao } from "../kakao/config"
import { ContactUsButton } from "../contact-us-button/contact-us-button"
import { usePrefix } from "../../contexts/prefix-provider"

export function PostOutlink({ block }) {
    const prefix = usePrefix()
    const linkUrl = new URL(block.link)
    const chatUrl = new URL(kakao.chatUrl)
    if (linkUrl.hostname === chatUrl.hostname) {
        return (
            <ContactUsButton
                justify={"center"}
                btnProps={{
                    variant: "outline",
                    bg: "white",
                    w: "full",
                    shadow: "md",
                    rounded: "md",
                    color: "brand.400",
                }}
            >
                바로 상담하기 💬
            </ContactUsButton>
        )
    }
    // console.log(linkUrl.hostname, window.location.hostname)
    if ([linkUrl.hostname, "localhost"].includes(window.location.hostname)) {
        if (linkUrl.pathname.endsWith("/curriculum")) {
            return (
                <Flex maxW={"full"}>
                    <Button
                        as={NavLink}
                        variant={"link"}
                        to={prefix("/curriculum")}
                        boxShadow={"none"}
                        justifyContent={"flex-start"}
                        overflowX={"hidden"}
                        maxW={"full"}
                    >
                        {block.link}
                    </Button>
                </Flex>
            )
        }
        return (
            <Flex maxW={"full"}>
                <Button
                    as={NavLink}
                    variant={"link"}
                    to={prefix("/")}
                    boxShadow={"none"}
                    justifyContent={"flex-start"}
                    overflowX={"hidden"}
                    maxW={"full"}
                >
                    Return to Home
                </Button>
            </Flex>
        )
    }

    if (linkUrl.pathname.startsWith("/byebye10")) {
        // https://m.blog.naver.com/byebye10
        const linkLabel = `${block.link.slice(0, 36)}...`
        return (
            <Flex maxW={"full"}>
                <Button
                    variant={"link"}
                    boxShadow={"none"}
                    justifyContent={"flex-start"}
                    overflowX={"hidden"}
                    maxW={"full"}
                    href={block.link}>
                    {linkLabel}
                </Button>
            </Flex>
        )
    }

    return (
        <Flex maxW={"full"}>
            <Button
                variant={"link"}
                boxShadow={"none"}
                justifyContent={"flex-start"}
                overflowX={"hidden"}
                maxW={"full"}
                href={block.link}>
                {block.link}
            </Button>
        </Flex>
    )
}
