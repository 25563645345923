import * as React from "react"
import { Helmet } from "react-helmet-async"
import { Box } from "@chakra-ui/react"

import { Hero } from "../components/hero/hero"
import { BlogList } from "../components/blogs/blog-list"
import { KakaoChannelButton } from "../components/kakao/kakao-channel-button"

export function BlogPage() {

    const { href } = window.location

    return <>
        <Helmet prioritizeSeoTags>
            <title>꿈을더하다 - 블로그</title>

            <meta property={"og:url"} content={href} />
            <meta property="og:description"
                  content={"연필 한자루 팔아본 적 없는 육아맘도, 인스타 하나로 협찬 받으며 공구마켓을 운영할 수 있습니다. 지금 바로 도전해보세요!"}/>
            <link rel="canonical" href="https://dream-more.kr/blog" />
        </Helmet>
        <Box display={{base: "none", md: "block"}}>
            <Hero />
        </Box>
        <BlogList />
        <KakaoChannelButton />
    </>
}