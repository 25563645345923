import * as React from "react"
import { Box } from "@chakra-ui/react"
import { map } from "lodash"

import { OptimizedImage } from "../../../components/optimized-image/optimized-image"

export function PostSticker({ block }) {
    return <Box
        mb={6}
        mx={"auto"}>
        {
            map(block.images, (img, id) => <Box
                key={id}
                mx={"auto"}
            >{
                img.bucket && <OptimizedImage
                    img={img}
                    imgProps={{ w: "120px", h: "auto" }}
                    pictureProps={{
                        display: "flex",
                        justifyContent: "center",
                        w: "full"
                    }}
                />
            }
                { !img.bucket && <Box key={id} w={"full"} h={6} bg={"red.200"} mb={1}>{block.type}</Box> }
            </Box>)
        }
    </Box>
}