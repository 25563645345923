import kakao00avif from "./kakao-chat-button-300x120.avif"
import kakao00png from "./kakao-chat-button-300x120.png"
import kakao00webp from "./kakao-chat-button-300x120.webp"

export const imgs = {
    avif: kakao00avif,
    src: kakao00png,
    webp: kakao00webp,
}
export default imgs
