import * as React from "react"
import { Box } from "@chakra-ui/react"

import { SectionHeader } from "../section/section-header"
import { HorizontalScroll } from "../../../components/scroll/horizontal-scroll"

import imgs from "../../assets/products/min"

export function Products() {

    return <Box
        className={"products-section"}
        as={"section"}
        bg={"white"}
        textAlign={"center"}
        py={{ base: 8, md: 12 }}>
        <Box
            className={"products-container"}
            mx={"auto"}
            maxW={"4xl"}
        >
            <SectionHeader
                title={"무제한 공구 상품 제공"}
                description={"매일 새로운 핫템들의 사진과 영상이 제공됩니다."}
                px={{ base: 6 }}
            />
            <HorizontalScroll
                className={"products-image-gallery"}
                items={imgs.map((img, i) => ({...img, alt: `상품 ${i.toString().padStart(2, "0")}`}))}
                aspect="square"
                w={"60vw"}
                maxW={"200px"}
                pl={{ base: 4 }}
            />
        </Box>
    </Box>
}