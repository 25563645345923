import * as React from "react"
import {
    Box,
    Flex,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    useDisclosure,
    useMultiStyleConfig
} from "@chakra-ui/react"
import { Spin as Hamburger } from "hamburger-react"

import { useConstants } from "../../../contexts/constants-provider"

import { ShellSidebar } from "./shell-sidebar"

export function ShellNavbar({ menuItems, scrollTop, showLogo = false }) {
    const constants = useConstants()
    const { isOpen, onToggle, onClose } = useDisclosure()
    const styles = useMultiStyleConfig("NavHeader")

    const easeLogoIn = 60
    const easeLogoOut = 100

    const logoOpacity = showLogo ? 1 : Math.min(1, Math.max(0, (scrollTop - easeLogoIn)) / (easeLogoOut - easeLogoIn))

    return (
        <Box
            width="full"
            px={{
                base: "1",
                md: "8",
            }}
            pt={2}
            pb={1}
            boxShadow="sm"
            className={"shell-navbar"}
            sx={styles.header}
        >
            <Flex justify="space-between">
                <Box px={3} pt="1" pb={2} opacity={logoOpacity}>
                    {constants.logo.navHeader}
                </Box>
                <Box zIndex={1401}>
                    <Hamburger
                        direction={"left"}
                        size={24}
                        toggled={isOpen}
                        toggle={onToggle}
                        color={isOpen ? "white" : "var(--chakra-colors-gray-800)"}

                    />
                </Box>

                <Drawer
                    isOpen={isOpen}
                    placement="left"
                    onClose={onClose}
                    isFullHeight
                    preserveScrollBarGap
                >
                    <DrawerOverlay />
                    <DrawerContent>
                        <Box
                            height="full"
                            width={{ lg: "14rem", xl: "18rem" }}
                            display={{ base: "initial", lg: "none" }}
                            overflowY="auto"
                            // borderRightWidth="1px"
                        >
                            <ShellSidebar
                                menuItems={menuItems}
                                onClose={onClose}
                            />
                        </Box>
                    </DrawerContent>
                </Drawer>
            </Flex>
        </Box>
    )
}
