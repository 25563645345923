import * as React from "react"
import {
    // Container,
    Box,
    Flex,
    // Stack,
    useBreakpointValue,
} from "@chakra-ui/react"

import { ShellNavbar } from "./shell/shell-navbar"
import { ShellSidebar } from "./shell/shell-sidebar"
import { ScrollNoScrollBar } from "../../components/scroll-no-scroll-bar"

export function MarketingShell({ menuItems, children }) {
    const isDesktop = useBreakpointValue({ base: false, lg: true })
    const [scrollTop, setScrollTop] = React.useState(0)

    return (
        <Flex
            as="section"
            direction={{
                base: "column",
                lg: "row",
            }}
            height="100dvh"
        >
            {isDesktop ? (
                <Box
                    height="full"
                    width={{ lg: "22rem", xl: "22rem" }}
                    display={{ base: "none", lg: "initial" }}
                    overflowY="auto"
                >
                    <ShellSidebar menuItems={menuItems} />
                </Box>
            ) : (
                <ShellNavbar menuItems={menuItems} scrollTop={scrollTop} />
            )}
            <ScrollNoScrollBar
                onScroll={(e) => setScrollTop(e.target.scrollTop)}
            >
                {children}
            </ScrollNoScrollBar>
        </Flex>
    )
}
