import React from "react"
import { Box, Flex, Heading, Button, Center } from "@chakra-ui/react"
import { NavLink } from "react-router-dom"

export function DemoPage({ title, to, link }) {
    return <Box h={"100vh"} className={"public"}>
        <Center>
            <Flex direction={"column"} h={"100vh"} justify={"center"}>
                <Heading mb={4}>{title}</Heading>
                <Button as={NavLink} to={to}>{link}</Button>
            </Flex>
        </Center>
    </Box>
}

export function DemoPublicPage() {
    return <DemoPage title={"Demo Public Page"} to={"/protected"} link={"Go Private"}/>
}

export function DemoProtectedPage() {
    return <DemoPage title={"Demo Private Page"} to={"/"} link={"Go Public"}/>
}
