import * as React from "react"
import { Box, Flex, Select, FormControl, FormLabel } from "@chakra-ui/react"
import { Helmet } from "react-helmet-async"

import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import { ScrollToTop } from "../../../components/scroll-to-top"
import { useTermsAndConditions } from "../../hooks/use-terms-and-conditions"



export function AgreementPage() {

    const { href } = window.location
    const { markdown, version, versions, setVersion } = useTermsAndConditions()

    return <ScrollToTop>
        <Helmet prioritizeSeoTags>
            <title>꿈을더하다 - Terms and Conditions</title>
            <meta property="og:url" content={href}/>
        </Helmet>
        <Box
            className="terms"
            fontFamily={"'Noto Sans KR'"}
            wordBreak={"keep-all"}
            maxW={"4xl"}
            px={4}
            py={8}
            mx={"auto"}
            sx={{
                "h1, h2, h3": {
                    fontFamily: "Montserrat",
                    textTransform: "uppercase",
                    marginBottom: ".5rem",
                    fontWeight: 600,
                    letterSpacing: "tighter",
                    lineHeight: 1.5,
                    // color: "#32325d"
                },
                "h1": {
                    fontSize: "1.7rem",
                },
                "h2": {
                    fontSize: "1.4rem",
                    fontFamily: "'Noto Sans KR'",
                    fontWeight: 600,
                    marginTop: "12px",
                },
                "h3": {
                    fontSize: "1.75rem"
                },
                "ul": {
                    px: 0,
                    py: 3,
                    marginBottom: "1rem",
                    listStyle: "none"
                },
                "li": {
                    mb: 2,
                    fontWeight: 300,
                    letterSpacing: "tight",
                    lineHeight: 1.4
                },
                "li span, ul li ul li span": {
                    fontWeight: 600,
                    mr: 2,
                    // color: "#32325d"
                },
                "ul li ul": {
                    my: 2,
                    px: 4
                },
                "ul li ul li span": {
                    fontWeight: 400,
                },
                "p": {
                    fontSize: "1rem",
                    fontWeight: 300,
                    lineHeight: 1.7,
                    marginBottom: ".5rem",
                    letterSpacing: "tight"
                }

            }}
        >
            { markdown.termsAndConditions && <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                children={markdown.termsAndConditions}
            /> }
            { markdown.refundPolicy && <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                children={markdown.refundPolicy}
                skipHtml
            /> }
            { markdown.privacyPolicy && <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                children={markdown.privacyPolicy}
                skipHtml
            /> }
            { markdown.useOfPersonalInformation && <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                children={markdown.useOfPersonalInformation}
                skipHtml
            /> }
            { markdown.contactUs && <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                children={markdown.contactUs}
                skipHtml
            /> }
            <Flex maxW={"lg"} mx={"auto"}>
                <FormControl>
                    <FormLabel>Version:</FormLabel>
                    <Select defaultValue={version.value} onChange={e => setVersion(versions.find(v => v.value === e.target.value)) }>
                        {
                            versions.map((version, index) => (
                                <option key={index} value={version.value}>{ version.name }</option>))
                        }
                    </Select>
                </FormControl>

            </Flex>
        </Box>
    </ScrollToTop>
}