import * as React from "react"
// import { useTheme } from "@chakra-ui/react"
// import { useThemeEditor } from "@hypertheme-editor/chakra-ui"
// import { isEqual } from "lodash"

export const SiteContext = React.createContext(null)

export function SiteProvider({ site, children }) {

    // const chakraTheme = useTheme()
    // const editor = useThemeEditor()
    // const { theme, setTheme } = editor
    //
    // React.useEffect(() => {
    //     if (chakraTheme && !isEqual(theme, chakraTheme)) setTheme(chakraTheme)
    // }, [chakraTheme, theme, setTheme])

    // console.log("SiteProvider theme", chakraTheme)
    // console.log("SiteProvider editor", editor)

    return <SiteContext.Provider value={site}>{ children }</SiteContext.Provider>
}

export const useSite = () => React.useContext(SiteContext)