import React from "react"
import {
    // mode,
    transparentize,
} from "@chakra-ui/theme-tools"

import { LogoDark, LogoLight } from "./components/logo"
import { AppLayout } from "./app/app-layout"

// import { useUsers } from "../collections/users/use-users"

export function useDreammore() {

    const name = "site"
    const layout = <AppLayout/>

    // const publicLayout = <AppLayout />
    // const protectedLayout = <AppLayout />

    const defaultPath = "/"

    const collections = {
        // users: useUsers()
    }

    const auth = {
        canSignup: true,
        requiredClaims: [ "super", "admin", "manager", "instructor", "owner", "member" ],
        defaultAuthRoute: "signup",
        usersCol: "users",
        signupPage: null,
    }

    const [ logo, /* setLogo */ ] = React.useState({
        dark: <LogoDark/>,
        light: <LogoLight/>,
        login: <LogoLight />,
        loading: <LogoLight />,
        defaultLogo: <LogoLight />,
    })

    const getLogo = name => logo[name] || logo.defaultLogo

    const [ constants, /* setConstants */ ] = React.useState({
            auth: {
                signupPage: true,
                loginPage: true,
                requiredClaims: [ "super", "admin", "manager", "instructor", "owner", "member" ],
                defaultAuthRoute: "login",
                usersCol: "users"
            },
            components: {},
            site: {},
            strings: {
                ko: {
                    auth: {
                        loginPage: {
                            // headline: "Welcome back Dream Family!",
                            // title: "Dream More+",
                            headline: "",
                            subheadline: "",
                            description: "",

                        },
                        signupPage: {
                            title: "Join the Dream family!",
                            isMember: "이미 회원가입이 되어 있으세요?",
                            loginLink: "로그인 하기"
                        },
                    }
                },
                en: {
                    auth: {
                        loginPage: {
                            // headline: "Welcome back Dream Family!",
                            // title: "Dream More+",
                            headline: "",
                            subheadline: "",
                            description: ""
                        },
                        signupPage: {
                            title: "Join the Dream family!",
                            isMember: "Already have an account?",
                            loginLink: "Login here"
                        },
                    }
                }
            }
        }
    )

    const ui = {
        accentColor: [ 'brand.500', 'brand.300' ],
        mutedColor: [ 'whiteAlpha.900', 'whiteAlpha.800' ],
        activeColor: [ 'white', 'brand.500' ],
    }

    const [ themes, /* setThemes */ ] = React.useState({
        colors: {
            // brand: {
            //     50: "#ffe9eb",
            //     100: '#ffe9eb',
            //     200: '#efc5c9',
            //     300: '#dfa0a7', // main
            //     400: '#d17b84',
            //     500: '#c35562',
            //     600: '#aa3c48',
            //     700: '#852e37',
            //     800: '#5f2028',
            //     900: '#3b1217',
            // },
            // brand: {
            //     "50": "#EEF1F6",
            //     "100": "#D0D9E7",
            //     "200": "#B2C0D7",
            //     "300": "#93A7C7",
            //     "400": "#758EB8",
            //     "500": "#5776A8",
            //     "600": "#465E86",
            //     "700": "#344765",
            //     "800": "#232F43",
            //     "900": "#111822"
            // },
            secondary: {
                "50": "#F3F1F3",
                "100": "#DDD8DE",
                "200": "#C7BFC9",
                "300": "#B0A6B5",
                "400": "#9A8DA0",
                "500": "#84748B",
                "600": "#6A5D6F",
                "700": "#4F4653",
                "800": "#352F37",
                "900": "#1A171C"
            },
            // brand: {
            //     "50": "#F6F1EE",
            //     "100": "#E6D9D1",
            //     "200": "#D6C1B3",
            //     "300": "#C6A995",
            //     "400": "#B69077",
            //     "500": "#A67859",
            //     "600": "#846048",
            //     "700": "#634836",
            //     "800": "#423024",
            //     "900": "#211812"
            // },
            brand: {
                "50": "#F4F1F1",
                "100": "#E0D9D7",
                "200": "#CCC0BD",
                "300": "#B8A8A3",
                "400": "#A49089",
                "500": "#90776F",
                "600": "#735F59",
                "700": "#574742",
                "800": "#3A302C",
                "900": "#1D1816"
            },
            primary: {
                50: "#ffe9eb",
                100: '#ffe9eb',
                200: '#efc5c9',
                300: '#dfa0a7', // main
                400: '#d17b84',
                500: '#c35562',
                600: '#aa3c48',
                700: '#852e37',
                800: '#5f2028',
                900: '#3b1217',
            },
            // secondary: {
            //     50: '#ffeeeb',
            //     100: '#e7d2cc',
            //     200: '#d1b6ad',  // main
            //     300: '#be998d',
            //     400: '#ab7d6d',
            //     500: '#926354',
            //     600: '#724c41',
            //     700: '#52372e',
            //     800: '#33201a',
            //     900: '#170802',
            // },
            bg: {
                50: '#ffeeeb',
                100: '#e7d2cc',
                200: '#d1b6ad',  // main
                300: '#be998d',
                400: '#ab7d6d',
                500: '#926354',
                600: '#724c41',
                700: '#52372e',
                800: '#33201a',
                900: '#170802',
            },
            bg2: {
                50: "#F5F2F0",
                100: "#E2DAD5",
                200: "#CFC3B9",
                300: "#BDAB9E",
                400: "#AA9483",
                500: "#987C67",
                600: "#796353",
                700: "#5B4A3E",
                800: "#3D3229",
                900: "#1E1915"
            }
        },
        components: {
            Alert: {
                baseStyle: {
                    color: "brand.700"
                }
            },

            AuthLayoutStyle: {
                baseStyle: {
                    background: "bg.200",
                    bgGradient: "linear(to-b, bg.300, bg.200)"
                }
            },
            AuthPageHeaderStyle: {
                baseStyle: {
                    color: "white"
                }
            },
            AuthSignupPageStyle: {
                baseStyle: {
                    color: "white"
                }
            },

            Button: {
                baseStyle: {
                    background: "brand.300",
                    color: "white"
                }
            },
            Card: {
                baseStyle: {
                    background: "white"
                }
            },

            LoginNotMemberYet: {
                baseStyle: {
                    color: "white"
                }
            },
            FullPageLoadingStyle: {
                baseStyle: {
                    background: "primary.200",
                    bgGradient: "linear(to-b, bg.300, bg.200)"
                }
            },
            LoadingStyle: {
                baseStyle: {
                    color: "white"
                }
            },

            Navbar: {
                baseStyle: {
                    bg: "white"
                }
            },
            NavButton: {
                baseStyle: props => ({
                    color: 'bg.50',
                    _hover: {
                        bg: transparentize('bg.300', 0.4)(props.theme),
                    },
                    _activeLink: {
                        color: 'brown.500',
                        bg: 'bg.100',
                    },
                })
            },
            NavbarToggle: {
                baseStyle: {
                    color: "primary.500",
                }
            },

            Sidebar: {
                baseStyle: {
                    bg: "bg.300"
                }
            }

        }
    })

    return {
        name,
        defaultPath,
        collections,
        auth,
        layout,
        // publicLayout,
        // privateLayout,
        constants,
        logo,
        getLogo,
        ui,
        themes
    }
}