import * as React from "react"
import { useOutletContext } from "react-router-dom"
import { Box } from "@chakra-ui/react"
import { motion } from "framer-motion"

import { NavHeader } from "./nav-header"
import { NavMenu } from "./nav-menu"


const slide = {
    hidden: {
        x: '-100%',
        opacity: 0,
    },
    visible: {
        x: 0,
        opacity: 1
    }
}

export function MarketingNav(props) {

    const { menu, routes, header } = { ...props, ...(useOutletContext() || {}) }

    React.useEffect(() => {
        if (menu.isOpen) window.scrollTo(0, 0)
    }, [ menu.isOpen ])


    return <Box
        className={"marketing-nav"}
        position={"relative"}
        w={"full"}
        maxW={"100vw"}>
        <Box
            className={"marketing-nav-container"}
            w={"full"}
            position={"fixed"}
            zIndex={3}>
            <NavHeader.Desktop
                menu={menu}
                routes={routes}
                header={header}
            />
            <NavHeader.Mobile
                menu={menu}
                routes={routes}
                header={header}
            />
            {/*{ menu.isOpen && <NavMenu.Desktop menu={menu} routes={routes}/> }*/}
        </Box>
        {menu.isOpen && <motion.div
            className={"marketing-nav-motion"}
            initial="hidden"
            animate="visible"
            variants={slide}>
            <Box
                className={"marketing-nav-mobile-container"}
                pt={header.height}
                overflow={"auto"}>
                <NavMenu.Mobile
                    menu={menu}
                    routes={routes}
                    header={header}/>
            </Box>
        </motion.div>
        }
    </Box>
}