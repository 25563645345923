import LocalizedStrings from "react-localization"
import { merge, omit, keys, map } from "lodash"

export const constants = {
    auth: {},
    components: {},
    site: {},
    strings: { ko: {}, en: {} },

    fuego: {
        protectedFields: [ "__snapshot", "id", "exists", "hasPendingWrites" ],
        callables: [ "createUser", "ensureMobileUnique", "ensureEmailUnique", "optimizeBlogImages" ],
    },
    kakaoPixelId: "3399921952215317461"
}

export function useConstants(plugins) {

    const collectionStrings = map(merge(...plugins.map(p => p.collections || {})),
            col => col.constants.strings)

    const getStrings = () => merge(constants.strings,
        ...plugins.map(p => p.constants.strings), ...collectionStrings)

    const getConstants = () => merge(constants,
        ...plugins.map(p => omit(p.constants, "strings")))


    const strings = new LocalizedStrings(getStrings())

    keys(strings).forEach(name => {
        const plugin = plugins.find(p => p.name === name)
        if (plugin && plugin.setStrings) plugin.setStrings(strings[name])
        plugins.forEach(p => {
            const collection = keys(p.collections).find(key => key === name)
            if (collection && collection.setStrings) collection.setStrings(strings[name])
        })
    })

    return {
        ...getConstants(),
        strings
    }
}