/* eslint import/no-unresolved: 0 */
import team00avif from "./꿈을더하다_회의.avif"
import team00jpg from "./꿈을더하다_회의.jpg"
import team00webp from "./꿈을더하다_회의.webp"

export const imgs = {
    team: {
        avif: team00avif,
        src: team00jpg,
        webp: team00webp
    }
}
export default imgs