import * as React from "react"
import { Box, useStyleConfig } from "@chakra-ui/react"
import { Outlet } from "react-router-dom"

import ScrollToTop from "../../components/scroll-to-top"

import { useSite } from "../../contexts/site-provider"
import { useApp } from "../../contexts/app-provider"

// import { isUndefined } from "lodash"

export function MarketingLayout({ children }) {
    const { setState } = useApp()
    const { variant } = useSite()
    const style = useStyleConfig("AppLayout", { variant })

    setState("visibility.chat", true)
    // const toggleVisibility = (key, value) => setVisibility(prev => {
    //
    //     return {
    //         ...prev,
    //         [key]: isUndefined(value) ? !prev[key] : value,
    //     }
    // })
    // console.log("layout", variant, style)
    return (
        <ScrollToTop className={"scroll-to-top"}>
            <Box className={"marketing-layout"} minH={"100vh"} {...style}>
                {children || <Outlet />}
            </Box>
        </ScrollToTop>
    )
}
