import * as React from "react"
import { useUser as useAuthUser } from "reactfire"

import {
    Flex,
    Stack,
    VStack,
} from "@chakra-ui/react"
import { map, groupBy } from "lodash"

import { useConstants } from "../../../contexts/constants-provider"
import { usePrefix } from "../../contexts/prefix-provider"
// import { useClaims } from "../../../authentication/claims/claims-provider"

// import { ShellNavButton } from "./shell-nav-button"
// import { ShellNavProtectedButton } from "./shell-nav-protected-button"
// import { useOauthProviders } from "../../../authentication/oauth/use-oauth-providers"

// import { DividerWithText } from "../../../components/dividers/divider-with-text"
import { ShellSidebarLinkGroup } from "./shell-sidebar-link-group"
// import { SidebarUserButton } from "../../../authentication/app/sidebar-buttons/sidebar-user-button"
// import { SidebarSignoutButton } from "../../../authentication/app/sidebar-buttons/sidebar-signout-button"
// import { ShellSidebarOnboardButton } from "../../../authentication/app/sidebar-buttons/shell-sidebar-onboard-button"
// import { ShellSidebarCreateAccount } from "../../../authentication/app/sidebar-buttons/shell-sidebar-create-account"
// import { ShellSidebarSignup } from "../../../authentication/app/sidebar-buttons/shell-sidebar-signup"

export function ShellSidebar({ menuItems, onClose = () => {} }) {
    const constants = useConstants()
    const { data: currentUser, status } = useAuthUser()
    const prefix = usePrefix()
    // const { claims, hasRequiredClaims, isClaimOrGreater } = useClaims()

    const hasRequiredClaims = React.useCallback(() => true, [])

    const getGroupedMenuItems = React.useCallback((menuItems) => {
        const filteredMenuItems = menuItems
            .filter(item => hasRequiredClaims(item.requiredClaims.display))
            .filter(item => !item.footerOnly)
            .map(item => ({ ...item, path: prefix(item.path)}))
        return groupBy(filteredMenuItems, (item) => item.group || "")
    }, [hasRequiredClaims, prefix])

    const initialMenuItems = getGroupedMenuItems(menuItems)

    const [groupedMenuItems, setGroupedMenuItems] = React.useState(initialMenuItems)

    React.useEffect(() => {
        setGroupedMenuItems(getGroupedMenuItems(menuItems))
    }, [currentUser, menuItems, getGroupedMenuItems])

    console.log(groupedMenuItems)

    const makeAccountButtons = () => {
        // console.log(currentUser, claims)
        if (status === "loading") return null
        // if (currentUser && isClaimOrGreater("onboarded")) return <SidebarSignoutButton />
        // if (currentUser && isClaimOrGreater("member")) return <ShellSidebarOnboardButton />
        // if (currentUser && isClaimOrGreater("visitor")) return <ShellSidebarCreateAccount />
        // return <ShellSidebarSignup />
        return null
    }

    return (
        <Flex
            className={"shell-sidebar"}
            flex="1"
            minH="100dvh"
            boxShadow="sm"
            bg={"white"}

            maxW={{
                base: "full",
                // sm: "xs",
                md: "2xl"
            }}
            py={{
                base: "6",
                sm: "8",
            }}
            px={{
                base: "4",
                sm: "6",
            }}
        >
            <Stack
                className={"shell-sidebar-container"}
                justify="space-between"
                spacing="1"
                w="full"
            >
                <Stack
                    spacing={{
                        base: "5",
                        sm: "6",
                    }}
                    shouldWrapChildren
                >
                    {constants.logo.navHeader}
                    <Stack
                        spacing="1"
                        className={"shell-sidebar-category-container"}
                        py={{ base: 2, lg: 6 }}
                    >
                        {map(groupedMenuItems, (menuItems, group) => (
                            <ShellSidebarLinkGroup
                                key={group}
                                group={group}
                                menuItems={menuItems}
                                onClose={onClose}
                            />
                        ))}
                    </Stack>
                </Stack>

                <VStack w={"full"}>
                    {/*<SidebarUserButton onClose={onClose}/>*/}
                    { makeAccountButtons() }
                </VStack>

            </Stack>
        </Flex>
    )

}

