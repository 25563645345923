/* eslint import/no-unresolved: 0 */

import hero00avif from "./꿈을더하다_인스타그램-lg.avif"
import hero00jpg from "./꿈을더하다_인스타그램-lg.jpg"
import hero00webp from "./꿈을더하다_인스타그램-lg.webp"
import hero01avif from "./꿈을더하다_인스타그램-md.avif"
import hero01jpg from "./꿈을더하다_인스타그램-md.jpg"
import hero01webp from "./꿈을더하다_인스타그램-md.webp"
import hero02avif from "./꿈을더하다_인스타그램-sm.avif"
import hero02jpg from "./꿈을더하다_인스타그램-sm.jpg"
import hero02webp from "./꿈을더하다_인스타그램-sm.webp"
import hero03avif from "./꿈을더하다_인스타그램-xl.avif"
import hero03jpg from "./꿈을더하다_인스타그램-xl.jpg"
import hero03webp from "./꿈을더하다_인스타그램-xl.webp"
import hero04avif from "./꿈을더하다_인스타그램.avif"
import hero04jpg from "./꿈을더하다_인스타그램.jpg"
import hero04webp from "./꿈을더하다_인스타그램.webp"
import hero05avif from "./꿈을더하다_인스타그램-PC.avif"
import hero05jpg from "./꿈을더하다_인스타그램-PC.jpg"
import hero05webp from "./꿈을더하다_인스타그램-PC.webp"
import hero06avif from "./꿈을더하다_인스타그램-모바일.avif"
import hero06jpg from "./꿈을더하다_인스타그램-모바일.jpg"
import hero06webp from "./꿈을더하다_인스타그램-모바일.webp"

export const imgs = {
    lg: {
        avif: hero00avif,
        src: hero00jpg,
        webp: hero00webp,
    },
    md: {
        avif: hero01avif,
        src: hero01jpg,
        webp: hero01webp,
    },
    sm: {
        avif: hero02avif,
        src: hero02jpg,
        webp: hero02webp,
    },
    xl: {
        avif: hero03avif,
        src: hero03jpg,
        webp: hero03webp,
    },
    orig: {
        avif: hero04avif,
        src: hero04jpg,
        webp: hero04webp,
    },
    pc: {
        avif: hero05avif,
        src: hero05jpg,
        webp: hero05webp,
    },
    mobile: {
        avif: hero06avif,
        src: hero06jpg,
        webp: hero06webp,
    },
}

export default imgs
