import { Text } from "@chakra-ui/react"

export function Author({ author, ...rest }) {
    return (
        <Text
            className={"author-container"}
            textTransform={"uppercase"}
            color={"gray.700"}
            {...rest}
        >
            by{" "}
            <Text
                className={"author-name"}
                as={"span"}
                fontWeight={"bold"}
                fontSize={{ base: "sm", md: "md" }}
            >
                {author}
            </Text>
        </Text>
    )
}
