import React, { useEffect, useState, useMemo } from "react"

import { OptimizedReactPhotoCollageComponent } from "./optimized-react-photo-collage-component"

const createPhotoIds = (photos) => {
    return photos.map((data, i) => {
        return { ...data, id: i }
    })
}

const createLayoutPhotoMaps = (layout, photos) => {
    const newPhotos = createPhotoIds(photos)
    const newMaps = {}
    layout.reduce((accumulator, currentValue, currentIndex) => {
        newMaps[currentIndex] = newPhotos.slice(
            accumulator,
            accumulator + currentValue,
        )
        return accumulator + currentValue
    }, 0)

    return newMaps
}

const checkProps = (props) => {
    const defaultProps = {
        width: "800px",
        height: new Array(props.layout.length),
        layout: [],
        photos: [],
        showNumOfRemainingPhotos: false,
    }
    const newProps = { ...defaultProps, ...props }
    if (newProps.height.length < newProps.layout.length) {
        for (let i = 0; i < newProps.layout.length; i++) {
            newProps.height[i] = "200px"
        }
    }
    return newProps
}

export function OptimizedReactPhotoCollageContainer(props) {
    const currProps = useMemo(() => checkProps(props), [props])
    const { width, height, layout, photos, showNumOfRemainingPhotos } =
        currProps
    const layoutNum = layout.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0,
    )
    const remainingNum = photos.length - layoutNum
    const [allowRender, setAllowRender] = useState(false)
    const [layoutPhotoMaps, setLayoutPhotoMaps] = useState({})
    // const [viewerIsOpen, setViewerIsOpen] = useState(false);
    // const [currentImage, setCurrentImage] = useState(0);

    useEffect(() => {
        setLayoutPhotoMaps(createLayoutPhotoMaps(layout, photos))
    }, [layout, photos])

    useEffect(() => {
        setAllowRender(Boolean(Object.keys(layoutPhotoMaps).length))
    }, [layoutPhotoMaps])

    // const openLightbox = useCallback((id) => {
    //     setCurrentImage(parseInt(id));
    //     setViewerIsOpen(true);
    // }, []);
    // const closeLightbox = useCallback(() => {
    //     setCurrentImage(0);
    //     setViewerIsOpen(false);
    // }, []);

    if (allowRender) {
        return (
            <OptimizedReactPhotoCollageComponent
                width={width}
                height={height}
                layout={layout}
                layoutPhotoMaps={layoutPhotoMaps}
                layoutNum={layoutNum}
                remainingNum={remainingNum}
                showNumOfRemainingPhotos={showNumOfRemainingPhotos}
                // openLightbox={openLightbox}
            />
        )
    }

    return null
}
