import r01j from "./main01.jpg"
import r02j from "./main02.jpg"
import r03j from "./main03.jpg"
import r04j from "./main04.jpg"
import r05j from "./main05.jpg"
import r06j from "./main06.jpg"

import r07j from "./main07.jpg"
import r08j from "./main08.jpg"
import r09j from "./main09.jpg"
import r10j from "./main10.jpg"
import r11j from "./main11.jpg"

import r12j from "./main12.jpg"
import r13j from "./main13.jpg"
import r14j from "./main14.jpg"
import r15j from "./main15.jpg"

import r01a from "./main01.avif"
import r02a from "./main02.avif"
import r03a from "./main03.avif"
import r04a from "./main04.avif"
import r05a from "./main05.avif"
import r06a from "./main06.avif"

import r07a from "./main07.avif"
import r08a from "./main08.avif"
import r09a from "./main09.avif"
import r10a from "./main10.avif"
import r11a from "./main11.avif"

import r12a from "./main12.avif"
import r13a from "./main13.avif"
import r14a from "./main14.avif"
import r15a from "./main15.avif"

import r01w from "./main01.webp"
import r02w from "./main02.webp"
import r03w from "./main03.webp"
import r04w from "./main04.webp"
import r05w from "./main05.webp"
import r06w from "./main06.webp"

import r07w from "./main07.webp"
import r08w from "./main08.webp"
import r09w from "./main09.webp"
import r10w from "./main10.webp"
import r11w from "./main11.webp"

import r12w from "./main12.webp"
import r13w from "./main13.webp"
import r14w from "./main14.webp"
import r15w from "./main15.webp"

export const reviewAssets = [
    {
        src: r01j,
        avif: r01a,
        webp: r01w,
        alt: "리얼 후기 01"
    },
    {
        src: r02j,
        avif: r02a,
        webp: r02w,
        alt: "리얼 후기 02"
    },
    {
        src: r03j,
        avif: r03a,
        webp: r03w,
        alt: "리얼 후기 03"
    },
    {
        src: r04j,
        avif: r04a,
        webp: r04w,
        alt: "리얼 후기 04"

    },
    {
        src: r05j,
        avif: r05a,
        webp: r05w,
        alt: "리얼 후기 05"
    },
    {
        src: r06j,
        avif: r06a,
        webp: r06w,
        alt: "리얼 후기 06"
    },
    {
        src: r07j,
        avif: r07a,
        webp: r07w,
        alt: "리얼 후기 07"
    },
    {
        src: r08j,
        avif: r08a,
        webp: r08w,
        alt: "리얼 후기 08"
    },
    {
        src: r09j,
        avif: r09a,
        webp: r09w,
        alt: "리얼 후기 09"
    },
    {
        src: r10j,
        avif: r10a,
        webp: r10w,
        alt: "리얼 후기 10"
    },
    {
        src: r11j,
        avif: r11a,
        webp: r11w,
        alt: "리얼 후기 11"
    },
    {
        src: r12j,
        avif: r12a,
        webp: r12w,
        alt: "리얼 후기 12"
    },
    {
        src: r13j,
        avif: r13a,
        webp: r13w,
        alt: "리얼 후기 13"
    },
    {
        src: r14j,
        avif: r14a,
        webp: r14w,
        alt: "리얼 후기 14"
    },
    {
        src: r15j,
        avif: r15a,
        webp: r15w,
        alt: "리얼 후기 15"

    },
]

export default reviewAssets
