import { Center, useStyleConfig, VStack } from "@chakra-ui/react"
import { BeatLoader } from "react-spinners"

export function AppLoading({ logo = null }) {
    const styles = useStyleConfig("FullPageLoadingStyle")
    const loaderStyles = useStyleConfig("LoadingStyle")

    return (
        <Center direction={"column"} h={"100vh"} __css={styles}>
            <Center>
                <VStack>
                    {logo}
                    <BeatLoader {...loaderStyles} />
                </VStack>
            </Center>
        </Center>
    )
}
