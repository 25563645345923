import React from "react"
import { componentsThemes } from "./components-themes"

export function useComponentsPlugin() {
    const name = "components"
    const [constants,] = React.useState({
        strings: {
            ko: {},
            en: {}
        }
    })
    return { name, constants, themes: componentsThemes }
}