import {
    Flex,
    Button,
    CloseButton,
    Container,
    // Icon,
    Image,
    Square,
    Stack,
    Text,
    useBreakpointValue,
} from '@chakra-ui/react'
import * as React from 'react'
// import { HiOutlineShoppingCart } from 'react-icons/hi'
import elmarketLogo from "../../assets/elmarket/elmarket-avatar-74x74.png"

export function ElmarketBanner() {
    const isMobile = useBreakpointValue({
        base: true,
        md: false,
    })

    return <Flex
        w={"full"}
    ><Container

        py={{
            base: '3',
            md: '2.5',
        }}
        position="relative"

    >
        {/*<CloseButton*/}
        {/*    display={{*/}
        {/*        sm: 'none',*/}
        {/*    }}*/}
        {/*    position="absolute"*/}
        {/*    right="2"*/}
        {/*    top="2"*/}
        {/*    onClick={() => setOpen(false)}*/}
        {/*/>*/}
        <Stack
            direction={{
                base: 'column',
                sm: 'row',
            }}
            justify={{base: "space-between", md: "space-around"}}
            spacing={{
                base: '3',
                md: '2',
            }}
        >
            <Stack
                spacing="4"
                direction={{
                    base: 'column',
                    md: 'row',
                }}
                align={{
                    base: 'center',
                    md: 'center',
                }}
            >
                {!isMobile && (
                    <Square size="12" bg="whiteAlpha.500" borderRadius="md">
                        <Image boxSize={"6"} src={elmarketLogo} />
                        {/*<Icon color={"brand.500"} as={HiOutlineShoppingCart} boxSize="6"/>*/}
                    </Square>
                )}
                <Stack
                    direction={{
                        // base: 'column',
                        base: "row",
                        md: 'row',
                    }}
                    spacing={{
                        base: '4',
                        // base: "0.5",
                        md: '1.5',
                    }}
                    pe={{
                        base: '4',
                        sm: '0',
                    }}
                >{isMobile && (
                    <Square size="12" bg="white" borderRadius="md">
                        <Image boxSize={"6"} src={elmarketLogo} />
                        {/*<Icon color={"brand.500"} as={HiOutlineShoppingCart} boxSize="6"/>*/}
                    </Square>
                )}
                    <Stack
                        direction={{
                            base: 'column',
                            md: 'row',
                        }}
                        spacing={{
                            base: "0.5",
                            md: '1.5',
                        }}
                        pe={{
                            base: '4',
                            sm: '0',
                        }}
                        align={{ md: "center" }}
                        fontFamily={"'Noto Sans KR'"}
                    >
                        <Text fontSize={{ base: "sm", md: "md" }} fontWeight="medium">엘마켓은 이제 (주)꿈을더하다의 자회사입니다. 🤎</Text>
                        {/*<Text fontSize={{ base: "xs", md: "md" }} color="muted">쇼핑몰 런칭! 지금 바로 확인하세요</Text>*/}
                    </Stack>
                </Stack>
            </Stack>
            <Stack
                direction={{
                    // base: 'column',
                    sm: 'row',
                }}
                spacing={{
                    base: '3',
                    sm: '2',
                }}
                align={{
                    base: 'stretch',
                    sm: 'center',
                }}
                display={{ base: "flex", lg: "none"}}
            >
                <Button
                    display={"none"}
                    as={"a"}
                    // href={mallUrl}
                    shadow={{ md: "none" }}
                    variant="brand"
                    width="full"
                    rounded={"md"}
                    size={{ base: "sm", md: "md" }}>
                    쇼핑몰 구경하기
                </Button>
                <CloseButton
                    display={{
                        base: 'none',
                        // sm: 'inline-flex',
                    }}
                />
            </Stack>
        </Stack>
    </Container></Flex>
}