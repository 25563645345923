import * as React from "react"
import { Box } from "@chakra-ui/react"

export function Dot({ isActive, onClick }) {
    return <Box
        w={"20px"}
        h={4}
        // m={"5px"}
        bg={"transparent"}
        position={"relative"}
        borderRadius={"1px"}
    >
        <Box
            as={"span"}
            onClick={onClick}
            display={"inline-block"}
            w={"8px"}
            h={"8px"}
            borderRadius={"50%"}
            bg={isActive ? "brand.400" : "gray.100"}
        />
    </Box>
}
