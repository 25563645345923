import * as React from "react"

import { Box, Flex, useStyleConfig, VStack } from "@chakra-ui/react"
import { useSite } from "../../../contexts/site-provider"
import { SubNavLinkMobileGroup } from "./sub-nav-link-group"

export function DesktopNavMenu() {
    // const { variant } = useSite()
}

export function MobileNavMenu({ routes, menu }) {
    const { variant } = useSite()
    const style = useStyleConfig("NavMenu", { variant })
    return (
        <Box
            className={"mobile-nav-menu"}
            w={"full"}
            h={"100vh"}
            overflow="auto"
            display={{
                xl: "none",
            }}
            {...style}
        >
            <VStack
                className={"mobile-nav-list"}
                align={"center"}
                color={"gray.600"}
                spacing={4}
                px={2}
            >
                <Flex w={"full"} px={3} py={3}>
                    <SubNavLinkMobileGroup routes={routes} menu={menu} />
                </Flex>
            </VStack>
        </Box>
    )
}

export const NavMenu = {
    Desktop: DesktopNavMenu,
    Mobile: MobileNavMenu,
}
