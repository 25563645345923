import * as React from "react"
import { Box } from "@chakra-ui/react"
import { Helmet } from "react-helmet-async"


import { Hero } from "../components/hero/hero"
import { Award } from "../components/award/award"
import { Reviews } from "../components/reviews/reviews"
import { GrandOpening } from "../components/grand-opening/grand-opening"
import { Products } from "../components/products/products"
import { Recommendations } from "../components/recommendations/recommendations"
import { Features } from "../components/features/features"
import { Team } from "../components/team/team"
import { InterviewCorner } from "../components/interview-corner/interview-corner"
import { Instructors } from "../components/instructors/instructors"
import { SupportTeam } from "../components/support-team/support-team"
import { Classes } from "../components/classes/classes"
import { BlogList } from "../components/blogs/blog-list"

import { Banner } from "../components/banner/banner"

import { KakaoChannelButton } from "../components/kakao/kakao-channel-button"


export function BannerPage({ mallUrl, path }) {

    const { href } = window.location

    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>꿈을더하다 - 공구교육끝판왕</title>
                <meta property={"og:url"} content={href} />
                <meta
                    property="og:description"
                    content={
                        "연필 한자루 팔아본 적 없는 육아맘도, 인스타 하나로 협찬 받으며 공구마켓을 운영할 수 있습니다. 지금 바로 도전해보세요!"
                    }
                />
                <link rel="canonical" href={`https://dream-more.kr/${path}`} />
            </Helmet>
            <Box
                className={"home-page"}
                sx={{
                    ".hero-arrow-box-container": {
                        display: "none",
                    },
                }}
            >
                <Banner mallUrl={mallUrl} />
                <Hero />
                <Box className={"home-page-container"} mx={"auto"}>
                    <Award />
                    <Reviews />
                    <GrandOpening />
                    <Products />
                    <Recommendations />
                    <Features />
                    <Team />
                    <InterviewCorner />
                    <Instructors />
                    <SupportTeam />
                    <Classes />
                    <BlogList infiniteScroll={false} />
                </Box>
                <KakaoChannelButton />
            </Box>
        </>
    )
}