import * as React from "react"
import { Box, Heading, Highlight, Text, VStack, SimpleGrid, AspectRatio, Stack, Image } from "@chakra-ui/react"
// import { SkeletonOptimizedImage } from "../../../components/optimized-image/skeleton-optimized-image"

import imgs from "../../assets/curriculum/min"

const courses = [
    {
        title: "1:1 담당매니저",
        description: "기본안내/교육 외 궁금한 점을\n" +
            "언제든지 질문할 수 있습니다.",
        img: imgs.manager,
    },
    {
        title: "인스타 마케팅 교육",
        description: "인스타 계정 생성하기\n" +
            "인스타 팔로워 늘리는 방법\n" +
            "공구 제의 및 협찬 받는 방법\n" +
            "인스타 피드 꾸미는 방법",
        img: imgs.instaMarketing,
    },
    {
        title: "판매기초 & 심화교육",
        description: "상품 업데이트 및 주문 받기 인스타로 이벤트하는 방법 어플 활용법",
        img: imgs.salesBasics,
    },
    {
        title: "교육카페",
        description: "교육카페에서는 판매노하우가 담긴 PDF자료와 영상 강의 자료가 가득하고 최신 꿀팁들이 꾸준히 업데이트 됩니다. 각종 강의신청 게시판이 있어 특강 신청이 가능합니다.",
        img: imgs.educafe,
        imgProps: {
            bg: "bg2.200"
        }
    },
    {
        title: "공구카페",
        description: "꿈을더하다 수강생만의 특권! 제공해드리는 공구 사진과 영상을 그대로 올려 간편하게 판매하세요.",
        img: imgs.educafe,
        imgProps: {
            bg: "bg2.200"
        }
    },

]

export function Curriculum() {
    return <Box
        as="section"
        bg={"bg2.50"}
        textAlign={"center"}
        py={{ base: 8, md: 12 }}
        px={{ base: 4 }}
    >
        <VStack spacing={0} px={2} pb={6}>
            <Heading
                color={"brand.700"}
                fontSize={{ base: "3xl", md: "4xl"}}
                fontWeight={"bold"}
                letterSpacing={"tight"}
                lineHeight={1.4}
                mb={2}
            >
                <Highlight
                    query={"All in One"}
                    styles={{ color: "yellow.500" }}
                >꿈을더하다 All in One 커리큘럼</Highlight>
            </Heading>
            <Text
                fontSize="md"
                letterSpacing={"tight"}
                wordBreak={"keep-all"}
                px={1}
            ><Highlight
                query={["왕초보도 공구 마스터가 되는 교육부터", "케어플랜까지"]}
                styles={{ bg: "brand.600", color: "white", px: 1, py: "1px" }}
            >
                왕초보도 공구 마스터가 되는 교육부터 케어플랜까지</Highlight>
            </Text>
        </VStack>
        <SimpleGrid columns={1} w={"full"} maxW={"4xl"} mx={"auto"}>
            {
                courses.map((c, i) => {
                    const direction = i % 2 === 0 ? "row" : "row-reverse"
                    return <Stack direction={{ base: "column", md: direction }} spacing={0} key={i}>
                        <VStack
                            h={"250px"}
                            bg={"bg2.100"}
                            w={{base: "full", md: "50%"}}
                            justify={"center"}
                            spacing={2}
                            px={8}>
                            <Text
                                fontSize={"3xl"}
                                fontWeight={"bold"}
                                wordBreak={"keep-all"}
                            >{c.title}</Text>
                            <Text
                                lineHeight={1.2}
                                fontSize={"lg"}
                                wordBreak={"keep-all"}
                            >{c.description}</Text>
                        </VStack>
                        <AspectRatio ratio={16 / 9} h={"250px"} w={{base: "full", md: "50%"}}>
                            <Image
                                src={c.img.src}
                                {...c.imgProps}
                                alt={c.title}
                            />
                        </AspectRatio>
                    </Stack>
                })
            }
        </SimpleGrid>

    </Box>
}