import * as React from "react"
import {
    Box,
    Text,
    VStack,
    ListItem,
    ListIcon,
    List,
    Highlight,
    Flex,
    Badge,
    AspectRatio,
} from "@chakra-ui/react"
import { FaCheckCircle } from "react-icons/fa"

import { SkeletonOptimizedImage } from "../../../components/optimized-image/skeleton-optimized-image"
import { ButtonContainer } from "../../../components/common/button-container"
import { ContactUsButton } from "../contact-us-button/contact-us-button"

import imgs from "../../assets/grand_opening/min"

export function GrandOpening() {
    return (
        <Box
            className={"grand-opening-section"}
            as={"section"}
            mx={"auto"}
            bg={"bg2.50"}
            maxW={"3xl"}
            pb={{ base: 4, lg: 12 }}
        >
            <Flex
                className={"grand-opening-container"}
                direction={{ base: "column", lg: "row-reverse" }}
                bg={"bg2.50"}
                py={{ base: 8 }}
                pt={{ base: 12 }}
                px={{ base: "inherit", lg: 2 }}
                align={"center"}
                justify={{ base: "flex-start", lg: "space-between" }}
            >
                <SkeletonOptimizedImage
                    img={imgs.image}
                    imgProps={{
                        className: "grand-opening-image",
                        maxW: { base: "350px", lg: "450px" },
                        w: { base: "full", lg: "450px" },
                        h: "auto",
                    }}
                    skeletonProps={{ mb: 2 }}
                />

                <VStack
                    className={"grand-opening-vstack"}
                    align={"flex-start"}
                    px={{ base: 6, lg: 6 }}
                >
                    <Flex
                        className={"grand-opening-badge-container"}
                        w={"full"}
                        justify={"center"}
                    >
                        <Badge
                            className={"grand-opening-badge"}
                            py={0}
                            px={2}
                            fontSize={"lg"}
                            size={"lg"}
                            bg={"transaparent"}
                            borderColor={"gray.600"}
                            borderStyle={"solid"}
                            borderWidth={"1px"}
                        >
                            2023년 1월 쇼핑몰
                        </Badge>
                    </Flex>
                    <Flex
                        className={"grand-opening-text-image-container"}
                        w={"full"}
                        justify={"center"}
                    >
                        <SkeletonOptimizedImage
                            img={imgs.label}
                            imgProps={{
                                className: "grand-opening-text-image",
                                maxW: { base: "275px" },
                                mx: "auto",
                                px: 8,
                                py: 4,
                                h: "auto",
                            }}
                            skeletonProps={{ mb: 2 }}
                        />
                        {/*<Skeleton*/}
                        {/*    startColor="bg2.50"*/}
                        {/*    endColor="bg2.200"*/}
                        {/*    isLoaded={labelIsLoaded}>*/}
                        {/*    <chakra.picture>*/}
                        {/*        <Image as="source" srcSet={imgs.label.avif} type="image/avif"/>*/}
                        {/*        <Image as="source" srcSet={imgs.label.webp} type="image/webp"/>*/}
                        {/*        <Image*/}
                        {/*            className={"grand-opening-text-image"}*/}
                        {/*            onLoad={() => setLabelIsLoaded(true)}*/}
                        {/*            mx={"auto"}*/}
                        {/*            maxW={"275px"}*/}
                        {/*            px={8}*/}
                        {/*            py={4}*/}
                        {/*            src={imgs.label.src}/>*/}
                        {/*    </chakra.picture>*/}
                        {/*</Skeleton>*/}
                    </Flex>

                    <Flex
                        className={"grand-opening-description-container"}
                        w={"full"}
                        justify={"center"}
                    >
                        <Text
                            className={"grand-opening-description-text"}
                            fontWeight={"500"}
                            fontSize={"lg"}
                            mb={".25rem"}
                            color={"brand.700"}
                            wordBreak={"keep-all"}
                            lineHeight={1.2}
                        >
                            <Highlight
                                className={
                                    "grand-opening-description-highlight"
                                }
                                query={"올인원"}
                                styles={{
                                    fontWeight: "bold",
                                }}
                            >
                                꿈을더하다 올인원 패키지
                            </Highlight>
                        </Text>
                    </Flex>
                    <Flex
                        className={"grand-opening-description-text-container"}
                        w={"full"}
                        justify={"center"}
                        textAlign={"center"}
                    >
                        <Text
                            className={"grand-opening-description-text"}
                            fontWeight={"500"}
                            // fontSize={"1.1rem"}
                            mb={".25rem"}
                            color={"brand.700"}
                            wordBreak={"keep-all"}
                            lineHeight={1.2}
                        >
                            이보다 더 좋을 수는 없다!
                            <br />
                            <Highlight
                                className={
                                    "grand-opening-description-highlight"
                                }
                                query={"나만의 쇼핑물 제공"}
                                styles={{
                                    fontWeight: "bold",
                                    display: "inline-block",
                                }}
                            >
                                최고의 공동구매 교육과 나만의 쇼핑몰 제공까지!
                            </Highlight>
                        </Text>
                    </Flex>
                    <Flex
                        className={"grand-opening-todo-list-container"}
                        w={"full"}
                        justify={"center"}
                        textAlign={"center"}
                        position={"relative"}
                    >
                        <AspectRatio
                            className={"grand-opening-todo-list-image-wrapper"}
                            ratio={1}
                            w={"300px"}
                        >
                            {/*<Image*/}
                            {/*    className={"grand-opening-todo-list-image-wrapper"}*/}
                            {/*    src={postIt}*/}
                            {/*    alt={"postit"}*/}
                            {/*/>*/}
                            <Box
                                bgGradient={"linear(to-b, bg2.400, bg2.300)"}
                                shadow={"xl"}
                            />
                        </AspectRatio>
                        <VStack
                            className={"grand-opening-todo-list-container"}
                            position={"absolute"}
                            pt={9}
                        >
                            <Text
                                className={"grand-opening-todo-list-title"}
                                color={"white"}
                                fontWeight={"bold"}
                                fontSize={"lg"}
                                letterSpacing={"tight"}
                                fontFamily={"Montserrat"}
                                mb={5}
                            >
                                HARD TO DO LIST
                            </Text>
                            <List
                                className={"grand-opening-todo-list"}
                                textAlign={"left"}
                                spacing={2}
                                fontWeight={"bold"}
                                fontSize={"lg"}
                                color={"white"}
                            >
                                <ListItem
                                    className={"grand-opening-todo-list-item"}
                                >
                                    <ListIcon as={FaCheckCircle} />
                                    전문가의 핫템 선정
                                </ListItem>
                                <ListItem
                                    className={"grand-opening-todo-list-item"}
                                >
                                    <ListIcon as={FaCheckCircle} />
                                    상품 업로드
                                </ListItem>
                                <ListItem
                                    className={"grand-opening-todo-list-item"}
                                >
                                    <ListIcon as={FaCheckCircle} />
                                    송장 입력
                                </ListItem>
                                <ListItem
                                    className={"grand-opening-todo-list-item"}
                                >
                                    <ListIcon as={FaCheckCircle} />
                                    배송
                                </ListItem>
                            </List>
                        </VStack>
                    </Flex>
                    <Flex
                        className={"grand-opening-description-text-container"}
                        w={"full"}
                        justify={"center"}
                        textAlign={"center"}
                    >
                        <Text
                            className={"grand-opening-description-text"}
                            fontWeight={"500"}
                            fontSize={"1.2rem"}
                            mb={".25rem"}
                            color={"brand.700"}
                            wordBreak={"keep-all"}
                            lineHeight={1.2}
                        >
                            어려운 건 본사에서 해드립니다.
                            <br />
                            <Highlight
                                className={
                                    "grand-opening-description-text-highlight"
                                }
                                query={"편하게 판매만"}
                                styles={{
                                    fontWeight: "extrabold",
                                }}
                            >
                                수강생 여러분은 편하게 판매만 하세요!
                            </Highlight>
                        </Text>
                    </Flex>
                </VStack>
            </Flex>

            <ButtonContainer>
                <ContactUsButton
                    justify={"center"}
                    btnProps={{
                        colorScheme: "brand",
                        w: "full",
                        rounded: "md",
                        shadow: "md",
                        // bg: "bg2.300",
                    }}
                    // colorScheme={"brand"}
                    // // variant={"ghost"}
                    // bg={"bg2.300"}
                    px={4}
                    pb={3}
                >
                    더 알아보기 💬
                </ContactUsButton>
            </ButtonContainer>
        </Box>
    )
}
