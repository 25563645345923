import * as React from "react"
import { useBreakpointValue, useDisclosure } from "@chakra-ui/react"

import { useOutletOrChildrenWithProps } from "../../hooks/use-outlet-or-children-with-props"
import { PrefixProvider } from "../contexts/prefix-provider"

import { MarketingLayout } from "./marketing-layout"
import { MarketingShell } from "./marketing-shell"

export function MarketingApp({ menuItems, prefix = "", children }) {
    const menu = useDisclosure()
    const headerHeight = useBreakpointValue({ base: "58px", lg: "64px" })
    const context = { menu, menuItems, header: { height: headerHeight } }

    const outletOrChildrenWithProps = useOutletOrChildrenWithProps(
        context,
        children,
        "marketing-app",
    )

    // console.log("context", context)
    const setPrefix = React.useCallback(link => {
        // console.log(link, prefix)
        return link === "/" && prefix ? prefix : `${prefix}${link}`
    }, [prefix])


    return (
        <PrefixProvider value={setPrefix}>
            <MarketingLayout>
                <MarketingShell menuItems={menuItems}>
                    {outletOrChildrenWithProps}
                </MarketingShell>
            </MarketingLayout>
        </PrefixProvider>
    )
}
