import { SimpleGrid } from "@chakra-ui/react"

export function ButtonContainer({ children, ...rest }) {
    const columns = children.length ? [1, 1] : 1
    return (
        <SimpleGrid
            className={"button-container"}
            columns={columns}
            spacingX={4}
            spacingY={2}
            w={["full"]}
            mx={"auto"}
            {...rest}
        >
            {children}
        </SimpleGrid>
    )
}
