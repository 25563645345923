import * as React from "react"
import {
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    sendSignInLinkToEmail,
    sendPasswordResetEmail,
    verifyPasswordResetCode,
    confirmPasswordReset,
} from "firebase/auth"
import { useFirebase } from "../../contexts/firebase-provider"
import { useConstants } from "../../contexts/constants-provider"

const AuthContext = React.createContext({})

export const reservedClaims = [
    "aud",
    "auth_time",
    "email",
    "email_verified",
    "exp",
    "firebase",
    "iat",
    "iss",
    "sub",
    "user_id",
]

export function AuthProvider({ children }) {
    const constants = useConstants()
    // console.log("AuthProvider", constants, constants.strings.auth)
    const strings = constants.strings.auth.loginPage

    const fuego = useFirebase()

    const [currentUser, setCurrentUser] = React.useState(undefined)
    const [claims, setClaims] = React.useState(null)

    React.useEffect(() => {
        // signout()
        return onAuthStateChanged(fuego.auth, (user) => {
            if (user) {
                // console.log(user)
                user.getIdTokenResult().then((token) => {
                    setClaims(token.claims)
                    setCurrentUser(user)
                })
            } else setCurrentUser(user)
        })
    }, [fuego.auth])

    // console.log(claims)
    const value = React.useMemo(() => {
        const signin = (email, password, successCallback, errorCallback) => {
            return signInWithEmailAndPassword(fuego.auth, email, password)
                .then(() => {
                    if (successCallback) successCallback()
                })
                .catch((err) => {
                    let loginError
                    switch (err.code) {
                        case "auth/wrong-password":
                        case "auth/user-not-found":
                            loginError = {
                                title: "",
                                message: strings.unknownEmailPassword,
                            }
                            break
                        default:
                            loginError = {
                                title: strings.somethingWentWrongTitle,
                                message: strings.somethingWentWrong,
                            }
                    }
                    console.warn(err.code, err)
                    if (errorCallback) errorCallback(loginError)
                })
        }

        const signout = () => {
            return signOut(fuego.auth).then(() => {
                setClaims(null)
                setCurrentUser(null)
            })
        }

        const sendSigninLink = (email, subscriberId) => {
            return sendSignInLinkToEmail(fuego.auth, email, {
                url: `http://${window.location.host}/signin/subscribers/${subscriberId}`,
            }).then(() => {
                window.localStorage.setItem("emailForSignIn", email)
            })
        }

        const sendPasswordReset = (email, onSent, onError) => {
            return sendPasswordResetEmail(fuego.auth, email, {
                url: `http://${window.location.host}/login`,
            })
                .then(onSent)
                .catch((err) => onError(err))
        }

        const verifyPasswordReset = (actionCode, onVerified, onNotVerified) => {
            return verifyPasswordResetCode(fuego.auth, actionCode)
                .then((email) => onVerified(email))
                .catch((err) => onNotVerified(err))
        }

        const resetPassword = (actionCode, password, onReset, onError) => {
            return confirmPasswordReset(fuego.auth, actionCode, password)
                .then((resp) => onReset(resp))
                .catch(onError)
        }

        return {
            currentUser,
            claims,
            signin,
            signout,
            sendSigninLink,
            sendPasswordReset,
            verifyPasswordReset,
            resetPassword,
        }
    }, [
        claims,
        currentUser,
        fuego.auth,
        strings.somethingWentWrongTitle,
        strings.somethingWentWrong,
        strings.unknownEmailPassword,
    ])

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => React.useContext(AuthContext)
