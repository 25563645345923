import * as React from "react"
import { NavLink } from "react-router-dom"
import {
    Box,
    Flex,
    Text,
    Heading,
    LinkOverlay,
    Stack,
    StackDivider,
    AspectRatio,
    VStack,
} from "@chakra-ui/react"
import { find, first, isEmpty, values } from "lodash"

// import { Author } from "../blogs/author"
import { SkeletonOptimizedImage } from "../../../components/optimized-image/skeleton-optimized-image"
import { usePrefix } from "../../contexts/prefix-provider"

export function TestimonialCard({ testimonial }) {
    const prefix = usePrefix()

    const keyImg = find(testimonial.images, "key_img") || first(values(testimonial.images))

    return (
        <Box
            as={"article"}
            className={"testimonial-container"}
            position={"relative"}
            overflow={"hidden"}
            borderColor={"gray.200"}
            transition={"all 0.2s ease 0s"}
            bgColor={"gray.50"}
            rounded={"md"}
            shadow={"md"}
            textAlign={"left"}
            wordBreak={"keep-all"}
            minH={"525px"}
        >
            <LinkOverlay
                className={"testimonial-link-overlay"}
                as={NavLink}
                to={prefix(`/testimonials/${testimonial.tid}`)}
            >
                <Flex className={"testimonial-card"} direction={"column"} h={"full"}>
                    <AspectRatio ratio={1}>
                        <SkeletonOptimizedImage
                            isLazy
                            img={keyImg}
                            imgProps={{
                                alt: "testimonial_cover_image",
                                minW: "320px",
                                h: "auto",
                            }}
                        />
                    </AspectRatio>

                    <Box
                        className={"testimonial-details"}
                        direction={"column"}
                        py={[5, 6]}
                        px={3}
                        h={"full"}
                        flex={1}
                    >
                        <Flex
                            className={"testimonial-details-header"}
                            justify={"space-between"}
                            mb={2}
                        >
                            <Stack
                                className={"testimonial-tags"}
                                direction={["row", "column", "row"]}
                                pl={1}
                                spacing={[2, 1, 3]}
                                textTransform={"uppercase"}
                                letterSpacing={"tight"}
                                fontSize={"sm"}
                                fontWeight={"bold"}
                                mb={2}
                                color={"penny.400"}
                                divider={
                                    <StackDivider borderColor="gray.200" />
                                }
                            >
                                {!isEmpty(testimonial.tags) &&
                                    testimonial.tags.map((tag) => (
                                        <Text className={"testimonial-tag"} key={tag}>
                                            {tag}
                                        </Text>
                                    ))}
                            </Stack>
                            <Text
                                className={"testimonial-date"}
                                fontSize={"xs"}
                                letterSpacing={"tighter"}
                                color={"gray.600"}
                            >
                                {testimonial.date}
                            </Text>
                        </Flex>
                        <Flex
                            justify={"space-between"}
                            direction={"column"}
                            h={"full"}
                        >
                            <VStack spacing={4} mb={8} align={"flex-start"}>
                                <Heading
                                    className={"testimonial-headline"}
                                    as={"h3"}
                                    fontWeight={"bold"}
                                    fontSize={["lg", null, null, "sm"]}
                                    lineHeight={"base"}
                                    color={"charcoal.700"}
                                >
                                    {testimonial.alias_title}
                                </Heading>
                                <Text
                                    className={"testimonial-snippet"}
                                    fontSize={["base", null, null, "sm"]}
                                    overflow={"hidden"}
                                    textOverflow={"ellipsis"}
                                    wordBreak={"break-all"}
                                    sx={{
                                        display: "-webkit-box",
                                        WebkitBoxOrient: "vertical",
                                        WebkitLineClamp: "2",
                                    }}
                                >
                                    {testimonial.body}
                                </Text>
                            </VStack>
                            {/*<Flex*/}
                            {/*    className={"testimonial-footer"}*/}
                            {/*    align={"baseline"}*/}
                            {/*    justify={"flex-start"}*/}
                            {/*    fontSize={"sm"}*/}
                            {/*    color={"gray.600"}*/}
                            {/*    pb={4}*/}
                            {/*>*/}
                            {/*    <Author*/}
                            {/*        className={"testimonial-author"}*/}
                            {/*        author={testimonial.name.slice(0, 1).padEnd(testimonial.name.length, "*")}*/}
                            {/*    />*/}
                            {/*</Flex>*/}
                        </Flex>
                    </Box>
                </Flex>
            </LinkOverlay>
        </Box>
    )
}