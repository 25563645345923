import * as React from "react"
import { Box, SimpleGrid, Stack, Text } from "@chakra-ui/react"
import { SectionHeader } from "../section/section-header"
import { SkeletonOptimizedImage } from "../../../components/optimized-image/skeleton-optimized-image"

import imgs from "../../assets/support_team/min"

const support = [
    {
        title: "교육총괄관리자",
        description: "공구교육 커리큘럼 관리",
    },
    {
        title: "교육카페/공구카페 관리자",
        description: "각 카페 관리, 신규자료 업로드",
    },
    {
        title: "쇼핑몰 관리자",
        description: "쇼핑몰 관리, 교육, 문의응대",
    },
    {
        title: "직속 디자이너",
        description: "꿈을더하다 직속 디자이너",
    },
    {
        title: "직속프로그래머",
        description: "꿈을더하다 홈페이지, 공구몰 유지보수",
    },
    {
        title: "상담실장1",
        description: "꿈을더하다 수강문의 상담 오전담당",
    },
    {
        title: "상담실장2",
        description: "꿈을더하다 수강문의 상담 오후담당",
    },
]

export function SupportTeam() {
    return <Box
        className={"support-team-section"}
        as={"section"}
        bg={"bg2.50"}
        textAlign={"center"}
        py={{ base: 8, md: 12 }}
        px={{ base: 4 }}
        mx={"auto"}
        maxW={"5xl"}
    >
        <SectionHeader
            title={"꿈을더하다를 도와주시는 분들"}
            description={"여러분들이 힘내실 수 있도록 뒤에서 열정을 다해주시는 분들이 계십니다."}
        />
        <SkeletonOptimizedImage
            img={imgs.support}
            imgProps={{
                className: "support-team-image",
                alt: "support team",
                w: "full",
                h: "auto",
                mb: "3rem",
                maxW: "2xl",
                mx: "auto",
                px: 2,
            }}
        />

        <SimpleGrid
            className={"support-team-list"}
            columns={{ base: 1, lg: 2 }}
            px={{ base: 3, md: 8 }}
            spacing={4}>
            {
                support.map((s, i) =>
                    <Stack
                        className={"support-team-list-item"}
                        key={i}
                        direction={{ base: "column", md: "row" }}
                        textAlign={"left"}
                        spacing={0}
                        align={{ base: "flex-start", lg: "flex-end" }}>
                        <Text
                            className={"support-team-title"}
                            fontWeight={"bold"}
                            lineHeight={1.5}
                            fontSize={"1.2rem"}
                            mr={2}>
                            {s.title}
                        </Text>
                        <Text
                            className={"support-team-description"}
                            lineHeight={1.5}
                            fontSize={"1.1rem"}>
                            {s.description}
                        </Text>
                    </Stack>)
            }
        </SimpleGrid>
    </Box>
}