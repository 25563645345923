import * as React from "react"
import { Heading, Text, VStack } from "@chakra-ui/react"

export function SectionHeader({ title, subtitle, description, pretitle, titleProps, textProps, ...rest }) {
    return <VStack
        className={"section-header"}
        spacing={0}
        pb={10}
        px={2}
        pt={4}
        {...rest}
    >
        {pretitle && <Text
            as={"h4"}
            className={"section-header-prologue"}
            fontSize="lg"
            color={"brand.700"}
            {...textProps}
        >
            {pretitle}
        </Text>}
        { title && <Heading
            as={"h3"}
            className={"section-header-title"}
            color={"brand.700"}
            fontSize={{ base: "3xl" }}
            fontWeight={"bold"}
            letterSpacing={"tighter"}
            mb={2}
            {...textProps}
            {...titleProps}
        >
            {title}
        </Heading> }
        { subtitle && <Heading
            as={"h4"}
            className={"section-header-subtitle"}
            color={"brand.600"}
            fontSize={{ base: "2xl" }}
            fontWeight={"semibold"}
            letterSpacing={"tighter"}
            mb={2}
            {...textProps}
        >
            {subtitle}
        </Heading> }
        {description && <Text
            className={"section-header-description"}
            fontSize="lg"
            color={"brand.600"}
            {...textProps}
        >
            {description}
        </Text>}
    </VStack>
}