import * as React from "react"
import { isEmpty, tail, map } from "lodash"
import { Box, Text } from "@chakra-ui/react"


export function PostTextBlock({ paragraph }) {

    if (isEmpty(paragraph)) return null

    const preNewLines = []
    const spans = []
    const postNewLines = []
    const elements = []

    paragraph.forEach(p => {
        if (p.data === "\n") {
            if (isEmpty(spans)) preNewLines.push(p)
            else postNewLines.push(p)
        } else spans.push(p)
    })

    let key = 0

    preNewLines.forEach(() => {
        elements.push(<Text key={key} mb={2} lineHeight={.7}> </Text>)
        key += 1
    })

    if (!isEmpty(spans)) {
        elements.push(<Text key={key} mb={1} {...spans[0].style}>
            {spans[0].data}

            {
                tail(spans).map((span, i) =>
                    <Text key={i} as="span" style={span.style}>
                        {span.data}
                    </Text>)
            }
        </Text>)
        key += 1
    }

    postNewLines.forEach(() => {
        elements.push(<Text key={key}> </Text>)
        key += 1
    })

    // console.log("elements", elements)

    return elements
}

export function PostTextBlocks({ block }) {
    return <Box mx={"auto"}>
        {
            map(block.paragraphs, (p, id) => <PostTextBlock key={id} paragraph={p}/>)
        }
    </Box>
}
