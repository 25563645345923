export const data = [{'alias_title': '공구판매왕 41기 삼****',
    'body': '안녕하세요?저는 삼형제를 키우면서 경단녀라는 생각에 집에서 할수있는 일을 찾다가 공구마켓을 시작하며 개인에게 사기도 ' + 
    '당하고 정착한곳이 바로 꿈을 더하다 였어요~\n' + 
    '\n' + 
    '시작한지 1년이 지났는데,정말 꾸준히 하면 존버하면 된다는걸 저같이 능력없는 사람도 가치있게 만들어 주는건 바로 ' + 
    '공구교육과 시스템이 달라서 라고 생각해요.\n' + 
    '\n' + 
    '무엇이든 환경이 중요하기도 한데,커리큘럼이 또 다른곳이라 공구왕 혜택도 주어지는곳 이야기를 살짝 들려 드릴까 해요^^\n' + 
    '\n' + 
    '매출 200만원 돌파!!제가 공구왕이라잖아요.감동❤️그리고 공구왕 혜택까지도 부여받게 되었어요^^\n' + 
    '\n' + 
    '공구왕은 꿈을더하다 안에서 주는 혜택인데 성과가 좋으면,보상제도 같은 거랄까요?!!\n' + 
    '\n' + 
    '혜택으로는 좋은 공구템을 꿈팸에서 직접 따오셔서 매출에 도움이 될수있게 해주는 혜택이라고 들었는데 생각만 해도 기분이 ' + 
    '업되네요😊\n' + 
    '\n' + 
    '이게 무슨일인가요?진짜 감동 바구니 입니다~저 혼자 팔았으면 전혀 불가능한 일을 꿈을더하다 안에서는 실현 가능하게 만들어 ' + 
    '주네요.\n' + 
    '\n' + 
    '판매0개도 있었던 시절의 아픔을 다 잊게 해 주네요🤭\n' + 
    '\n' + 
    '1년이 지난 지금 시점,다른 사장님들을 보면서 저도 매번 많이 배우고 많이 느끼고 하루가 다르게 성장하고 있다는 느낌을 ' + 
    '받았었는데,공유 시스템이 있어서 함께 팔아주시는데 진짜 위력 대단하죠?아무리 좋은템이 있더라도 안팔리면 무용지물인데,템도 ' + 
    '좋고 시스템이 잘되어 있는곳에서 있으니 대박쳤지 뭐예요~\n' + 
    '\n' + 
    '이 결과는 다른 사장님들과 이런 시스템이 갖춰져 있지 않았다면 불가능 했을 꺼예요~\n' + 
    '\n' + 
    '필요한 제품 가져와서 했을뿐인데,이런 좋은결과가 있으니 정말 더할 나위없이 좋더라구요!!^^\n' + 
    '\n' + 
    '좋은 환경이 주어져 있어서,동기부여도 되고 자극도 받으면서 저 또한 하루가 다르게 성장해 나가는거 같아요^^\n' + 
    '\n' + 
    '꿈을더하다 안에서 공구교육을 받고 꾸준히만 한다면 누구나 이루어낼 결과라고 자부합니다.^^\n' + 
    '\n' + 
    '앞으로의발전이 더욱더 기대되는 꿈을더하다 입니다~앞으로도 존버 할 것입니다^^\n' + 
    '\n' + 
    '성공하는자는 도전과 실행을 멈추지 않는다고 합니다~모두 파이팅 입니다^^',
    'date': '2022년 07월14일',
    'id': '30332424_2884',
    'images': {'000': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2884%2Fopt%2F000.avif',
            'bucket': 'img/testimonials/30332424_2884/000.jpg',
            'key_img': true,
            'size': 'lg',
            'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2884%2Fopt%2F000.jpeg',
            'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2884%2Fopt%2F000.webp'},
        '001': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2884%2Fopt%2F001.avif',
            'bucket': 'img/testimonials/30332424_2884/001.jpg',
            'key_img': false,
            'size': 'lg',
            'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2884%2Fopt%2F001.jpeg',
            'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2884%2Fopt%2F001.webp'},
        '002': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2884%2Fopt%2F002.avif',
            'bucket': 'img/testimonials/30332424_2884/002.jpg',
            'key_img': false,
            'size': 'lg',
            'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2884%2Fopt%2F002.jpeg',
            'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2884%2Fopt%2F002.webp'},
        '003': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2884%2Fopt%2F003.avif',
            'bucket': 'img/testimonials/30332424_2884/003.jpg',
            'key_img': false,
            'size': 'lg',
            'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2884%2Fopt%2F003.jpeg',
            'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2884%2Fopt%2F003.webp'}},
    'name': '삼형제마켓',
    'subtitle': '공구판매왕 41기의 주인공은 삼**** 사장님입니다 :-)축하드립니다 !!\n' + 
    '\n' + 
    '하단은 삼**** 사장님의 100% 리얼 솔직 후기입니다 ♡',
    'tid': '30332424_2884',
    'title': '공구판매왕 41기 삼형제마켓'},
    {'alias_title': '공구판매왕 42기 온**',
        'body': '안녕하세요! 온** 입니다 ^^제가 공구왕이 되었다니 믿기지가 않고, 감사한마음 뿐이네요.꿈팸 가입후, 5개월이 지났고, ' + 
        '교육 받고 첫 공구 진행한지는 3개월이 됐네요.짧은 시간이라면 짧지만, 그동안 꿈을 더하다 안에서많은 것을 배우고, 직접 ' + 
        '실행에 옮기며 바쁘게 살았더니 공구왕이란 타이틀까지 주셔서더 열심히 해야 할것 같아요!\n' + 
        '\n' + 
        '꿈팸처럼 체계적으로 교육받지 못한다면,꾸준히 지속할수 없었을텐데, 강사님들께 교육받고 피드백 받으며 이만큼 성장한거 ' + 
        '같아요~그리고 수많은 함께 파는 사장님들과 소통하면서 서로 으쌰으쌰하는것도 너무 좋구요💛선배 사장님들이 공구진행하는거 ' + 
        '직접 보고 배울수 있어 좋았습니다 ^^\n' + 
        '\n' + 
        '저는 본업이 있고, 아들둘까지 육아도 해야해서시작하기전에 고민 정말 많이 했는데,요즘엔 본업보다 재밌고 의지가 ' + 
        '생기네요💪꿈팸 항상 너무 감사합니다!더 열심히 하겠습니다',
        'date': '2022년 08월11일',
        'id': '30332424_2943',
        'images': {'000': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2943%2Fopt%2F000.avif',
                'bucket': 'img/testimonials/30332424_2943/000.jpg',
                'key_img': true,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2943%2Fopt%2F000.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2943%2Fopt%2F000.webp'},
            '001': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2943%2Fopt%2F001.avif',
                'bucket': 'img/testimonials/30332424_2943/001.jpg',
                'key_img': false,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2943%2Fopt%2F001.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2943%2Fopt%2F001.webp'},
            '002': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2943%2Fopt%2F002.avif',
                'bucket': 'img/testimonials/30332424_2943/002.jpg',
                'key_img': false,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2943%2Fopt%2F002.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2943%2Fopt%2F002.webp'},
            '003': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2943%2Fopt%2F003.avif',
                'bucket': 'img/testimonials/30332424_2943/003.jpg',
                'key_img': false,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2943%2Fopt%2F003.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2943%2Fopt%2F003.webp'},
            '004': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2943%2Fopt%2F004.avif',
                'bucket': 'img/testimonials/30332424_2943/004.jpg',
                'key_img': false,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2943%2Fopt%2F004.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2943%2Fopt%2F004.webp'},
            '005': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2943%2Fopt%2F005.avif',
                'bucket': 'img/testimonials/30332424_2943/005.jpg',
                'key_img': false,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2943%2Fopt%2F005.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2943%2Fopt%2F005.webp'},
            '006': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2943%2Fopt%2F006.avif',
                'bucket': 'img/testimonials/30332424_2943/006.jpg',
                'key_img': false,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2943%2Fopt%2F006.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2943%2Fopt%2F006.webp'},
            '007': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2943%2Fopt%2F007.avif',
                'bucket': 'img/testimonials/30332424_2943/007.jpg',
                'key_img': false,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2943%2Fopt%2F007.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2943%2Fopt%2F007.webp'},
            '008': {'bucket': 'img/testimonials/30332424_2943/008.gif',
                'key_img': false,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_2943%2Fopt%2F008.gif'}},
        'name': '온리윤',
        'subtitle': '공구판매왕 42기의 주인공은 온** 사장님입니다 :-)축하드립니다 !!\n' + 
        '\n' + 
        '하단은 온** 사장님의 100% 리얼 솔직 후기입니다 ♡',
        'tid': '30332424_2943',
        'title': '공구판매왕 42기 온리윤'},
    {'alias_title': '공구판매왕 43기 슈*****',
        'body': '안녕하세요?꿈을더하다에서 진짜 꿈을 더하고 있는슈*****입니다.\n' + 
        '\n' + 
        '아니 이게 무슨일이죠 ㅜㅜ 3월에 공구교육을 받고,4월에 첫공구진행 진행하면서공구왕 선정 되시는 선배님들 ' + 
        '바라보며.리스팩- 엄지척- 쌍따봉을 날리던게 엇그제 같은데 ㅠㅠ이게 무슨일이란 말입니까...제가 공구왕 이라니요 ' + 
        '!!!!  (공구교육 만세 ~!)\n' + 
        '\n' + 
        '제가 남편에게 제인생은 공구를 하기 전과한후로 나뉜다고 농담처럼 말하곤해요지금까지 해온 모든 사회생활의 학습은제가 공구를 ' + 
        '하게 되려고 워밍업한것 같다고말할정도 입니다\n' + 
        '\n' + 
        '제가 수입이 좋거나 판매가 안정적이서가 아니라이일을 할수 있음에 너무 감사하고 행복해서요. ㅠㅜ인스타로 친구를 ' + 
        '만드는일,서로 소통하고 고민을 나누는일그들에게 필요한 상품을 찾는 일,그리고 그것이 그들에도움이 되어감사의 말씀으로 ' + 
        '되돌려 받는일이모든 과정이 너무 큰 행복이랍니다\n' + 
        '\n' + 
        '퇴직후 하루하루 무료하게 살던 저를 돌이켜 보면꿈팸을 문의하고 결심하고 반나절만에 교육비 결제까지논스톱으로 진행한 ' + 
        '나자신이 너무 대견합니다.\n' + 
        '\n' + 
        '그때 만약 금액이 부담되서 포기 했다면?다음에 다시 생각해보자고 미뤄뒀다면?지금의 저는 없었을테죠.계속 그렇게 "뭔가를 ' + 
        '해야 하는데 도대체 뭘해야해? "라는 끝도 없고 출구도 없는 고민만 했겠지요고민만 하던 저를 실천하는저로 바꿔준 ' + 
        '꿈팸에게정말 감사해요그리고 꿈팸을 알게 해준 몽글블리님에게 정말 정말 감사해요~!\n' + 
        '\n' + 
        '수입은 하는만큼 늘어난다고 굳게 믿고 있어요그래서 당장의 수입이 만족스럽지 못해도 불안하지 않아요누가 처음부터 ' + 
        '잘한답니까?공구 하시는분들이 많이 생기면 뭐 어때유~~그속에서도 큰 성공을 거두시는 선배님들이바로 꿈팸에 ' + 
        '있는데요~제뒤에는 꿈팸이라는 든든한 백이있으니이거야 말로 근거있는 자신감이 아닐까요?\n' + 
        '\n' + 
        '하는만큼 늘어나고, 성실한 결과로 돌아오는데지금 당장의 수입이 뭐가 중하겠습니까잘차려진 밥상에 저를 초대해주시고 기회를 ' + 
        '주셨는데나머지는 제 몫이라고 생각하고, 초심 단디 챙겨서더욱더 분발해 보겠습니다~!\n' + 
        '\n' + 
        '그럼에도 가끔씩 인태기라고 올라치면정신 번쩍 뜨이게 해주시는 공구와 선배님들이 계시고매일매일 정성껏 준비하신 상품들이 ' + 
        '업로드 되는것 보면서 자극도 많이 받고 열정도 다시금 살아나고 해요정말 꿈팸만난것은 제인생에 큰 터닝 포인트가 아닐수 ' + 
        '없습니다\n' + 
        '\n' + 
        '이런 저런 고민이 있으신분들저같이 5개월된 신생아도 공구왕에 감히 이름을올리게 되었습니다 ㅠㅠ 하다 보니 이런 선물같은 ' + 
        '행운이 오네요아무것도 모르는 초짜인 저도 했는데이걸 보시는 여러분들도 충분히 하실수 있습니다아자아자 모두모두 화이팅 ' + 
        '넘치는 하루 되세요~!!!',
        'date': '2022년 08월30일',
        'id': '30332424_3004',
        'images': {'000': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3004%2Fopt%2F000.avif',
                'bucket': 'img/testimonials/30332424_3004/000.jpg',
                'key_img': true,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3004%2Fopt%2F000.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3004%2Fopt%2F000.webp'},
            '001': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3004%2Fopt%2F001.avif',
                'bucket': 'img/testimonials/30332424_3004/001.jpg',
                'key_img': false,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3004%2Fopt%2F001.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3004%2Fopt%2F001.webp'}},
        'name': '슈퍼하내마켓',
        'subtitle': '공구판매왕 43기의 주인공은 슈***** 사장님입니다 :-)축하드립니다 !!\n' + 
        '\n' + 
        '하단은 슈***** 사장님의 100% 리얼 솔직 후기입니다 ♡',
        'tid': '30332424_3004',
        'title': '공구판매왕 43기 슈퍼하내마켓'},
    {'alias_title': '공구판매왕 44기 두***',
        'body': '안녕하세요. 꿈을 더하다로 마켓을 오픈한지...1년 9개월 정도 된 두***입니다^^저는 꿈을 더하다를 들어오기전에 리틀 ' + 
        '***를 하는 사람한테 한 4개월간 판매를 해본적이 있었습니다.피드백도 느리고...피드백을 해주면 계속 이벤트만 ' + 
        '열어라,,피드가 재미없다 이런식으로만 말을해주고..바꿔도 딱히...효과는 보지못했습니다그래서 4개월동안 순수마진으로만 ' + 
        '15만원정도를 벌엇습니다..이벤트등에 쓰엿던 비용을 빼면 10만원도 못벌었습니다..정말 호기롭게 시작한 결과가...너무 ' + 
        '참담해서 속상하더라구요....\n' + 
        '\n' + 
        '그래서 이대로 계속 할순 없을것같아서 여기저기 검색하다가 모찌몽 대표님 인스타를 보고 상담받고 큰결심을 한후에 꿈을 ' + 
        '더하다로 들어오게 되었습니다 들어온 후의 판매수익은...지난 4개월간..했던 것보다 정말 훨씬훨씬...좋아졌습니다 ' + 
        '.여기들어온것이 신의 한수였습니다!!!사장님들이 올려주시는 상품들도 너무나 다양하고 핫템들도 많고 문의도 꾸준히 오고 ' + 
        '판매로도 이뤄지다보니 저도 모르게 신이나게되고 아 이제야내가 일을 하고있구나라고 느끼게 되었습니다.\n' + 
        '\n' + 
        'ㅎㅎ기초교육,선정하는방법,판매방법등등,자료들이 너무 꼼꼼하게 자세하게 적혀있더라구요 그 전에서는 배워보지도 못햇던... ' + 
        '선정하는방법도.. 그전에 배웠던 곳에서는 그냥 1000명 해와라~그럼 그다음 알려주겟다 이러고만 끝났는데 꿈더에서는 ' + 
        '선정하는 타겟정하는것부터 댓글도 이렇게 달아야한다 저렇게 달아야한다라고 디테일하게 알려주고 그대로 적용했더니 효과도 ' + 
        '나타나고...교육 받을 맛이 나더라구용 ㅎㅎ그리고 교육이 끝나더라고도 자료들이 있기때문에판매에 도움이 정말 많이 ' + 
        '되었습니다 ㅎ ㅎㅎ (사실..지금도..가끔식 봐요 ㅎㅎㅎ)\n' + 
        '\n' + 
        '포기하지않고 꾸준하게 내 자리에서 해오다보니 이렇게 좋은 결과도 얻게 되었네요앞으로도 쭈욱~~~~~~제 자리를 지키면서 ' + 
        '이 일을 할수있을때까지 열심히 하겠습니다지금당장 힘드시겠지만.. 분명 모두에게 햇볕이 쩅쨍 뜰날이 올겁니다감사합니다 ' + 
        '홧팅♥♥♥♥♥♥',
        'date': '2022년 09월19일',
        'id': '30332424_3076',
        'images': {'000': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3076%2Fopt%2F000.avif',
                'bucket': 'img/testimonials/30332424_3076/000.jpg',
                'key_img': true,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3076%2Fopt%2F000.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3076%2Fopt%2F000.webp'},
            '001': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3076%2Fopt%2F001.avif',
                'bucket': 'img/testimonials/30332424_3076/001.jpg',
                'key_img': false,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3076%2Fopt%2F001.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3076%2Fopt%2F001.webp'},
            '002': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3076%2Fopt%2F002.avif',
                'bucket': 'img/testimonials/30332424_3076/002.jpg',
                'key_img': false,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3076%2Fopt%2F002.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3076%2Fopt%2F002.webp'},
            '003': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3076%2Fopt%2F003.avif',
                'bucket': 'img/testimonials/30332424_3076/003.jpg',
                'key_img': false,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3076%2Fopt%2F003.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3076%2Fopt%2F003.webp'},
            '004': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3076%2Fopt%2F004.avif',
                'bucket': 'img/testimonials/30332424_3076/004.jpg',
                'key_img': false,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3076%2Fopt%2F004.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3076%2Fopt%2F004.webp'},
            '005': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3076%2Fopt%2F005.avif',
                'bucket': 'img/testimonials/30332424_3076/005.jpg',
                'key_img': false,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3076%2Fopt%2F005.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3076%2Fopt%2F005.webp'},
            '006': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3076%2Fopt%2F006.avif',
                'bucket': 'img/testimonials/30332424_3076/006.jpg',
                'key_img': false,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3076%2Fopt%2F006.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3076%2Fopt%2F006.webp'}},
        'name': '두리콩콩',
        'subtitle': '공구판매왕 44기의 주인공은두***사장님입니다 :-)축하드립니다 !!\n' + 
        '\n' + 
        '하단은두***사장님의 100% 리얼 솔직 후기입니다 ♡',
        'tid': '30332424_3076',
        'title': '공구판매왕 44기 두리콩콩'},
    {'alias_title': '공구판매왕 45기 미***',
        'body': '안녕하세요 :)저는 꿈을더하다 에서 공구교육을 받고인스타를 통해 공구마켓을 운영중인 미*** 입니다 ♡어느덧 마켓을 ' + 
        '운영한지도 , 6개월차 ♥️\n' + 
        '\n' + 
        '꿈을더하다 에서 교육받은대로 정말 꾸준히열심히 노력해온 결과공구왕까지 되는좋은 기회까지 얻게 되었답니다 : )\n' + 
        '\n' + 
        '꿈을더하다에서 공구를 시작하기전에혼자 마켓을 운영해보려고도 시도를 해봤지만평범한 육아맘인 제가 혼자하기는 참 쉽지 ' + 
        '않더라구요 ~\n' + 
        '\n' + 
        '역시 배우고 안배우고의 차이 !달라도 너무 달라요 ^_^\n' + 
        '\n' + 
        '어떻게 템을 선택해야 하는지어떻게 공구로 가져올수있는지하나하나 공구마켓 운영에 필요한 부분들을초보자도 이해할수 있게끔 ' + 
        '!여러 강의를 통해서전문적으로 알려주신 덕분에\n' + 
        '\n' + 
        '포기하지않고 꾸준히 실천하다보니이런 자리까지 갖게되는 영광을 누리게되네요♥️\n' + 
        '\n' + 
        '\n' + 
        '\n' + 
        '처음엔 진짜 사진도 어떻게 찍어야하는지조차몰라서 엉망이었지만 교육 받은 뒤에피드도 너무 예뻐지고 고객들도 한분씩 ' + 
        '늘어나게되면서이 마켓 운영이 너무 재밌어진거있죠 ! ㅎㅎ\n' + 
        '\n' + 
        '평범한 한 아이의 엄마가아이를 위해서 뭐 하나라도 더 해주고싶어서생활비에 보탬이 되고자 시작한 일이지만 ,\n' + 
        '\n' + 
        '지금은 공구마켓 운영하면서 생기는 금액들남편몰래 비상금 까지 챙겨두는 여유까지 생겼답니당♥️\n' + 
        '\n' + 
        '꿈을 더하다에서 교육 받은 뒤 ,1만 팔로워 달성 !\n' + 
        '\n' + 
        '인스타를 기존에도 운영해봤지만정말 1만 팔로워는 꿈에도 못꾸었는데얼마전 1만 팔로워도 달성했어요♥️🎉\n' + 
        '\n' + 
        '그래서 기분좋게 이번에 이벤트까지 진행도 하고공구왕이라는 타이틀까지 좋은일 가득한한주를 보냈답니다 ~~ㅎㅎ\n' + 
        '\n' + 
        '공구를 배우고 이렇게 꾸준히 운영하다보니몇십만원 상당의 물품들도무료로 지원받기도 하고\n' + 
        '\n' + 
        '또는, 공구가보다 저 저렴한 비용으로구매할수있어서 하면 할수록공구마켓을 운영하는 일너무너무 재밌는거같아요 ~~\n' + 
        '\n' + 
        '정말 포기하지않고 배운대로만 꾸준히 하다보면누구나 좋은결과로 나타내는 일이니끝까지 포기하지 않으셨으면 해요 ^_^ !\n' + 
        '\n' + 
        '시작하기까지 고민 많았지만 꿈팸 사장님들 덕분에더욱 으쌰으쌰 일하고있어요 ~~더 열심히 일하겠습니다 !\n' + 
        '\n' + 
        '\n' + 
        '\n' + 
        '늘 감사해요 꿈을더하다♥️',
        'date': '2022년 10월31일',
        'id': '30332424_3213',
        'images': {'000': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3213%2Fopt%2F000.avif',
                'bucket': 'img/testimonials/30332424_3213/000.jpg',
                'key_img': true,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3213%2Fopt%2F000.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3213%2Fopt%2F000.webp'},
            '001': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3213%2Fopt%2F001.avif',
                'bucket': 'img/testimonials/30332424_3213/001.jpg',
                'key_img': false,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3213%2Fopt%2F001.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3213%2Fopt%2F001.webp'},
            '002': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3213%2Fopt%2F002.avif',
                'bucket': 'img/testimonials/30332424_3213/002.jpg',
                'key_img': false,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3213%2Fopt%2F002.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3213%2Fopt%2F002.webp'},
            '003': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3213%2Fopt%2F003.avif',
                'bucket': 'img/testimonials/30332424_3213/003.jpg',
                'key_img': false,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3213%2Fopt%2F003.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3213%2Fopt%2F003.webp'},
            '004': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3213%2Fopt%2F004.avif',
                'bucket': 'img/testimonials/30332424_3213/004.jpg',
                'key_img': false,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3213%2Fopt%2F004.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3213%2Fopt%2F004.webp'},
            '005': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3213%2Fopt%2F005.avif',
                'bucket': 'img/testimonials/30332424_3213/005.jpg',
                'key_img': false,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3213%2Fopt%2F005.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3213%2Fopt%2F005.webp'},
            '006': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3213%2Fopt%2F006.avif',
                'bucket': 'img/testimonials/30332424_3213/006.jpg',
                'key_img': false,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3213%2Fopt%2F006.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3213%2Fopt%2F006.webp'},
            '007': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3213%2Fopt%2F007.avif',
                'bucket': 'img/testimonials/30332424_3213/007.jpg',
                'key_img': false,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3213%2Fopt%2F007.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3213%2Fopt%2F007.webp'},
            '008': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3213%2Fopt%2F008.avif',
                'bucket': 'img/testimonials/30332424_3213/008.jpg',
                'key_img': false,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3213%2Fopt%2F008.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3213%2Fopt%2F008.webp'}},
        'name': '미쁘제이',
        'subtitle': '공구판매왕 45기의 주인공은미***사장님입니다 :-)축하드립니다 !!\n' + 
        '\n' + 
        '하단은미***사장님의 100% 리얼 솔직 후기입니다 ♡',
        'tid': '30332424_3213',
        'title': '공구판매왕 45기 미쁘제이'},
    {'alias_title': '공구판매왕 46기 유***',
        'body': '안녕하세요?😀저는 <꿈을더하다>에서 공구 교육을 받고 인스타로 공유마켓을 운영중인 유*** 율이맘이에요^^\n' + 
        '\n' + 
        '\n' + 
        '\n' + 
        '유치원 교사/원감으로 15년을 근무하고 임신/출산으로 퇴사해 육아에 전념을 하다가꿈을더하다를 만나 인스타 공구를 시작한지 ' + 
        '7개월째!!\n' + 
        '\n' + 
        '\n' + 
        '\n' + 
        '갑자기 날아든 기쁜 소식🎉여러분~ 제가! 바로 제가 이번에 공구왕👑이 됐어요!!\n' + 
        '\n' + 
        '\n' + 
        '\n' + 
        '다들 축하해주실거죠??👏🏻👏🏻👏🏻👏🏻👏🏻\n' + 
        '\n' + 
        '\n' + 
        '\n' + 
        '워커홀릭인 제가 아이가 있다보니 어디에 소속되어 근무하는 상황이 너무 힘들더라구요갑자기 아이가 열이 나거나 아프면 제가 ' + 
        '간호를 해야하니까..그런데 육아만 하고 있으니 하루하루가 지루하고 무료하더라구요\n' + 
        '\n' + 
        '\n' + 
        '\n' + 
        '그러던 중 <꿈을더하다>를 만나 상담을 받고 고민없이 바로 공구 교육을 듣기 시작했어요\n' + 
        '\n' + 
        '\n' + 
        '\n' + 
        '매일이 지루하던 제 삶에 공구마켓을 시작하면서 삶의 활력이 생기고 꿈과 희망이 생기기 시작했어요\n' + 
        '\n' + 
        '처음에 시작하면서 소통에서 판매가 이루어지니까 마켓 운영이 너무 재미있고 신이나더라구요😀\n' + 
        '\n' + 
        '\n' + 
        '\n' + 
        '잘 모르는 것들도 선배사장님들과 매니저님, 관리자님등등 차분하게 알려주셔서 한 단계 한 단계 성장할 수 있었구요.\n' + 
        '\n' + 
        '\n' + 
        '\n' + 
        '고객님이 제품 구매하시고 만족하시는 후기를 통해 뿌듯함과 행복도 느끼구요.\n' + 
        '\n' + 
        '\n' + 
        '\n' + 
        '꿈팸에서 공구 관련 교육을 단계적으로 알려주시고 여러 셀러분들과 함께 정보를 공유하고 나눌 수 있는게 꿈팸만의 가장 큰 ' + 
        '장점이 아닌가 싶어요^^\n' + 
        '\n' + 
        '\n' + 
        '\n' + 
        '열심히 열정을 가지고 공구마켓을 운영하다보니 여러업체에서 먼저 공구제안도 많이 해주시고 1차2차 꾸준히 진행도 하게 ' + 
        '되구요\n' + 
        '\n' + 
        '\n' + 
        '\n' + 
        '무엇보다 공구를 하면서 제품 협찬도 받고 우리 아이 제품도 저렴한 가격에 구매할 수 있고 인친님들께 좋은 제품을 소개할 ' + 
        '수 있어서 너무 너무 좋아요❤️\n' + 
        '\n' + 
        '\n' + 
        '\n' + 
        '꿈을더하다를 만나 시간과 공간에 구애받지 않고 열심히 일하는 엄마가 되게 해주셔서 너무 너무 감사합니다🙏',
        'date': '2023년 01월20일',
        'id': '30332424_3565',
        'images': {'000': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3565%2Fopt%2F000.avif',
                'bucket': 'img/testimonials/30332424_3565/000.jpeg',
                'key_img': true,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3565%2Fopt%2F000.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3565%2Fopt%2F000.webp'},
            '001': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3565%2Fopt%2F001.avif',
                'bucket': 'img/testimonials/30332424_3565/001.png',
                'key_img': false,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3565%2Fopt%2F001.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3565%2Fopt%2F001.webp'},
            '002': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3565%2Fopt%2F002.avif',
                'bucket': 'img/testimonials/30332424_3565/002.jpeg',
                'key_img': false,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3565%2Fopt%2F002.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3565%2Fopt%2F002.webp'},
            '003': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3565%2Fopt%2F003.avif',
                'bucket': 'img/testimonials/30332424_3565/003.png',
                'key_img': false,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3565%2Fopt%2F003.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3565%2Fopt%2F003.webp'}},
        'name': '유리베베',
        'subtitle': '공구판매왕 46기의 주인공은유***사장님입니다 :-)축하드립니다 !!\n' + 
        '\n' + 
        '하단은유***사장님의 100% 리얼 솔직 후기입니다 ♡',
        'tid': '30332424_3565',
        'title': '공구판매왕 46기 유리베베'},
    {'alias_title': '공구판매왕 47기 새***',
        'body': ('안녕하세요😊저는 꿈을 더하다에서 공구교육을 받고인스타로 공구마켓 운영중인 새***입니다.\n' + 
        '\n' + 
        '공구마켓을 운영한지도 어느덧 10개월이 되었네요 :)\n' + 
        '\n' + 
        '꿈을 더하다의 체계적인 교육 덕분에꿈에 그리던 공구왕까지!!저에게도 이런 날이 오네요😆\n' + 
        '\n' + 
        '저는 진짜 판매1도 모르는 완전 초짜새내기였어요간호조무사 일만 17년 해왔던 제가 늦둥이 둘째 임신/ 출산으로 이제 ' + 
        '조무사 일도 못할거 같고 아기 키우면서 뭘 할수있을까? 고민하다 꿈을 더하다를 알게 되었어요\n' + 
        '\n' + 
        '정말 고민 많이 하고 몇날 몇일을 상담했던거 같은데 왜그랬나 싶어요. 좀더 일찍 시작할껄!\n' + 
        '\n' + 
        '지금은 우리 아이들 필요한 육아템도 협찬받고,도매옷도 저렴히 살수있게 되었어요\n' + 
        '\n' + 
        '인친님들과 소통하며 재밌게 공구마켓 운영중이고,이번 카타르 월드컵때도 예상스코어 맞추기 이벤트하면서즐겁게 월드컵도 ' + 
        '보냈답니다 :)\n' + 
        '\n' + 
        '그리고 다른 사장님들께서 가지고 오시는 핫템들도 공유받을수 있어 너무 좋은거 같아요\n' + 
        '\n' + 
        '항상 더 대박날수 있게 판매의 장을 열어주시고,내년 1월 홈페이지도 제공해주신다니 꿈더 참 잘왔다👍\n' + 
        '\n' + 
        '우리사장님들도 포기하지않고,공구교육 들으신데로 노력하시면 꼭! 성공하실수 있으세요다들 홧팅하시고 연말 마무리 ' + 
        '잘하셔요🤍새해복 많이많이 받으시고 우리모두 존버해요🤍🤍'),
        'date': '2023년 01월20일',
        'id': '30332424_3566',
        'images': {'000': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3566%2Fopt%2F000.avif',
                'bucket': 'img/testimonials/30332424_3566/000.png',
                'key_img': true,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3566%2Fopt%2F000.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3566%2Fopt%2F000.webp'},
            '001': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3566%2Fopt%2F001.avif',
                'bucket': 'img/testimonials/30332424_3566/001.png',
                'key_img': false,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3566%2Fopt%2F001.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3566%2Fopt%2F001.webp'},
            '002': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3566%2Fopt%2F002.avif',
                'bucket': 'img/testimonials/30332424_3566/002.png',
                'key_img': false,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3566%2Fopt%2F002.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3566%2Fopt%2F002.webp'},
            '003': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3566%2Fopt%2F003.avif',
                'bucket': 'img/testimonials/30332424_3566/003.png',
                'key_img': false,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3566%2Fopt%2F003.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3566%2Fopt%2F003.webp'},
            '004': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3566%2Fopt%2F004.avif',
                'bucket': 'img/testimonials/30332424_3566/004.png',
                'key_img': false,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3566%2Fopt%2F004.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3566%2Fopt%2F004.webp'}},
        'name': '새싹베베',
        'subtitle': '공구판매왕 47기의 주인공은새***사장님입니다 :-)축하드립니다 !!\n' + 
        '\n' + 
        '하단은새***사장님의 100% 리얼 솔직 후기입니다 ♡',
        'tid': '30332424_3566',
        'title': '공구판매왕 47기 새싹베베'},
    {'alias_title': '공구판매왕 48기 호****',
        'body': '안녕하세요 [꿈을더하다]에서완전 체계적인 [공구교육]을 받고인스타마켓을 운영하고 있는 ➰호****➰입니다\n' + 
        '\n' + 
        '제가 이번에 공구판매왕이 되었다니 너무 꿈만 같아요모두모두 축하해주세요 소리질러~ 예얍😆\n' + 
        '\n' + 
        '항상 카페에 좋은소식이 들려올때면나도 꼭!! 공구판매왕 될꺼야 다짐을 하고힘을 받고 자극도 받았는데,공구시작한지 6개월 ' + 
        '된 아직도 초짜사장인 저에게도 이런 명예가 주어진다니 너무 행복합니다\n' + 
        '\n' + 
        '제 이야기를 해볼께요😀\n' + 
        '\n' + 
        '사실 [꿈을더하다]는 예전부터 관심이 가는 곳이였고 언젠가는 공구를 시작한다면[꿈을더하다]와 함께 해야겠다는막연한 생각을 ' + 
        '품은 후2022년 어느 여름날 결심을 하고인연을 이어가고 있습니다\n' + 
        '\n' + 
        '꿈을더하다는 공구마켓을 운영할 수 있는[공구교육][체계적인 교육][공구의핵심][진짜필요한 교육]을 알려주셨습니다\n' + 
        '\n' + 
        '정말 기초부터 하나하나이런거까지 알려주시나 싶을 정도로탄탄한 기초다지기부터놀라울 정도로 세심한 자료와 교육에진짜 꿈팸 ' + 
        '만세🙇🏻\u200d♀️ 🙌\n' + 
        '\n' + 
        '사실 지금도 교육자료들 틈틈히 본답니다(소근소근)\n' + 
        '\n' + 
        '교육받고 처음에는 꿈팸사장님들께서 공유해주시는 핫템들을 인스타에 올렸고10월부턴 직접 주최자가 되어공구진행도 하고 ' + 
        '있습니다\n' + 
        '\n' + 
        '배운교육을 토대로 자신감 있게 업체와 미팅을 하고전~혀 다른 삶을 살고 있는제 자신이 어떨땐 참 신기합니다사실 ' + 
        '전업주부로써 자신감도 많이 하락되어 있고위축되어 있던 제가 꿈팸을 만나다른 삶을 살고 있습니다\n' + 
        '\n' + 
        '처음교육때부터 강조하신인친들과의 소통의 즐거움도 덤으로 얻고,새로운 사람들을 만나고 매일이 참 재미납니다\n' + 
        '\n' + 
        '남편몰래 비상금 모으는 재미도 솔솔합니다😀\n' + 
        '\n' + 
        '이 모든 일이 저 혼자 이룬것이라곤 생각하지 않습니다모든 교육과정을 만드신 두 분의 대표님,기초부터 하나하나 완전 ' + 
        '쌩초보가 이해할수 있게교육해주신 강사님들,지금도 어려운일이 있을때 요청하면바~로 같이 고민해주시고 응원해주시는 ' + 
        '매니저님들, 그리고 매일 핫템을 공유해주시는소중한 우리 꿈을더하다 [꿈팸]사장님들모두모두 감사합니다💛\n' + 
        '\n' + 
        '꿈팸이 있어서 어디서나 당당하게!!올해는 더 많은 업체들과 더 좋은 아이템으로대박날 일들만 가득할것 같습니다그리고 ' + 
        '꿈을더하다 홈페이지까지👍🏻언제나 무한대로 퍼주시니 우리 모두 꽃길만 걸어요화이팅입니다💛',
        'date': '2023년 01월30일',
        'id': '30332424_3610',
        'images': {'000': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3610%2Fopt%2F000.avif',
                'bucket': 'img/testimonials/30332424_3610/000.jpg',
                'key_img': true,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3610%2Fopt%2F000.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3610%2Fopt%2F000.webp'},
            '001': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3610%2Fopt%2F001.avif',
                'bucket': 'img/testimonials/30332424_3610/001.jpg',
                'key_img': false,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3610%2Fopt%2F001.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3610%2Fopt%2F001.webp'},
            '002': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3610%2Fopt%2F002.avif',
                'bucket': 'img/testimonials/30332424_3610/002.jpg',
                'key_img': false,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3610%2Fopt%2F002.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3610%2Fopt%2F002.webp'},
            '003': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3610%2Fopt%2F003.avif',
                'bucket': 'img/testimonials/30332424_3610/003.jpg',
                'key_img': false,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3610%2Fopt%2F003.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3610%2Fopt%2F003.webp'},
            '004': {'avif': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3610%2Fopt%2F004.avif',
                'bucket': 'img/testimonials/30332424_3610/004.jpg',
                'key_img': false,
                'size': 'lg',
                'src': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3610%2Fopt%2F004.jpeg',
                'webp': 'https://storage.googleapis.com/supersimpleshop-71b5f.appspot.com/img%2Ftestimonials%2F30332424_3610%2Fopt%2F004.webp'}},
        'name': '호두단디네',
        'subtitle': '공구판매왕 48기의 주인공은호****사장님입니다 :-)축하드립니다 !!\n' + 
        '\n' + 
        '하단은호****사장님의 100% 리얼 솔직 후기입니다 ♡',
        'tid': '30332424_3610',
        'title': '공구판매왕 48기 호두단디네'}
]