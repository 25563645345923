import * as React from "react"
import { Box, Flex, SimpleGrid } from "@chakra-ui/react"
import { map } from "lodash"

import { OptimizedImage } from "../../../components/optimized-image/optimized-image"

export function PostImages({ block }) {

    const noOfImages = block.images.length
    const maxNoOfImagesPerRow = 3
    const noOfColumns = Math.min(noOfImages, maxNoOfImagesPerRow)

    return <SimpleGrid
        spacing={2}
        columns={{ base: Math.max(1, noOfColumns) }}
        mb={6}
    >
        {
            map(block.images, (img, id) => <Flex key={id} w={"full"}>
                { img.bucket && <OptimizedImage
                    img={img}
                    imgProps={{
                        w: "full",
                        p: { base: 2, md: 3 },
                        bg: "white",
                        shadow: "md",
                        alt: `blog_img_${id}`
                }}
                    pictureProps={{ w: "full" }}
                /> }
                { !img.bucket && <Box key={id} w={"full"} h={6} bg={"red.200"} mb={1}>{block.type}</Box> }
            </Flex>)
        }
    </SimpleGrid>
}
