import instructors00avif from "./teacher0.avif"
import instructors00png from "./teacher0.png"
import instructors00webp from "./teacher0.webp"
import instructors01avif from "./teacher1.avif"
import instructors01png from "./teacher1.png"
import instructors01webp from "./teacher1.webp"
import instructors02avif from "./teacher2.avif"
import instructors02png from "./teacher2.png"
import instructors02webp from "./teacher2.webp"
import instructors03avif from "./teacher3.avif"
import instructors03png from "./teacher3.png"
import instructors03webp from "./teacher3.webp"
import instructors04avif from "./teacher4.avif"
import instructors04png from "./teacher4.png"
import instructors04webp from "./teacher4.webp"
import instructors05avif from "./teacher5.avif"
import instructors05png from "./teacher5.png"
import instructors05webp from "./teacher5.webp"
import instructors06avif from "./teacher6.avif"
import instructors06png from "./teacher6.png"
import instructors06webp from "./teacher6.webp"
import instructors08avif from "./teacher_bg2d.avif"
import instructors08png from "./teacher_bg2d.png"
import instructors08webp from "./teacher_bg2d.webp"
import instructors09avif from "./teacher_bg2e.avif"
import instructors09jpg from "./teacher_bg2e.jpg"
import instructors09webp from "./teacher_bg2e.webp"

export const imgs = {
    t00: {
        avif: instructors00avif,
        src: instructors00png,
        webp: instructors00webp,
    },
    t01: {
        avif: instructors01avif,
        src: instructors01png,
        webp: instructors01webp,
    },
    t02: {
        avif: instructors02avif,
        src: instructors02png,
        webp: instructors02webp,
    },
    t03: {
        avif: instructors03avif,
        src: instructors03png,
        webp: instructors03webp,
    },
    t04: {
        avif: instructors04avif,
        src: instructors04png,
        webp: instructors04webp,
    },
    t05: {
        avif: instructors05avif,
        src: instructors05png,
        webp: instructors05webp,
    },
    t06: {
        avif: instructors06avif,
        src: instructors06png,
        webp: instructors06webp,
    },
    bg01: {
        avif: instructors08avif,
        src: instructors08png,
        webp: instructors08webp,
    },
    bg02: {
        avif: instructors09avif,
        src: instructors09jpg,
        webp: instructors09webp,
    },
}
export default imgs
