import * as React from "react"
import { Box } from "@chakra-ui/react"
import { useNavigate, useLocation } from "react-router-dom"
import * as yup from "yup"

import { useAuth } from "../contexts/auth-provider"
import { useConstants } from "../../contexts/constants-provider"
import { useSite } from "../../contexts/site-provider"

import { LoginForm } from "./login-form"
import { AuthPageHeader } from "../components/auth-page-header"

import { LogoPlaceholder } from "../../components/logo-placeholder"
import { LoginNotMemberYet } from "./login-not-member-yet"

// import { DividerWithText } from "../../components/dividers/divider-with-text"

export function LoginPage(props) {
    const auth = useAuth()
    const constants = useConstants()
    const site = useSite()
    const strings = constants.strings.auth.loginPage
    const logo = site?.getLogo("dark") || <LogoPlaceholder />
    // const logo = <LogoPlaceholder />

    const navigate = useNavigate()
    const location = useLocation()
    const from = location.state?.from?.pathname || "/"
    const [loginError, setLoginError] = React.useState({})

    const schema = yup.object().shape({
        email: yup.string().email().required(strings.emailRequired).default(""),
        password: yup.string().required(strings.passwordRequired).default(""),
    })

    const onSubmit = (values, { setSubmitting }) => {
        auth.signin(
            values.email,
            values.password,
            () => navigate(from, { replace: true }),
            (err) => {
                setLoginError(err)
                setSubmitting(false)
            },
        )
    }
    console.log("LoginPage", site, constants)
    return (
        <Box {...props}>
            <AuthPageHeader
                title={strings.headline || " "}
                description={strings.subheadline}
            />
            <LoginForm
                onSubmit={onSubmit}
                strings={strings}
                error={loginError}
                setError={setLoginError}
                schema={schema}
                forgotRoute={constants.auth.forgotRoute}
                title={strings.title}
                description={strings.description}
                logo={logo}
                // logoOnLeft={true}
                centerLogo
            />
            {site?.auth?.canSignup && (
                <LoginNotMemberYet
                    strings={strings}
                    link={constants.auth.signupRoute}
                    from={from}
                />
            )}
        </Box>
    )
}

