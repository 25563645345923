import { useState, useEffect } from "react"
import { Box, Link, Fade } from "@chakra-ui/react"
import { OptimizedImage } from "../../../components/optimized-image/optimized-image"

import imgs from "../../assets/kakao/min"

import { kakao } from "./config"

export function KakaoChannelButton() {
    const [isScrolling, setIsScrolling] = useState(true)

    const chatUrl = kakao.getChatUrl()

    useEffect(() => {
        const onScroll = () => setIsScrolling(false)

        window.addEventListener("scroll", onScroll, { once: true })

        return () => {
            // clearTimeout(timer)
            window.removeEventListener("scroll", onScroll)
        }
    }, [])

    return (
        <Fade className={"contact-us-button-fade"} in={!isScrolling}>
            <Box
                className={"contact-us-button-container"}
                position={"fixed"}
                bottom={6}
                right={4}
                transition={"background-color .3s, opacity .5s, visibility .5s"}
                zIndex={1}
                d={isScrolling ? "none" : "block"}
            >
                <Link className={"contact-us contact-us-button"} href={chatUrl}>
                    <OptimizedImage
                        img={imgs}
                        imgProps={{
                            className: "contact-us-button-image",
                            w: "120px",
                            h: "auto",
                            rounded: "xl",
                            shadow: "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
                        }}
                    />
                    {/*<Image*/}
                    {/*    className={"contact-us-button-image"}*/}
                    {/*    src={chatChannelImg}*/}
                    {/*    w={"120px"}*/}
                    {/*    h={"auto"}*/}
                    {/*    rounded={"xl"}*/}
                    {/*    shadow={"0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)"}*/}
                    {/*/>*/}
                </Link>
            </Box>
        </Fade>
    )
}
