import * as React from "react"
import { Outlet, useLocation, useOutletContext } from "react-router-dom"
import { Box, useStyleConfig } from "@chakra-ui/react"

import { AuthPageHeader } from "../components/auth-page-header"
import { SignupAlreadyMember } from "./signup-already-member"

import { useConstants } from "../../contexts/constants-provider"

// import { useSite } from "../../contexts/site-provider"

export function SignupPage({ children, ...rest }) {
    const constants = useConstants()
    const context = useOutletContext()
    // const site = useSite()
    const strings = constants.strings.auth.signupPage

    const location = useLocation()
    const from = location.state?.from?.pathname || "/"

    const styles = useStyleConfig("AuthSignupPageStyle")

    const newContext = {
        ...context,
        strings,
    }

    const childrenOrOutlet = (context) => {
        if (children) {
            return (
                <div>
                    {React.Children.map(children, (child) => {
                        if (React.isValidElement(child)) {
                            return React.cloneElement(child, { ...context })
                        }
                        return child
                    })}
                </div>
            )
        }
        return <Outlet context={context} />
    }

    return (
        <Box __css={styles} {...rest}>
            <AuthPageHeader
                title={strings.title}
                description={strings.description}
            />
            <SignupAlreadyMember
                strings={strings}
                loginRoute={constants.auth.loginRoute}
                from={from}
            />
            {childrenOrOutlet(newContext)}
        </Box>
    )
}
