import * as React from "react"
import { transparentize } from "@chakra-ui/theme-tools"

export function useMarketingThemes() {

    const [ themes, setThemes ] = React.useState({
        colors: {
            cute: {
                50: "#ffe9eb",
                100: '#ffe9eb',
                200: '#efc5c9',
                300: '#dfa0a7', // main
                400: '#d17b84',
                500: '#c35562',
                600: '#aa3c48',
                700: '#852e37',
                800: '#5f2028',
                900: '#3b1217',
            },
            // secondary: {
            //     "50": "#F3F1F3",
            //     "100": "#DDD8DE",
            //     "200": "#C7BFC9",
            //     "300": "#B0A6B5",
            //     "400": "#9A8DA0",
            //     "500": "#84748B",
            //     "600": "#6A5D6F",
            //     "700": "#4F4653",
            //     "800": "#352F37",
            //     "900": "#1A171C"
            // },
            secondary: {
                "50": "#FBEEEA",
                "100": "#F3CEC4",
                "200": "#EBAF9E", // main
                "300": "#E38F77",
                "400": "#DC7051",
                "500": "#D4502B",
                "600": "#A94023",
                "700": "#7F301A",
                "800": "#552011",
                "900": "#2A1009"
            },
            brand: {
                "50": "#F4F1F1",
                "100": "#E0D9D7",
                "200": "#CCC0BD",
                "300": "#B8A8A3",
                "400": "#A49089",
                "500": "#90776F",
                "600": "#735F59",
                "700": "#574742",
                "800": "#3A302C",
                "900": "#1D1816"
            },
            primary: {
                50: "#ffe9eb",
                100: '#ffe9eb',
                200: '#efc5c9',
                300: '#dfa0a7', // main
                400: '#d17b84',
                500: '#c35562',
                600: '#aa3c48',
                700: '#852e37',
                800: '#5f2028',
                900: '#3b1217',
            },
            bg: {
                50: '#ffeeeb',
                100: '#e7d2cc',
                200: '#d1b6ad',  // main
                300: '#be998d',
                400: '#ab7d6d',
                500: '#926354',
                600: '#724c41',
                700: '#52372e',
                800: '#33201a',
                900: '#170802',
            },
            bg2: {
                50: "#F5F2F0",
                100: "#E2DAD5",
                200: "#CFC3B9",
                300: "#BDAB9E",
                400: "#AA9483",
                500: "#987C67",
                600: "#796353",
                700: "#5B4A3E",
                800: "#3D3229",
                900: "#1E1915"
            }
        },
        fonts: {
            heading: 'Noto Sans KR, -apple-system, system-ui, sans-serif',
            body: 'Noto Sans KR, -apple-system, system-ui, sans-serif',
        },
        components: {
            Alert: {
                baseStyle: {
                    color: "brand.700"
                }
            },
            AppLayout: {
                variants: {
                    brand: {
                        bg: "bg2.50"
                    },
                    cute: {
                        bg: "gray.50"
                    },
                }
            },
            AuthLayoutStyle: {
                baseStyle: {
                    background: "bg.200",
                    bgGradient: "linear(to-b, bg.300, bg.200)"
                }
            },
            AuthPageHeaderStyle: {
                baseStyle: {
                    color: "white"
                }
            },
            AuthSignupPageStyle: {
                baseStyle: {
                    color: "white"
                }
            },

            Button: {
                baseStyle: {
                    // background: "brand.300",
                    shadow: "sm",
                    rounded: "sm",
                    color: "white"
                },
                variants: {
                    brand: {
                        bg: "brand.400",
                        rounded: "sm",
                    },
                    cute: {
                        bg: "cute.500",
                        rounded: "md",
                    },
                }
            },
            CallToActionButton: {
                variants: {
                    brand: {
                        bg: "brand.500"
                    },
                    cute: {
                        bg: "cute.400"
                    }
                }
            },
            CallToActionInverseButton: {
                variants: {
                    brand: {
                        color: "secondary.500",
                        bg: "white",
                    },
                    cute: {
                        color: "cute.500",
                        bg: "white",
                    }
                }
            },
            Card: {
                baseStyle: {
                    background: "white"
                }

            },
            CarouselIconButton: {
                variants: {
                    brand: {
                        rounded: "full",
                        color: "cute.300",
                        bg: "whiteAlpha.200"
                    },
                    cute: {
                        color: "cute.300",
                        bg: "whiteAlpha.200",
                        rounded: "full",
                    },
                }
            },
            FooterSocialButtons: {
                variants: {
                    brand: {
                        color: "gray.700"
                    },
                    cute: {
                        color: "cute.500"
                    },
                }
            },
            Heading: {
                baseStyle: {
                    wordBreak: "keep-all"
                }
            },
            LoginNotMemberYet: {
                baseStyle: {
                    color: "white"
                }
            },
            FullPageLoadingStyle: {
                baseStyle: {
                    background: "primary.200",
                    bgGradient: "linear(to-b, bg.300, bg.200)"
                }
            },
            LoadingStyle: {
                baseStyle: {
                    color: "var(--chakra-colors-bg2-100)"
                }
            },

            Navbar: {
                baseStyle: {
                    bg: "white"
                }
            },
            NavButton: {
                baseStyle: props => ({
                    color: 'bg.50',
                    _hover: {
                        bg: transparentize('bg.300', 0.4)(props.theme),
                    },
                    _activeLink: {
                        color: 'brown.500',
                        bg: 'bg.100',
                    },
                })
            },
            NavbarToggle: {
                baseStyle: {
                    color: "primary.500",
                }
            },
            NavHeader: {
                parts: [ "header", "buttons" ],
                baseStyle: {
                    header: {
                        bg: "bg2.50",
                        color: "brand.700",
                    },
                    buttons: {
                        color: "brand.700",
                    },
                },
            },
            // NavHeader: {
            //     variants: {
            //         brand: {
            //             bg: "bg2.100"
            //         },
            //         cute: {
            //             bg: "white"
            //         },
            //         marketing: {
            //             bg: "bg2.700"
            //         }
            //     }
            // },
            NavButtons: {
                baseStyle: {
                    color: "secondary.700"
                },
                variants: {
                    brand: {
                        color: "brand.700"
                    }
                }
            },
            NavMenu: {
                variants: {
                    brand: {
                        bg: "bg2.50"
                    },
                    cute: {
                        bg: "white"
                    },
                }
            },
            QuantityPicker: {
                variants: {
                    brand: {
                        bg: "white"
                    },
                    cute: {
                        bg: "gray.50"
                    },
                }
            },
            ProductCardFavorite: {
                variants: {
                    brand: {
                        rounded: "sm",
                        bg: "whiteAlpha.400"

                    },
                    cute: {
                        rounded: "full"
                    }
                }
            },
            ProductCardImage: {
                variants: {
                    brand: {
                        rounded: "sm"
                    },
                    cute: {
                        rounded: "md"
                    }
                }
            },
            ProductCardPrice: {
                variants: {
                    brand: {
                        color: "secondary.500"
                    },
                    cute: {
                        color: "cute.50"
                    }
                }
            },
            ProductCardTag: {
                variants: {
                    brand: {
                        rounded: "sm",
                        bg: "secondary.500"
                    },
                    cute: {
                        rounded: "md",
                        bg: "cute.400"
                    }
                }
            },
            Sidebar: {
                baseStyle: {
                    bg: "bg.300"
                }
            },
            SizePickerButton: {
                variants: {
                    brand: {
                        bg: "white"
                    },
                    cute: {
                        bg: "gray.50"
                    },
                }

            },
            Text: {
                baseStyle: {
                    wordBreak: "keep-all"
                }
            },
            TopBanner: {
                baseStyle: {
                    shadow: "sm",
                },
                variants: {
                    brand: {
                        bg: "brand.400"
                    },
                    cute: {
                        bg: "cute.500"
                    },
                }
            }

        }
    })

    return { themes, setThemes }
}