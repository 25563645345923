import { Button, Flex } from "@chakra-ui/react"
import { kakao } from "../kakao/config"

export function ContactUsButton({ variant, btnProps = {}, children, ...rest }) {
    // variant = variant || "solid-berry"
    const chatUrl = kakao.getChatUrl()

    return (
        <Flex className={"contact-us-button-container"} w={"full"} {...rest}>
            <Button
                className={"contact-us contact-us-button"}
                as={"a"}
                href={chatUrl}
                size={["lg"]}
                maxW={"2xl"}
                shadow={"sm"}
                rounded={"sm"}
                mb={2}
                width={"full"}
                {...btnProps}
            >
                {children}
            </Button>
        </Flex>
    )
}
