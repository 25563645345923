import { Box, chakra, Text, useColorModeValue } from "@chakra-ui/react"
import { ImQuotesLeft } from "react-icons/im"
import * as React from "react"
import { map } from "lodash"

export function PostBlockquoteBlock({ block }) {
    return <Box
        flex="1"
        rounded="lg"
        bg={useColorModeValue('white', 'gray.700')}
        shadow="base"
        px={{ base: 6, md: 10 }}
        pt={{ base: 4, md: 8 }}
        pb={{ base: 8, md: 12 }}
        pos="relative"
        mb={{ base: 10, md: 10 }}
    >
        <Box as={ImQuotesLeft} color={"brand.400"} fontSize="3xl"/>
        <Box fontSize="lg" fontWeight="medium">
            {map(block.paragraphs, (p, i) => <Text key={i}>{p}</Text>)}
        </Box>
        <chakra.svg
            pos="absolute"
            bottom="-7"
            insetStart="3.5rem"
            w="10"
            viewBox="0 0 38 28"
            color={useColorModeValue('white', 'gray.700')}
            style={{
                filter: 'drop-shadow(0px 1px 1px rgba(0, 0, 0, .1)',
            }}
        >

            <path d="M0 28V0H38L0 28Z" fill="currentColor"/>
        </chakra.svg>
    </Box>
}
