import React from "react"
import ReactDOM from "react-dom/client"

import "@fontsource/montserrat"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/700.css"
import "@fontsource/montserrat/600-italic.css"
import "@fontsource/montserrat/variable.css"
import "@fontsource/inter/variable.css"
import "@fontsource/noto-sans-kr"
import "@fontsource/noto-sans-kr/korean.css"

import "react-responsive-carousel/lib/styles/carousel.min.css"

import { FirebaseProviders } from "./firebase/firebase-providers"

import "./index.css"
import { App } from "./App"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
    <React.StrictMode>
        <FirebaseProviders>
            <App />
        </FirebaseProviders>
    </React.StrictMode>,
)
