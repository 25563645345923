import * as React from "react"
import {
    Box,
    Flex,
    VStack,
    HStack,
    Text,
    Heading,
    LinkOverlay,
    Stack,
    StackDivider,
    AspectRatio,
} from "@chakra-ui/react"
import { NavLink } from "react-router-dom"
import { isEmpty, find, first, values } from "lodash"
import he from "he"

import { SkeletonOptimizedImage } from "../../../components/optimized-image/skeleton-optimized-image"
// import { Author } from "../blogs/author"
import { usePrefix } from "../../contexts/prefix-provider"

export function TestimonialListItem({ testimonial }) {
    const prefix = usePrefix()

    // console.log(testimonial.tid, testimonial.images, find(testimonial.images, "key_img"), first(values(testimonial.images)))
    const keyImg =
        find(testimonial.images, "key_img") || first(values(testimonial.images))

    return (
        <Box
            as={"article"}
            className={"testimonial-container"}
            position={"relative"}
            overflow={"hidden"}
            transition={"all 0.2s ease 0s"}
            bgColor={"gray.50"}
            textAlign={"left"}
            wordBreak={"keep-all"}
        >
            <LinkOverlay
                as={NavLink}
                className={"testimonial-link-overlay"}
                // href={blog.url}
                to={prefix(`/testimonials/${testimonial.tid}`)}
            >
                <VStack
                    className={"testimonial-card"}
                    align={"flex-end"}
                    py={[2, 6]}
                    px={{ base: 4, md: 6, lg: 8 }}
                >
                    <HStack
                        className={"testimonial-details-main"}
                        justify={"space-between"}
                        align={"flex-start"}
                    >
                        <Flex w={["33%", "25%"]} justify={"center"}>
                            <AspectRatio
                                w={{ base: "120px", md: "140px", lg: "160px" }}
                                ratio={1}
                            >
                                <SkeletonOptimizedImage
                                    isLazy
                                    img={keyImg}
                                    imgProps={{
                                        alt: "",
                                        w: {
                                            base: "120px",
                                            md: "140px",
                                            lg: "160px",
                                        },
                                        h: "auto",
                                    }}
                                />
                            </AspectRatio>
                        </Flex>
                        <VStack
                            className={"testimonial-details"}
                            w={["67%", "75%"]}
                            align={"flex-start"}
                        >
                            {!isEmpty(testimonial.tags) && (
                                <Stack
                                    className={"testimonial-tags"}
                                    direction={["row", "column", "row"]}
                                    pl={1}
                                    spacing={[2, 1, 3]}
                                    textTransform={"uppercase"}
                                    letterSpacing={"tight"}
                                    fontSize={"sm"}
                                    fontWeight={"bold"}
                                    mb={2}
                                    color={"penny.400"}
                                    divider={
                                        <StackDivider borderColor="gray.200" />
                                    }
                                >
                                    {!isEmpty(testimonial.tags) &&
                                        testimonial.tags.map((tag) => (
                                            <Text
                                                className={"testimonial-tag"}
                                                key={tag}
                                            >
                                                {tag}
                                            </Text>
                                        ))}
                                </Stack>
                            )}
                            <Flex w={"full"} justify={"flex-end"}>
                                <Text
                                    className={"testimonial-date"}
                                    fontSize={"xs"}
                                    letterSpacing={"tighter"}
                                    color={"gray.600"}
                                >
                                    {testimonial.date}
                                </Text>
                            </Flex>

                            <Heading
                                className={"testimonial-headline"}
                                as={"h3"}
                                fontWeight={"bold"}
                                fontSize={["md", null, "sm"]}
                                lineHeight={"base"}
                                mb={{ base: 2, md: 2 }}
                                color={"charcoal.700"}
                            >
                                {testimonial.alias_title}
                            </Heading>
                            <Text
                                className={"testimonial-snippet"}
                                fontSize={["sm", null, null, "sm"]}
                                overflow={"hidden"}
                                textOverflow={"ellipsis"}
                                wordBreak={"break-all"}
                                mb={{ base: 4, md: 8 }}
                                sx={{
                                    display: "-webkit-box",
                                    WebkitBoxOrient: "vertical",
                                    WebkitLineClamp: "3",
                                }}
                            >
                                {he.decode(testimonial.body)}
                            </Text>
                        </VStack>
                    </HStack>
                    {/*<Flex*/}
                    {/*    className={"testimonial-footer"}*/}
                    {/*    align={"baseline"}*/}
                    {/*    justify={"flex-start"}*/}
                    {/*    fontSize={"sm"}*/}
                    {/*    color={"gray.600"}*/}
                    {/*    w={"full"}*/}
                    {/*    pr={2}*/}
                    {/*>*/}
                    {/*    <Author*/}
                    {/*        className={"testimonial-author"}*/}
                    {/*        author={testimonial.name.slice(0, 1).padEnd(testimonial.name.length, "*")}*/}
                    {/*    />*/}
                    {/*</Flex>*/}
                </VStack>
            </LinkOverlay>
        </Box>
    )
}
