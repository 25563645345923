import { mode } from "@chakra-ui/theme-tools"

export const componentsThemes = {
    components: {
        Card: {
            baseStyle: {
                bg: "white"
            }
        },
        FullPageLoading: {
            baseStyle: props => ({
                background: mode('gray.50', 'inherit')(props)
            })
        },
        LoadingStyle: {
            baseStyle: {
                color: "var(--chakra-colors-primary-400)"
            }
        },
    }
}