import products00avif from "./10.avif"
import products00jpg from "./10.jpg"
import products00webp from "./10.webp"
import products01avif from "./11.avif"
import products01jpg from "./11.jpg"
import products01webp from "./11.webp"
import products02avif from "./12.avif"
import products02jpg from "./12.jpg"
import products02webp from "./12.webp"
import products03avif from "./13.avif"
import products03jpg from "./13.jpg"
import products03webp from "./13.webp"
import products04avif from "./14.avif"
import products04jpg from "./14.jpg"
import products04webp from "./14.webp"
import products05avif from "./15.avif"
import products05jpg from "./15.jpg"
import products05webp from "./15.webp"
import products06avif from "./16.avif"
import products06jpg from "./16.jpg"
import products06webp from "./16.webp"
import products07avif from "./17.avif"
import products07jpg from "./17.jpg"
import products07webp from "./17.webp"
import products08avif from "./18.avif"
import products08jpg from "./18.jpg"
import products08webp from "./18.webp"
import products09avif from "./19.avif"
import products09jpg from "./19.jpg"
import products09webp from "./19.webp"
import products10avif from "./20.avif"
import products10jpg from "./20.jpg"
import products10webp from "./20.webp"
import products11avif from "./21.avif"
import products11jpg from "./21.jpg"
import products11webp from "./21.webp"
import products12avif from "./22.avif"
import products12jpg from "./22.jpg"
import products12webp from "./22.webp"
import products13avif from "./23.avif"
import products13jpg from "./23.jpg"
import products13webp from "./23.webp"
import products14avif from "./01.avif"
import products14jpg from "./01.jpg"
import products14webp from "./01.webp"
import products15avif from "./02.avif"
import products15jpg from "./02.jpg"
import products15webp from "./02.webp"
import products16avif from "./03.avif"
import products16jpg from "./03.jpg"
import products16webp from "./03.webp"
import products17avif from "./04.avif"
import products17jpg from "./04.jpg"
import products17webp from "./04.webp"
import products18avif from "./05.avif"
import products18jpg from "./05.jpg"
import products18webp from "./05.webp"
import products19avif from "./06.avif"
import products19jpg from "./06.jpg"
import products19webp from "./06.webp"
import products20avif from "./07.avif"
import products20jpg from "./07.jpg"
import products20webp from "./07.webp"
import products21avif from "./08.avif"
import products21jpg from "./08.jpg"
import products21webp from "./08.webp"
import products22avif from "./09.avif"
import products22jpg from "./09.jpg"
import products22webp from "./09.webp"

export const imgs = [
    {
        avif: products00avif,
        src: products00jpg,
        webp: products00webp,
    },
    {
        avif: products01avif,
        src: products01jpg,
        webp: products01webp,
    },
    {
        avif: products02avif,
        src: products02jpg,
        webp: products02webp,
    },
    {
        avif: products03avif,
        src: products03jpg,
        webp: products03webp,
    },
    {
        avif: products04avif,
        src: products04jpg,
        webp: products04webp,
    },
    {
        avif: products05avif,
        src: products05jpg,
        webp: products05webp,
    },
    {
        avif: products06avif,
        src: products06jpg,
        webp: products06webp,
    },
    {
        avif: products07avif,
        src: products07jpg,
        webp: products07webp,
    },
    {
        avif: products08avif,
        src: products08jpg,
        webp: products08webp,
    },
    {
        avif: products09avif,
        src: products09jpg,
        webp: products09webp,
    },
    {
        avif: products10avif,
        src: products10jpg,
        webp: products10webp,
    },
    {
        avif: products11avif,
        src: products11jpg,
        webp: products11webp,
    },
    {
        avif: products12avif,
        src: products12jpg,
        webp: products12webp,
    },
    {
        avif: products13avif,
        src: products13jpg,
        webp: products13webp,
    },
    {
        avif: products14avif,
        src: products14jpg,
        webp: products14webp,
    },
    {
        avif: products15avif,
        src: products15jpg,
        webp: products15webp,
    },
    {
        avif: products16avif,
        src: products16jpg,
        webp: products16webp,
    },
    {
        avif: products17avif,
        src: products17jpg,
        webp: products17webp,
    },
    {
        avif: products18avif,
        src: products18jpg,
        webp: products18webp,
    },
    {
        avif: products19avif,
        src: products19jpg,
        webp: products19webp,
    },
    {
        avif: products20avif,
        src: products20jpg,
        webp: products20webp,
    },
    {
        avif: products21avif,
        src: products21jpg,
        webp: products21webp,
    },
    {
        avif: products22avif,
        src: products22jpg,
        webp: products22webp,
    },
]
export default imgs
