import * as React from "react"
import {
    createBrowserRouter,
    RouterProvider
} from "react-router-dom"
import { HelmetProvider } from "react-helmet-async"
import { QueryClientProvider, QueryClient } from "react-query"
//
// import { FirebaseProvider } from "./contexts/firebase-provider"
// import { configureFuego, initializeFuego } from "./firebase/init"

import { ConstantsProvider } from "./contexts/constants-provider"
import { useConstants } from "./constants"
import { SiteProvider } from "./contexts/site-provider"
import { AppProvider } from "./contexts/app-provider"

import { useAuthentication } from "./authentication/use-authentication"
import { useComponentsPlugin } from "./components/useComponentsPlugin"
import { useValidation } from "./validation/use-validation"
import { useDreammore } from "./dreammore/use-dreammore"
// import { useShop } from "./shop/use-shop"
import { useMarketing } from "./marketing/use-marketing"

import { ChakraUiTheme, useThemes } from "./themes/chakra-ui-theme"

// import { AuthProvider } from "./auth/contexts/auth-provider"
// import { MetadataProvider } from "./contexts/metadata-provider"
// import { UserProvider } from "./contexts/user-provider"

// import { useCollections } from "./collections/use-collections"

import "./firebase/tagManager"

import './App.css'

// const firebase = initializeFuego()

export function App() {


    // const shop = useShop()
    const dreammore = useDreammore()
    const marketing = useMarketing()

    const currentSite = "marketing"

    const site = {
        dreammore,
        // shop,
        marketing
    }[currentSite]

    const auth = useAuthentication()
    const components = useComponentsPlugin()
    const validation = useValidation()

    const constants = useConstants([ auth, components, validation, site, ])

    // const collections = useCollections(constants, [ auth, site ])

    // const fuego = configureFuego(firebase, constants)

    const themes = useThemes([ auth, components, site ])

    // console.log("themes xy", themes)
    // TODO change this when multiple sites
    // let routes = auth.getRoutes(site, collections)
    const routes = site.publicRoutes
    // console.log("routes", routes)

    const router = createBrowserRouter(routes)

    const queryClient = new QueryClient()

    return (
        <ChakraUiTheme themes={themes}>
            <ConstantsProvider value={constants}>
                {/*<FirebaseProvider value={fuego}>*/}
                    <QueryClientProvider client={queryClient}>
                        <SiteProvider site={site}>
                            <AppProvider>
                                <HelmetProvider>
                                    <RouterProvider router={router}/>
                                </HelmetProvider>
                            </AppProvider>
                        </SiteProvider>
                    </QueryClientProvider>
                {/*</FirebaseProvider>*/}
            </ConstantsProvider>
        </ChakraUiTheme>
    )
}

export default App
