import * as React from "react"

import { VStack, Badge, AspectRatio, Text, Image } from "@chakra-ui/react"
// import { SkeletonOptimizedImage } from "../../../components/optimized-image/skeleton-optimized-image"
// import { OptimizedImage } from "../../../components/optimized-image/optimized-image"


export function ClassCard({ image, imgProps = {}, badge, title, subtitle }) {
    return <VStack
        className={"class-card"}
        bg={"white"}
        w={"full"}
        rounded={"md"}
        justify={"space-between"}
    >
        <VStack
            className={"class-description-container"}
            py={6}
            px={4}
            spacing={4}>
            <Badge
                className={"class-description-badge"}
                textTransform={"uppercase"}
                fontSize={"lg"}
                size={"lg"}
                bg={"white"}
                color={"brand.500"}
                borderWidth={"1px"}
                borderStyle={"solid"}
                rounded={"full"}
                borderColor={"brand.500"}>{badge}</Badge>
            <Text
                className={"class-description-title"}
                fontWeight={700}
                lineHeight={1.2}
                fontSize={"2xl"}
            >{title}</Text>
            <Text
                className={"class-description"}
                wordBreak={"keep-all"}
            >{subtitle}</Text>
        </VStack>
        <AspectRatio
            className={"class-image-wrapper"}
            ratio={{ base: 16 / 9, md: 1 }}
            h={"225px"}
            w={"full"}>
            {/*<OptimizedImage*/}
            {/*    // isLazy={true}*/}
            {/*    pictureProps={{w: "full", h: "full"}}*/}
            {/*    img={image}*/}
            {/*    imgProps={{*/}
            {/*        className: "class-image",*/}
            {/*        w: "full",*/}
            {/*        objectFit: "contain",*/}
            {/*        borderBottomRadius: "md",*/}
            {/*        ...imgProps*/}
            {/*    }}*/}

            {/*/>*/}
            <Image
                className={"class-image"}
                src={image.src}
                w={"full"}
                objectFit="contain"
                // objectPosition={"left top"}
                borderBottomRadius={"md"}
                {...imgProps}
            />
        </AspectRatio>
    </VStack>
}