import * as React from "react"
import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet-async"
import { Box } from "@chakra-ui/react"

import { Hero } from "../components/hero/hero"
import { Testimonial} from "../components/testimonial/testimonial"
import { KakaoChannelButton } from "../components/kakao/kakao-channel-button"

export function TestimonialItemPage() {
    const { tid } = useParams()
    const { href } = window.location

    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>꿈을더하다 - 후기</title>
                <meta property={"og:url"} content={href} />
                <meta
                    property="og:description"
                    content={
                        "왕초보부터 공구판매왕까지 수강생들의 100% 리얼후기 확인해보세요."
                    }
                />
                />
                <link
                    rel="canonical"
                    href={`https://dream-more.kr/testimonials/${tid}`}
                />
            </Helmet>
            <Box display={{ base: "none", md: "block" }}>
                <Hero />
            </Box>
            <Testimonial />
            <KakaoChannelButton />
        </>
    )
}