/* eslint dot-notation:0 */
import * as React from "react"
import process from "process"
import { getAnalytics } from "firebase/analytics"
import { getAuth, connectAuthEmulator } from "firebase/auth"
import {
    initializeFirestore,
    connectFirestoreEmulator,
    enableMultiTabIndexedDbPersistence,
} from "firebase/firestore"
import { getFunctions, connectFunctionsEmulator } from "firebase/functions"
import { getStorage, connectStorageEmulator } from "firebase/storage"
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check"

import {
    FirebaseAppProvider,
    FirestoreProvider,
    AppCheckProvider,
    FunctionsProvider,
    StorageProvider,
    AnalyticsProvider,
    AuthProvider as FirebaseAuthProvider,
    useFirebaseApp,
    useInitFirestore,
} from "reactfire"

const IS_DEV = process.env.NODE_ENV !== "production"

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    // TODO change authentication domain to custom domain
    // authDomain: "dream-more.kr",
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

function AppCheckSdkProviders({ children }) {
    const app = useFirebaseApp()

    if (IS_DEV) {
        // analytics.disable();
        if (!process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN)
            console.warn(
                "Add Firebase AppCheck Debug token to .env.development",
            )
        global.self.FIREBASE_APPCHECK_DEBUG_TOKEN =
            process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN || true
    }

    const appCheck = initializeAppCheck(app, {
        provider: new ReCaptchaV3Provider(
            process.env.REACT_APP_CAPTCHA_CLIENT_KEY
        ),
        isTokenAutoRefreshEnabled: true,
    })

    return <AppCheckProvider sdk={appCheck}>{children}</AppCheckProvider>
}

export function AnalyticsSdkProvider({ children }) {
    const app = useFirebaseApp()
    const analytics = getAnalytics(app)

    return <AnalyticsProvider sdk={analytics}>{children}</AnalyticsProvider>
}

export function StorageSdkProvider({ children }) {
    const app = useFirebaseApp()
    const storage = getStorage(app)

    if (IS_DEV) connectStorageEmulator(storage, "localhost", 9199)

    return <StorageProvider sdk={storage}>{children}</StorageProvider>
}

export function FunctionsSdkProvider({ children }) {
    const app = useFirebaseApp()
    const region = IS_DEV ? "us-central1" : "asia-northeast3"
    const functions = getFunctions(app, region)

    if (IS_DEV) connectFunctionsEmulator(functions, "localhost", 5001)

    return <FunctionsProvider sdk={functions}>{children}</FunctionsProvider>
}

export function AuthSdkProvider({ children }) {
    const app = useFirebaseApp()
    const auth = getAuth(app)

    if (IS_DEV) connectAuthEmulator(auth, "http://localhost:9099", { disableWarnings: true })

    return <FirebaseAuthProvider sdk={auth}>{children}</FirebaseAuthProvider>
}

export function FirestoreSdkProvider({ children }) {
    const { status, data: firestore } = useInitFirestore(async (app) => {
        const db = initializeFirestore(app, {})

        if (IS_DEV) connectFirestoreEmulator(db, "localhost", 8080)
        else if (!db["_settingsFrozen"])
            await enableMultiTabIndexedDbPersistence(db)

        return db
    })

    if (status === "loading") return null
    return <FirestoreProvider sdk={firestore}>{children}</FirestoreProvider>
}

export function FirebaseProviders({ children }) {
    return (
        <FirebaseAppProvider firebaseConfig={firebaseConfig}>
            <AppCheckSdkProviders>
                <AuthSdkProvider>
                    <FirestoreSdkProvider>
                        <FunctionsSdkProvider>
                            <StorageSdkProvider>
                                <AnalyticsSdkProvider>
                                    {children}
                                </AnalyticsSdkProvider>
                            </StorageSdkProvider>
                        </FunctionsSdkProvider>
                    </FirestoreSdkProvider>
                </AuthSdkProvider>
            </AppCheckSdkProviders>
        </FirebaseAppProvider>
    )
}
