import * as React from "react"

import { Box, Heading, Text, VStack, SimpleGrid, Highlight, Flex, useBreakpointValue } from "@chakra-ui/react"
import { Feature } from "./feature"
import { ButtonContainer } from "../../../components/common/button-container"
import { ContactUsButton } from "../contact-us-button/contact-us-button"

import bgImage from "../../assets/features/merit_bg.jpg"
import mobileBgImage from "../../assets/features/merit_bg_mobile.jpg"
import f01 from "../../assets/features/merit_icon01.png"
import f02 from "../../assets/features/merit_icon02.png"
import f03 from "../../assets/features/merit_icon03.png"
import f04 from "../../assets/features/merit_icon04.png"
import f05 from "../../assets/features/merit_icon05.png"
import f06 from "../../assets/features/merit_icon06.png"
import f07 from "../../assets/features/merit_icon07.png"
import f08 from "../../assets/features/merit_icon08.png"

const features = [
    {
        title: "총괄매니저",
        highlight: "총괄매니저",
        description: "매니저에게 궁금한건 언제든지 문의 가능",
        img: f01,
    },
    {
        title: "8명의 강사",
        highlight: "8명",
        description: "인스타, 판매, 공구 등 각 단계별 강사와 1:1 교육",
        img: f02,
    },
    {
        title: "공구카페",
        highlight: "공구카페",
        description: "매일 업로드되는 다양한 상품을 편하게 퍼가기만 하세요!",
        img: f03,
    },
    {
        title: "도매가쇼핑",
        highlight: "도매가",
        description: "수백, 수천가지 상품을 도매가로 쇼핑가능합니다.",
        img: f04,
    },
    {
        title: "교육카페",
        highlight: "교육카페",
        description: "필요한 자료는 모두 이곳에! 편할 때 원하는 자료를 찾아보세요. 최신자료가 꾸준히 업데이트 됩니다. ",
        img: f05,
    },
    {
        title: "쇼핑몰 제공",
        highlight: "쇼핑몰",
        description: "5분만에 생성되는 나만의 쇼핑몰! 인스타그램과 연동하면 불필요한 고객 응대는 줄여주고 판매는 더욱 극대화될 거예요!",
        img: f06,
    },
    {
        title: "각종 시상",
        highlight: "시상",
        description: "매달 공구왕에게 현금 30만원 지급! 그 외 열심히 하신 분에게는 선물을 아낌없이 쏩니다.",
        img: f07,
    },
    {
        title: "간편한 시스템",
        highlight: "간편한",
        description: "주문만 받으면 사입, 배송 필요없어요. 배송까지 알아서 착착!",
        img: f08,
    },

]


export function Features() {

    const bgImg = useBreakpointValue({ base: mobileBgImage, lg: bgImage })

    return <Box
        className={"features-section"}
        as={"section"}
        bg={"bg2.50"}
        textAlign={"center"}
        py={{ base: 8, md: 12 }}
    >
        <VStack
            className={"features-stack"}
            spacing={1}
            pb={8}
            px={2}
            mx={"auto"}
            maxW={"4xl"}>
            <Text
                as={"h4"}
                className={"features-description section-header-prologue"}
                wordBreak={"keep-all"}
                color={"brand.700"}
                fontSize={"md"}>
                다 똑같아 보이는 공구교육!<br/>겉만보고 판단하면 장담컨대, 돈낭비입니다.
            </Text>
            <Heading
                className={"features-header-title"}
                as={"h3"}
                color={"brand.700"}
                fontSize={{ base: "3xl" }}
                fontWeight={"semibold"}
                letterSpacing={"tighter"}
                mb={2}>
                왜 꿈을더하다를 선택해야 할까요?
            </Heading>
            <Text
                className={"features-header-description"}
                as={"h4"}
                fontSize={"xl"}
                wordBreak={"keep-all"}
                bg={"bg2.700"}
                color={"white"}
                px={2}
                fontWeight={"bold"}
            >꿈을더하다만의 특장점</Text>
        </VStack>

        <Box
            className={"features-list-container"}
            bg={"bg2.800"}
            backgroundImage={bgImg}
            backgroundRepeat={'no-repeat'}
            backgroundSize={'cover'}
        >
            <SimpleGrid
                className={"features-list"}
                p={2}
                spacing={2}
                columns={{ base: 2, md: 3, lg: 4 }}
                textAlign={"center"}
                py={{ base: 8, md: 12 }}
                px={{ base: 4 }}
                maxW={"4xl"}
                mx={"auto"}
                // minH={"900px"}
            >
                {features.map((feature, i) => <Feature key={i} {...feature} />)}
            </SimpleGrid>
        </Box>
        <Flex
            className={"features-full-description-container"}
            w={"full"}
            bg={"bg2.700"}
            px={4}
            py={4}
            textAlign={"center"}>
            <Box
                className={"features-full-description-box"}
                mx={"auto"}
                maxW={"3xl"}>
                <Text
                    className={"features-full-description-text"}
                    color={"white"}>
                    <Highlight
                        className={"features-full-description-text-highlight"}
                        styles={{ bg: "yellow.400", color: "brand.800", px: 1 }}
                        query={[ "단계별 강사님의 1:1강의", "대표의 피드백", "핫한 공구상품과 공구몰" ]}>

                        「꿈을더하다」는 달콤한 말뿐인 부업들과 무책임한 교육에 실망하는 분들이 더이상 피해보는 일이 없도록
                        두 명의 대표와 각 분야 강사들이 힘을 모아 만든 교육법입니다.
                        업계 최초로 단계별 강사님의 1:1강의와 대표의 피드백, 매일 쏟아지는 핫한 공구상품과 공구몰까지!
                    </Highlight>
                    <br/>
                    A부터 Z까지 집에서 편하게 교육 받으세요!
                </Text>
                <ButtonContainer
                    className={"features-contact-us-button-container"}
                    w={"full"}
                    justify={"center"}
                    pt={4}
                    pb={2}>
                    <ContactUsButton
                        justify={"center"}
                        btnProps={{ variant: "outline", bg: "white", w: "full", shadow: "md", rounded: "md", color: "brand.400" }}>
                        바로 상담하기 💬
                    </ContactUsButton>
                </ButtonContainer>
            </Box>
        </Flex>
    </Box>
}