import * as React from "react"
import { Box, Skeleton, VStack } from "@chakra-ui/react"

export function BlogPostSkeleton() {
    return (
        <Box
            h={"100vh"}
            px={[3, 6, 8]}
            py={{ base: 8 }}
            mx={"auto"}
            w={["full"]}
            maxW={"3xl"}
        >
            <VStack>
                <Skeleton
                    startColor="bg2.50"
                    endColor="bg2.200"
                    w={"90%"}
                    h={"30px"}
                    mb={3}
                />
                <Skeleton
                    startColor="bg2.50"
                    endColor="bg2.200"
                    w={"100px"}
                    h={"22px"}
                />
                <Skeleton
                    startColor="bg2.50"
                    endColor="bg2.200"
                    w={"120px"}
                    h={"22px"}
                    mb={8}
                />
                <Box h={"50px"} />
                <Skeleton
                    startColor="bg2.50"
                    endColor="bg2.200"
                    w={"70%"}
                    h={"22px"}
                />
                <Skeleton
                    startColor="bg2.50"
                    endColor="bg2.200"
                    w={"80%"}
                    h={"22px"}
                />
                <Skeleton
                    startColor="bg2.50"
                    endColor="bg2.200"
                    w={"78%"}
                    h={"22px"}
                />
                <Box h={"40px"} />
                <Skeleton
                    startColor="bg2.50"
                    endColor="bg2.200"
                    p={16}
                    w={"full"}
                    h={"400px"}
                />
            </VStack>
        </Box>
    )
}
