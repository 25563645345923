import {
    Box,
    Center,
    Stack,
    Text,
    useColorModeValue as mode,
    useToken,
    VisuallyHidden,
} from '@chakra-ui/react'
import { transparentize } from '@chakra-ui/theme-tools'
import * as React from 'react'
import { NavLink } from "react-router-dom"
import { useSite } from "../../contexts/site-provider"

function MobileNavAction(props) {
    const { label, icon, isActive, to, href, onClick, disabled } = props
    const { variant } = useSite()
    const brand500 = useToken('colors', `${variant}.500`)

    let link = {};
    if (onClick) link = { as: "div", onClick }
    if (href) link = { as: "a", href }
    if (to) link = { as: NavLink, to }

    return (
        <Center
            {...link}
            height="3.5rem"
            rounded="4"
            aria-current={isActive ? 'page' : undefined}
            // bg={"white"}
            _activeLink={{
                bg: mode(transparentize(`${variant}.50`, .3), transparentize(brand500, 0.2)),
                color: mode(`${variant}.500`, `${variant}.300`),
            }}
            _hover={{
                bg: mode('gray.100', 'gray.700'),
            }}

        >
            <Stack direction="column" align="center" as="button" spacing="0" {...disabled}>
                <Box fontSize="xl" as={icon}/>
                <Text fontSize="sm" fontWeight="medium">
                    {label}
                </Text>
            </Stack>
        </Center>
    )
}

function DesktopNavAction(props) {
    const { label, icon, isActive, href, onClick, color } = props

    const link = onClick ? { as: "div", onClick } : { as: "a", href }
    const buttonColor = color || mode('gray.600', 'gray.300')
    return (
        <Center
            {...link}
            w="8"
            h="8"
            aria-current={isActive ? 'page' : undefined}>
            <VisuallyHidden>{label}</VisuallyHidden>
            <Box
                focusable="false"
                fontSize="xl" as={icon}
                color={buttonColor}/>
        </Center>
    )
}

export const NavAction = {
    Mobile: MobileNavAction,
    Desktop: DesktopNavAction,
}
