import {
    extendTheme,
    withDefaultColorScheme,
} from '@chakra-ui/react'
import colors from "./foundations/colors"
import { globalStyles } from "./global"
import * as foundations from "./foundations"
import * as components from "./components"

// export { ChakraUiTheme, useThemes } from "./chakra-ui-theme"



export const getBaseTheme = () => {
    return extendTheme(globalStyles, { ...foundations, colors })
}

export const createSiteTheme = (siteTheme, colorScheme = "brand") => {
    // console.log("createSiteTheme", foundations, siteTheme.fonts)

    return extendTheme(
        getBaseTheme(),
        { fonts: {...foundations.fonts, ...siteTheme.fonts }},
        { colors: { ...colors, ...siteTheme.colors }},
        { components: { ...components, ...siteTheme.components }},
        withDefaultColorScheme({
            colorScheme
        }))
}
