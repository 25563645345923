import * as React from "react"
import { VStack, Flex, Image, Divider, Text, HStack } from "@chakra-ui/react"

import darkLogoP from "../assets/logos/min/꿈을더하다_로고_black.png"
import lightLogoP from "../assets/logos/min/꿈을더하다_로고_white.png"
import tinyDarkLogoP from "../assets/logos/min/꿈을더하다_로고_black-sm.png"
import tinyLightLogoP from "../assets/logos/min/꿈을더하다_로고_white-sm.png"

import darkLogoA from "../assets/logos/min/꿈을더하다_로고_black.avif"
import lightLogoA from "../assets/logos/min/꿈을더하다_로고_white.avif"
import tinyDarkLogoA from "../assets/logos/min/꿈을더하다_로고_black-sm.avif"
import tinyLightLogoA from "../assets/logos/min/꿈을더하다_로고_white-sm.avif"

import darkLogoW from "../assets/logos/min/꿈을더하다_로고_black.webp"
import lightLogoW from "../assets/logos/min/꿈을더하다_로고_white.webp"
import tinyDarkLogoW from "../assets/logos/min/꿈을더하다_로고_black-sm.webp"
import tinyLightLogoW from "../assets/logos/min/꿈을더하다_로고_white-sm.webp"

const darkLogo = {
    src: darkLogoP,
    avif: darkLogoA,
    webp: darkLogoW,
}
const lightLogo = {
    src: lightLogoP,
    avif: lightLogoA,
    webp: lightLogoW,
}
const tinyDarkLogo = {
    src: tinyDarkLogoP,
    avif: tinyDarkLogoA,
    webp: tinyDarkLogoW,
}
const tinyLightLogo = {
    src: tinyLightLogoP,
    avif: tinyLightLogoA,
    webp: tinyLightLogoW,
}

export function TextWithLogo({ logo, imgProps, textProps }) {
    const Logo = logo || <TinyLogoDark maxH={"30px"} {...imgProps} />

    return (
        <HStack spacing={"2"} display={"flex"}>
            {Logo}
            <Text
                as={"span"}
                display={"inline-block"}
                whiteSpace={"nowrap"}
                fontWeight={"semibold"}
                fontFamily={"Montserrat"}
                textTransform={"uppercase"}
                {...textProps}
            >
                DREAM MORE
            </Text>
        </HStack>
    )
}

export function TextWithTinyLogoLight(props) {
    return (
        <TextWithLogo
            logo={<TinyLogoLight maxH={"30px"} />}
            textProps={{ color: "white" }}
            {...props}
        />
    )
}

export function TextWithTinyLogoDark(props) {
    return <TextWithLogo logo={<TinyLogoDark maxH={"30px"} />} {...props} />
}

export function LogoDark({ imgProps, ...rest }) {
    return (
        <Logo
            img={darkLogo}
            color={"blackAlpha.600"}
            imgProps={imgProps}
            {...rest}
        />
    )
}

export function LogoLight({ imgProps, ...rest }) {
    return (
        <Logo img={lightLogo} color={"white"} imgProps={imgProps} {...rest} />
    )
}

export function PlainLogoDark(props) {
    return <PlainLogo img={darkLogo} {...props} />
}

export function PlainLogoLight(props) {
    return <PlainLogo img={lightLogo} {...props} />
}

export function PlainLogo({ img, ...imgProps }) {
    return (
        <picture>
            <Image
                src={img.src}
                alt={"꿈을더하다 로고"}
                maxH={"50px"}
                {...imgProps}
            />
        </picture>
    )
}

export function TinyLogoDark(props) {
    return <TinyLogo img={tinyDarkLogo} {...props} />
}

export function TinyLogoLight(props) {
    return <TinyLogo img={tinyLightLogo} {...props} />
}

export function TinyLogo({ img, ...imgProps }) {
    return (
        <picture>
            <Image src={img.src} alt={"꿈을더하다 로고"} {...imgProps} />
        </picture>
    )
}

export function Logo({ img, color, imgProps, textProps, ...rest }) {
    const [imageLoaded, setImageLoaded] = React.useState(false)

    return (
        <Flex align={"center"} mb={5} {...rest}>
            <VStack
                // pt={[ 6 ]}
                justify={"center"}
                mx={"auto"}
            >
                <picture>
                    <Image as="source" srcSet={img.avif} type="image/avif" />
                    <Image as="source" srcSet={img.webp} type="image/webp" />
                    <Image
                        src={img.src}
                        alt={"꿈을더하다 로고"}
                        maxH={"50px"}
                        onLoad={() => setImageLoaded(true)}
                        {...imgProps}
                    />
                </picture>

                <Flex
                    justify={"space-between"}
                    w={"full"}
                    mt={0}
                    style={{ marginTop: 0 }}
                >
                    {imageLoaded && (
                        <Text
                            as={"div"}
                            pt={1}
                            pr={1}
                            color={color}
                            fontWeight={600}
                            fontSize={"sm"}
                            fontFamily={"Montserrat"}
                            textTransform={"uppercase"}
                            whiteSpace={"nowrap"}
                            letterSpacing={"tight"}
                            {...textProps}
                        >
                            {"Dream More"}
                        </Text>
                    )}
                    <Divider
                        flexGrow={1}
                        my={"auto"}
                        borderColor={color}
                        borderBottomWidth={"1px"}
                    />
                </Flex>
            </VStack>
        </Flex>
    )
}
