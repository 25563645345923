import * as React from "react"
import {
    Box,
    VStack,
    Heading,
    Text,
    Flex,
    List,
    ListItem,
    ListIcon,
} from "@chakra-ui/react"
import { ArrowForwardIcon } from "@chakra-ui/icons"

import { SkeletonOptimizedImage } from "../../../components/optimized-image/skeleton-optimized-image"

export function Recommendation({ img, titleNo, features }) {
    // console.log(img)

    const imgProps = {
        className: "recommendation-image",
        // src : img,
        borderRadius: "full",
        borderColor: "bg2.300",
        borderWidth: "10px",
        borderStyle: "solid",
        // position : "absolute",
        left: 0,
        top: 0,
        bg: "white",
        objectFit: "cover",
        boxSize: "120px",
        // ml : 6,
        zIndex: 1,
        shadow: "sm",
    }

    return (
        <Box className={"recommendation-container"} pt={{ base: 6 }}>
            <Flex
                className={"recommendation-image-container"}
                h={"120px"}
                position={"relative"}
                rounded={"lg"}
                bg={"bg2.100"}
                pl={6}
            >
                <VStack
                    position={"absolute"}
                    w={"full"}
                    bg={"white"}
                    top={"40%"}
                    left={0}
                    right={0}
                    bottom={0}
                />
                <SkeletonOptimizedImage
                    img={img}
                    imgProps={imgProps}
                    skeletonProps={{
                        rounded: "full",
                        zIndex: 1,
                    }}
                />
                {/*<Image*/}
                {/*    className={"recommendation-image"}*/}
                {/*    src={img}*/}
                {/*    borderRadius={"full"}*/}
                {/*    borderColor={"bg2.300"}*/}
                {/*    borderWidth={"10px"}*/}
                {/*    borderStyle={"solid"}*/}
                {/*    // position={"absolute"}*/}
                {/*    left={0}*/}
                {/*    top={0}*/}
                {/*    bg={"white"}*/}
                {/*    objectFit={"cover"}*/}
                {/*    boxSize={"120px"}*/}
                {/*    ml={6}*/}
                {/*    zIndex={1}*/}
                {/*    shadow={"sm"}*/}
                {/*/>*/}
            </Flex>

            <VStack
                className={"recommendation-text-container"}
                shadow={"sm"}
                px={{ base: 4, lg: 8 }}
                w={"full"}
                bg={"white"}
                align={"flex-start"}
                textAlign={"left"}
                minH={"260px"}
            >
                <Heading
                    className={"recommendation-text-title"}
                    as={"p"}
                    lineHeight={2}
                    fontFamily={"Montserrat"}
                    textTransform={"uppercase"}
                    fontSize={"1.3rem"}
                >
                    <Text as={"span"} fontSize={"1.6rem"}>
                        {titleNo}.
                    </Text>{" "}
                    Recommendation
                </Heading>
                <List className={"recommendation-text-list"} spacing={3}>
                    {features.map((feature, i) => (
                        <ListItem
                            className={"recommendation-text-list-item"}
                            key={i}
                        >
                            <Flex
                                className={
                                    "recommendation-text-list-item-container"
                                }
                                align={"baseline"}
                                wordBreak={"keep-all"}
                            >
                                <ListIcon
                                    as={ArrowForwardIcon}
                                    color={"bg2.300"}
                                />
                                {feature}
                            </Flex>
                        </ListItem>
                    ))}
                </List>
            </VStack>
        </Box>
    )
}
