import * as React from "react"
import { Skeleton } from "@chakra-ui/react"

import { OptimizedImage } from "./optimized-image"

export function SkeletonOptimizedImage({ img, skeletonProps, imgProps, pictureProps, isLazy, ...rest }) {

    const [isLoaded, setIsLoaded] = React.useState(false)
    // const isLoaded = React.useRef

    return <Skeleton
        startColor='bg2.50'
        endColor='bg2.200'
        isLoaded={isLoaded}
        fadeDuration={1}
        {...skeletonProps}
    >
        <OptimizedImage
            img={img}
            setIsLoaded={setIsLoaded}
            isLazy={isLazy && !isLoaded}
            imgProps={imgProps}
            pictureProps={pictureProps}
            { ...rest } />
    </Skeleton>
}