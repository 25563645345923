import icons00avif from "./icon_blog.avif"
import icons00png from "./icon_blog.png"
import icons00webp from "./icon_blog.webp"
import icons01avif from "./icon_kakao.avif"
import icons01png from "./icon_kakao.png"
import icons01webp from "./icon_kakao.webp"

export const imgs = {
    naverBlog: {
        avif: icons00avif,
        src: icons00png,
        webp: icons00webp,
    },
    kakaoTalk: {
        avif: icons01avif,
        src: icons01png,
        webp: icons01webp,
    },
}
export default imgs
