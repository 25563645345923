import { Button, HStack, Icon, Text } from "@chakra-ui/react"

export function ShellSidebarLink({ icon, label, ...buttonProps }) {
    return (
        <Button
            variant="ghost"
            justifyContent="start"
            px={0}
            h={8}
            w={"full"}
            bg={"unset !important"}
            shadow={"none"}
            {...buttonProps}
        >
            <HStack spacing="3">
                {icon && <Icon as={icon} boxSize="6" color="subtle" />}
                <Text>{label}</Text>
            </HStack>
        </Button>
    )
}
