import * as React from "react"
import { chakra, Box, Image } from "@chakra-ui/react"
import LazyLoad from "react-lazy-load"

export function OptimizedImage({
    img,
    imgProps,
    pictureProps,
    setIsLoaded = () => {},
    isLazy,
}) {
    const picture = (
        <chakra.picture {...pictureProps}>
            {img.avif && (
                <Image as="source" srcSet={img.avif} type="image/avif" />
            )}
            {img.webp && (
                <Image as="source" srcSet={img.webp} type="image/webp" />
            )}
            <Image
                onLoad={() => setIsLoaded(true)}
                src={img.src}
                alt={img.alt || "img"}
                {...imgProps}
            />
        </chakra.picture>
    )

    if (isLazy)
        return (
            <Box
                sx={{
                    ".LazyLoad": {
                        opacity: 0,
                        transition: "all 1s ease-in-out",
                    },
                    ".is-visible": {
                        opacity: 1,
                    },
                }}
            >
                <LazyLoad>{picture}</LazyLoad>
            </Box>
        )

    return picture
}
