import * as React from "react"
import { Box } from "@chakra-ui/react"
import { SectionHeader } from "../section/section-header"

import { QAndACard } from "./q-and-a-card"
import { HorizontalScroll } from "../../../components/scroll/horizontal-scroll"

import y01 from "../../assets/interview_corner/꿈을더하다 안예람 대표.jpg"
import y02 from "../../assets/interview_corner/육아맘.jpg"
import y03 from "../../assets/interview_corner/교육과정.jpg"

import ys01 from "../../assets/interview_corner/꿈을더하다 이수연 대표.jpg"
import ys02 from "../../assets/interview_corner/인플루언서.jpg"
import ys03 from "../../assets/interview_corner/인스타 공구.jpg"

const yeram = [
    {
        img: y01,
        imgProps: {
            // maxW: "250px",
            // pl: 4,
            pt: 3,
            // mx: "auto",
            alt: "안예람 대표"
        },
        no: "01",
        q: "대표님에 대해 한 마디로 소개 부탁드려요!",
        a: "안녕하세요. 저는 투잡삼아 시작했던 인스타마켓이 본업이 되고, 제가 성과를 냈던 방법을 많은 수강생에게 가르쳐 오면서 어느덧 공구교육 (주)꿈을더하다 대표가 된 안예람입니다."
    },
    {
        img: y02,
        imgProps: {
            alt: "육아맘"
        },
        no: "02",
        q: "아무것도 모르는 평범한 육아맘 or 직장인도 시작할 수 있나요?",
        a: "연필 한자루 팔아본적 없는 분들도 배우면 누구나 인스타 공구가 가능합니다. 인스타마케팅의 기초부터, 판매 기초~ 심화의 모든과정을 A~Z까지 강사님들과 소통하며 1:1 및 그룹레슨으로 배우실 거예요. 마치 개인 PT를 받는것 처럼요."

    },
    {
        img: y03,
        imgProps: {
            alt: "교육과정"
        },
        no: "03",
        q: "이 교육과정을 이수하면 어떤 변화가 있나요?",
        a: "버스나 지하철, 출퇴근길, 혹은 침대에 뒹굴거리는 시간, 티비보는 자투리 시간들을 다 모아 하루 2시간을 생산적인 시간으로 바꿔보세요. 단조로웠던 일상속에고객문의, 입금 알림이 울릴때마다 생활에 활력이 생기실 거예요. 지금보다 나은 경제적 여유는 덤이구요."
    }, ]
const ys = [
    {
        img: ys01,
        imgProps: {
            maxW: "250px",
            pt: 3,
            mx: "auto",
            alt: "이수연 대표"
        },
        no: "01",
        q: "대표님에 대해 한 마디로 소개 부탁드려요!",
        a: "아이 둘 낳고 경력단절이 된 후, 구인구직 사이트를 매일 밤들어가보며 한숨만 내쉬던 제가, 인스타마켓으로 인생역전을 하였어요. 누구에게나 공평한 인스타마켓을 널리 알리고싶어 회사를 설립했어요."
    },
    {
        img: ys02,
        no: "02",
        imgProps: {
            alt: "인플루언서"
        },
        q: "인플루언서가 아니어도 상관없나요?",
        a: "네, 가능합니다. 심지어 꿈을더하다 수강생의 99%는 인스타그램 새계정에 시작하고 있어요. 팔로워 0부터 시작함에도 불구하고 찐고객층으로 팔로워를 확보해 나가는 방법을 인스타 교육과정에서 충분히 배우실거예요. 평범했던 내 계정에 인친들이 바글바글 모여드는 마법을 경험해 보세요."
    },
    {
        img: ys03,
        imgProps: {
            alt: "인스타 공구"
        },
        no: "03",
        q: "저는 지인이 없는데도 판매가 가능할까요?",
        a: "네, 가능합니다. 인스타 공구는 지인과 전혀 상관이 없습니다. 내 지인에게 하나만 구매해달라면서 부탁하며 판매하는 일이었다면 저조차도 시작 못했을 거예요. 지인이 한명도 없어도 괜찮습니다. 인스타그램의 수많은 유저들이 내 잠재고객이니까요!"
    },
]


export function InterviewCorner() {

    return <Box
        className={"interview-corner-section"}
        as={"section"}
        bg={"bg2.100"}
        textAlign={"center"}


    >
        <Box
            className={"interview-corner-container"}
            py={{ base: 8, md: 12 }}
            mx={"auto"}
            maxW={"4xl"}
        >
            <SectionHeader
                title={"Interview Corner"}
                description={"업계 최초 전문 강사제를 도입하여 체계적이고 정확한 교육을 실천합니다."}
                titleProps={{ fontFamily: "Montserrat", textTransform: "uppercase", fontWeight: "bold" }}
                px={{ base: 6 }}
            />
            <HorizontalScroll
                className={"interview-image-gallery"}
                items={yeram}
                Component={QAndACard}
                w={"90vw"}
                maxW={"450px"}
                pl={{base: 4}}
            />
            <HorizontalScroll
                className={"interview-image-gallery"}
                items={ys}
                Component={QAndACard}
                w={"90vw"}
                maxW={"450px"}
                pl={{base: 4}}
            />

        </Box>



    </Box>
}