import * as React from "react"
import { useLocation } from "react-router-dom"

export const ScrollToTop = ({children}) => {
    const location = useLocation();
    React.useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
}

export default ScrollToTop
