import * as React from "react"
import { AspectRatio, Box, Flex, Image } from "@chakra-ui/react"
import { ScrollMenu } from "react-horizontal-scrolling-menu"
import LazyLoad from "react-lazy-load"
import { motion } from "framer-motion"

import { useHorizontalScroll, Dots } from "../../hooks/use-horizontal-scroll"
import { SkeletonOptimizedImage } from "../optimized-image/skeleton-optimized-image"

const slideLeft = {
    hidden: {
        x: '100%',
        // opacity: 0,
        // duration: 2000
    },
    visible: {
        x: 0,
        // opacity: 1,
        duration: 2000
    }
}

export function ScrollImage({ img }) {

    if (img.src) return <SkeletonOptimizedImage
        img={img}
        // isLazy={true}
        imgProps={{
            alt: img.alt || "img",
            draggable: false,
        }}/>

    return <Image
        src={img}
        draggable={"false"}/>
}

export function HorizontalScroll(props) {

    const {
        items,
        aspect = "square",
        w = "80vw",
        maxW = "350px",
        Component,
        className = "horizontal-scroll",
        ...rest
    } = props

    const {
        ref,
        // isVisible,
        getScrollMenuProps, active
    } = useHorizontalScroll(items)

    const ratio = {
        square: 1,
        portrait: 9 / 16,
        landscape: 16 / 9
    }[aspect]

    return <Box
        className={className}
        w={"full"}
        py={4}
        ref={ref}
        overflowX={"hidden"}
        {...rest}
        __css={{
            "& .control-dots": {
                position: "relative"
            },
            "& .control-dots .dot": {
                boxShadow: "none",
                bg: "brand.300",
                mx: 1,
                w: "6px",
                h: "6px",
            },
            "& .control-dots .dot.selected": {
                boxShadow: "none",
                bg: "brand.500",

            },
            "& .thumbs-wrapper": {
                marginBottom: 1,
            },
            "& .slide": {
                opacity: .3
            },
            "& .slide.selected": {
                opacity: 1
            }
        }}
    >
        <motion.div
            className={"scroll-menu-motion"}
            initial="hidden" animate="visible" variants={slideLeft}>
            <LazyLoad offset={300}>
                <ScrollMenu {...getScrollMenuProps()}>
                    {
                        Component ?
                            items.map((item, index) => <Flex key={`${index}-e`} w={w} maxW={maxW}><Component
                                index={index} {...item} /></Flex>)
                            :
                            items.map((img, index) => <AspectRatio
                                key={`${index}-e`}
                                ratio={ratio}
                                mr={2}
                                w={w}
                                maxW={maxW}>
                                <ScrollImage img={img}/>
                            </AspectRatio>)
                    }
                </ScrollMenu>
            </LazyLoad>
        </motion.div>
        <Dots images={items} isActive={active}/>
    </Box>

}