import * as React from "react"
import { Box, SimpleGrid, VStack, AspectRatio, Text } from "@chakra-ui/react"
import { SkeletonOptimizedImage } from "../../../components/optimized-image/skeleton-optimized-image"


export function QAndACard({ img, no, q, a, imgProps = {} }) {

    return <Box
        className={"interview-cards"}
        rounded={"md"}
        px={{ base: 2, md: 0}}
    >
        <SimpleGrid
            className={"interview-cards-list"}
            columns={{ base: 1, md: 2 }}

            bg={"white"}
            rounded={'md'}>
            <AspectRatio
                className={"interview-card-wrapper"}
                ratio={{ base: 2 / 3, md: 9 / 16 }}
                h={{ base: "354px", md: "400px" }}
                w={"full"}
                bg={"white"}

            >
                <SkeletonOptimizedImage
                    img={{ src: img }}
                    imgProps={{
                        className: "interview-card-image",
                        pb: { base: 0, md: 4 },
                        // px: 2,
                        // pt: 2,
                        bg: "white",
                        h: "auto",
                        borderColor: "white",
                        borderStyle: "solid",
                        objectFit: "contain",
                        objectPosition: "10% top",
                        ...imgProps
                    }}
                />

            </AspectRatio>

            <VStack
                className={"interview-card-text"}
                bg={"white"}
                minH={"285px"}
                p={4}
                fontSize={".9rem"}
                lineHeight={1.05}
                fontWeight={"400"}
                color={"gray.800"}
                align={"left"}
                spacing={4}>

                <Text
                    className={"interview-card-label"}
                    fontSize={".95rem"}
                    textAlign={"left"}>
                    Question <Text as="span" fontWeight={"bold"}>{no}</Text>
                </Text>
                <Text
                    className={"interview-card-question"}
                    fontSize={"xl"}
                    fontFamily={"'Noto Sans KR'"}
                    lineHeight={1.2}
                    fontWeight={"600"}
                    textAlign={"left"}>
                    {q}
                </Text>
                <Text
                    className={"interview-card-answer"}
                    fontSize={"md"}
                    textAlign={"left"}
                    lineHeight={1.2}>
                    {a}
                </Text>
            </VStack>
        </SimpleGrid>
    </Box>
}