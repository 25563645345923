import * as React from "react"
import {
    Box,
    Stack,
    VStack,
    HStack,
    List,
    ListItem,
    Text,
    Flex,
} from "@chakra-ui/react"
import { isEmpty } from "lodash"

import { HorizontalScroll } from "../../../components/scroll/horizontal-scroll"
import { OptimizedImage } from "../../../components/optimized-image/optimized-image"


export function Course({ no, name, engName, icon, days, images, footnotes }) {


    return <Box
        w={"full"}
        px={2}
        py={2}>
        <VStack
            className={"course-stack"}
            bg={"white"}
            shadow={"md"}
            py={3}
            // px={{ base: 4, lg: 8 }}
            spacing={0}>
            <VStack
                px={3}
                w={"full"}
                maxW={"2xl"}
            >
                <HStack
                    w={"full"}
                    spacing={3}
                    py={{ base: 1 }}
                    px={2}
                >
                    <OptimizedImage
                        img={icon}
                        imgProps={{
                            w: {
                                base: "55px",
                                lg: "100px"
                            },
                            h: "auto",
                            alt: `${name} icon`
                        }}/>
                    {/*<Image src={icon} w={{ base: "55px", lg: "100px" }} h={"auto"}/>*/}
                    <Stack
                        direction={{ base: "column", lg: "row" }}
                        spacing={{ base: 0, lg: 3 }}
                        align={{ base: "flex-start", lg: "baseline" }}
                        letterSpacing={"tighter"}>
                        <HStack lineHeight={1.2} spacing={2}>
                            <Text
                                as={"span"}
                                color={"brand.400"}
                                fontSize={"2.3rem"}
                                fontFamily={"Montserrat"}
                                fontWeight={700}
                            >{no}.</Text>
                            <Text
                                letterSpacing={"tighter"}
                                color={"gray.700"}
                                fontSize={{ base: "3xl", lg: "2.2rem" }}
                                fontWeight={600}
                            >{name}</Text>
                        </HStack>
                        <Text
                            color={"brand.300"}
                            fontWeight={"bold"}
                            fontFamily={"Montserrat"}
                            textTransform={"uppercase"}
                        >{engName}</Text>
                    </Stack>
                </HStack>
                <VStack
                    bg={"bg2.50"}
                    w={"full"}
                    px={{ base: 4, lg: 8 }}
                    py={2}>
                    {isEmpty(days) ?
                        <Flex h={"50px"} w={"full"} px={3} py={2} mr={3}>
                            <Text fontWeight={"bold"}>Coming soon...</Text>
                        </Flex>
                        : <List w={"full"} textAlign={"left"} spacing={2} mr={2}>
                            {days.map((d, i) => <ListItem key={i}>
                                <HStack w={"full"} align={"middle"}>
                                    <Text
                                        as={"span"}
                                        bg={"gray.800"}
                                        color={"white"}
                                        fontWeight={"semibold"}
                                        // fontSize={"sm"}
                                        px={2}
                                        py={1}
                                        verticalAlign={"center"}
                                        maxH={"32px"}
                                    >{`${i + 1}일차`}</Text><Text>{d}</Text></HStack></ListItem>)}
                        </List>}
                    {!isEmpty(footnotes)
                        && footnotes.map((footnote, idx) =>
                            <Flex
                                pt={2}
                                key={idx}
                                w={"full"}
                                justify={"flex-start"}
                                pl={{base: 0, md: 2}}
                            >
                                <Text
                                    fontSize={"sm"}
                                    color={"muted"}>{footnote}</Text></Flex>)}
                </VStack>
            </VStack>


            {!isEmpty(images) && <HorizontalScroll
                items={images}
                w={"78vw"}
                maxW={"350px"}
                aspect={"portrait"}
                pl={3}
            />}
        </VStack>
    </Box>
}