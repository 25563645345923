import { Box, Button, Link, Stack, Text } from '@chakra-ui/react'
import * as React from 'react'
import { useNavigate } from "react-router-dom"
import { usePrefix } from "../../contexts/prefix-provider"

export function SubNavLinkGroup(props) {
    const { label, links } = props
    return (
        <Box>
            <Text fontSize="xs" textTransform="uppercase" fontWeight="bold" letterSpacing="wide">
                {label}
            </Text>
            <Stack spacing="4" as="ul" mt="6" listStyleType="none">
                {links
                    .filter(({ footerOnly }) => !footerOnly)
                    .map(({ label, url }, idx) => (
                    <li key={idx}>
                        <Link href={url} fontSize="sm">
                            {label}
                        </Link>
                    </li>
                ))}
            </Stack>
        </Box>
    )
}

export function SubNavLinkMobileGroup(props) {
    const { label, routes, menu } = props
    const prefix = usePrefix()
    const navigate = useNavigate()
    return (
        <Box w={"full"}>
            {label && <Text fontSize="md" textTransform="uppercase" fontWeight="bold" letterSpacing="wide">
                {label}
            </Text>}
            <Stack spacing="5" as="ul" mt="4" listStyleType="none">
                {routes
                    .filter(({ footerOnly }) => !footerOnly)
                    .map(({ label, path, href }, idx) => {
                    if (href) {
                        return (
                            <li key={idx}>
                                <Link href={href} fontSize="lg">
                                    {label}
                                </Link>
                            </li>
                        )
                    }
                    return (
                        <li key={idx}>
                            <Button
                                variant={"link"}
                                shadow={"none"}
                                onClick={() => {
                                    menu.onClose()
                                    navigate(prefix(path))
                                }}
                                fontSize="lg"
                                color={"brand.700"}
                                fontFamily={"Montserrat"}
                                fontWeight={"bold"}
                                textTransform={"uppercase"}>
                                {label}
                            </Button>
                        </li>
                    )
                })}
            </Stack>
        </Box>
    )
}
