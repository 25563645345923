import * as React from "react"
import { Outlet } from "react-router-dom"
import { Box } from "@chakra-ui/react"

export function useOutletOrChildrenWithProps(context, children, className="outlet-context") {
    if (children) {
        const childrenWithProps = React.Children.map(children, (child, ix) => {
            // Checking isValidElement is the safe way and avoids a typescript
            // error too.
            if (React.isValidElement(child)) {
                return React.cloneElement(child, { key: ix, ...context })
            }
            return child
        })

        return <Box className={className}>{childrenWithProps}</Box>
    }
    return <Outlet context={context}/>
}