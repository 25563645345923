import intensiveCourses00avif from "./01_1.avif"
import intensiveCourses00jpg from "./01_1.jpg"
import intensiveCourses00webp from "./01_1.webp"
import intensiveCourses01avif from "./01_10.avif"
import intensiveCourses01jpg from "./01_10.jpg"
import intensiveCourses01webp from "./01_10.webp"
import intensiveCourses02avif from "./01_11.avif"
import intensiveCourses02jpg from "./01_11.jpg"
import intensiveCourses02webp from "./01_11.webp"
import intensiveCourses03avif from "./01_12.avif"
import intensiveCourses03jpg from "./01_12.jpg"
import intensiveCourses03webp from "./01_12.webp"
import intensiveCourses04avif from "./01_2.avif"
import intensiveCourses04jpg from "./01_2.jpg"
import intensiveCourses04webp from "./01_2.webp"
import intensiveCourses05avif from "./01_3.avif"
import intensiveCourses05jpg from "./01_3.jpg"
import intensiveCourses05webp from "./01_3.webp"
import intensiveCourses06avif from "./01_4.avif"
import intensiveCourses06jpg from "./01_4.jpg"
import intensiveCourses06webp from "./01_4.webp"
import intensiveCourses07avif from "./01_5.avif"
import intensiveCourses07jpg from "./01_5.jpg"
import intensiveCourses07webp from "./01_5.webp"
import intensiveCourses08avif from "./01_6.avif"
import intensiveCourses08jpg from "./01_6.jpg"
import intensiveCourses08webp from "./01_6.webp"
import intensiveCourses09avif from "./01_7.avif"
import intensiveCourses09jpg from "./01_7.jpg"
import intensiveCourses09webp from "./01_7.webp"
import intensiveCourses10avif from "./01_8.avif"
import intensiveCourses10jpg from "./01_8.jpg"
import intensiveCourses10webp from "./01_8.webp"
import intensiveCourses11avif from "./01_9.avif"
import intensiveCourses11jpg from "./01_9.jpg"
import intensiveCourses11webp from "./01_9.webp"
import intensiveCourses12avif from "./02_1.avif"
import intensiveCourses12jpg from "./02_1.jpg"
import intensiveCourses12webp from "./02_1.webp"
import intensiveCourses13avif from "./02_2.avif"
import intensiveCourses13jpg from "./02_2.jpg"
import intensiveCourses13webp from "./02_2.webp"
import intensiveCourses14avif from "./02_3.avif"
import intensiveCourses14jpg from "./02_3.jpg"
import intensiveCourses14webp from "./02_3.webp"
import intensiveCourses15avif from "./02_4.avif"
import intensiveCourses15jpg from "./02_4.jpg"
import intensiveCourses15webp from "./02_4.webp"
import intensiveCourses16avif from "./02_5.avif"
import intensiveCourses16jpg from "./02_5.jpg"
import intensiveCourses16webp from "./02_5.webp"
import intensiveCourses17avif from "./02_6.avif"
import intensiveCourses17jpg from "./02_6.jpg"
import intensiveCourses17webp from "./02_6.webp"
import intensiveCourses18avif from "./02_7.avif"
import intensiveCourses18jpg from "./02_7.jpg"
import intensiveCourses18webp from "./02_7.webp"
import intensiveCourses19avif from "./02_8.avif"
import intensiveCourses19jpg from "./02_8.jpg"
import intensiveCourses19webp from "./02_8.webp"
import intensiveCourses20avif from "./02_9.avif"
import intensiveCourses20jpg from "./02_9.jpg"
import intensiveCourses20webp from "./02_9.webp"
import intensiveCourses21avif from "./03_1.avif"
import intensiveCourses21jpg from "./03_1.jpg"
import intensiveCourses21webp from "./03_1.webp"
import intensiveCourses22avif from "./03_2.avif"
import intensiveCourses22jpg from "./03_2.jpg"
import intensiveCourses22webp from "./03_2.webp"
import intensiveCourses23avif from "./03_3.avif"
import intensiveCourses23jpg from "./03_3.jpg"
import intensiveCourses23webp from "./03_3.webp"
import intensiveCourses24avif from "./03_4.avif"
import intensiveCourses24jpg from "./03_4.jpg"
import intensiveCourses24webp from "./03_4.webp"
import intensiveCourses25avif from "./03_5.avif"
import intensiveCourses25jpg from "./03_5.jpg"
import intensiveCourses25webp from "./03_5.webp"
import intensiveCourses26avif from "./03_6.avif"
import intensiveCourses26jpg from "./03_6.jpg"
import intensiveCourses26webp from "./03_6.webp"
import intensiveCourses27avif from "./03_7.avif"
import intensiveCourses27jpg from "./03_7.jpg"
import intensiveCourses27webp from "./03_7.webp"
import intensiveCourses28avif from "./03_8.avif"
import intensiveCourses28jpg from "./03_8.jpg"
import intensiveCourses28webp from "./03_8.webp"
import intensiveCourses29avif from "./04_1.avif"
import intensiveCourses29jpg from "./04_1.jpg"
import intensiveCourses29webp from "./04_1.webp"
import intensiveCourses30avif from "./04_10.avif"
import intensiveCourses30jpg from "./04_10.jpg"
import intensiveCourses30webp from "./04_10.webp"
import intensiveCourses31avif from "./04_2.avif"
import intensiveCourses31jpg from "./04_2.jpg"
import intensiveCourses31webp from "./04_2.webp"
import intensiveCourses32avif from "./04_3.avif"
import intensiveCourses32jpg from "./04_3.jpg"
import intensiveCourses32webp from "./04_3.webp"
import intensiveCourses33avif from "./04_4.avif"
import intensiveCourses33jpg from "./04_4.jpg"
import intensiveCourses33webp from "./04_4.webp"
import intensiveCourses34avif from "./04_5.avif"
import intensiveCourses34jpg from "./04_5.jpg"
import intensiveCourses34webp from "./04_5.webp"
import intensiveCourses35avif from "./04_6.avif"
import intensiveCourses35jpg from "./04_6.jpg"
import intensiveCourses35webp from "./04_6.webp"
import intensiveCourses36avif from "./04_7.avif"
import intensiveCourses36jpg from "./04_7.jpg"
import intensiveCourses36webp from "./04_7.webp"
import intensiveCourses37avif from "./04_8.avif"
import intensiveCourses37jpg from "./04_8.jpg"
import intensiveCourses37webp from "./04_8.webp"
import intensiveCourses38avif from "./04_9.avif"
import intensiveCourses38jpg from "./04_9.jpg"
import intensiveCourses38webp from "./04_9.webp"
import intensiveCourses39avif from "./class_icon01b.avif"
import intensiveCourses39png from "./class_icon01b.png"
import intensiveCourses39webp from "./class_icon01b.webp"
import intensiveCourses40avif from "./class_icon02b.avif"
import intensiveCourses40png from "./class_icon02b.png"
import intensiveCourses40webp from "./class_icon02b.webp"
import intensiveCourses41avif from "./class_icon03b.avif"
import intensiveCourses41png from "./class_icon03b.png"
import intensiveCourses41webp from "./class_icon03b.webp"
import intensiveCourses42avif from "./class_icon04b.avif"
import intensiveCourses42png from "./class_icon04b.png"
import intensiveCourses42webp from "./class_icon04b.webp"
import intensiveCourses43avif from "./class_icon05b.avif"
import intensiveCourses43png from "./class_icon05b.png"
import intensiveCourses43webp from "./class_icon05b.webp"

export const imgs = {
    course01: [
        {
            avif: intensiveCourses00avif,
            src: intensiveCourses00jpg,
            webp: intensiveCourses00webp,
        },

        {
            avif: intensiveCourses04avif,
            src: intensiveCourses04jpg,
            webp: intensiveCourses04webp,
        },
        {
            avif: intensiveCourses05avif,
            src: intensiveCourses05jpg,
            webp: intensiveCourses05webp,
        },
        {
            avif: intensiveCourses06avif,
            src: intensiveCourses06jpg,
            webp: intensiveCourses06webp,
        },
        {
            avif: intensiveCourses07avif,
            src: intensiveCourses07jpg,
            webp: intensiveCourses07webp,
        },
        {
            avif: intensiveCourses08avif,
            src: intensiveCourses08jpg,
            webp: intensiveCourses08webp,
        },
        {
            avif: intensiveCourses09avif,
            src: intensiveCourses09jpg,
            webp: intensiveCourses09webp,
        },
        {
            avif: intensiveCourses10avif,
            src: intensiveCourses10jpg,
            webp: intensiveCourses10webp,
        },
        {
            avif: intensiveCourses11avif,
            src: intensiveCourses11jpg,
            webp: intensiveCourses11webp,
        },
        {
            avif: intensiveCourses01avif,
            src: intensiveCourses01jpg,
            webp: intensiveCourses01webp,
        },
        {
            avif: intensiveCourses02avif,
            src: intensiveCourses02jpg,
            webp: intensiveCourses02webp,
        },
        {
            avif: intensiveCourses03avif,
            src: intensiveCourses03jpg,
            webp: intensiveCourses03webp,
        },
    ],
    course02: [
        {
            avif: intensiveCourses12avif,
            src: intensiveCourses12jpg,
            webp: intensiveCourses12webp,
        },
        {
            avif: intensiveCourses13avif,
            src: intensiveCourses13jpg,
            webp: intensiveCourses13webp,
        },
        {
            avif: intensiveCourses14avif,
            src: intensiveCourses14jpg,
            webp: intensiveCourses14webp,
        },
        {
            avif: intensiveCourses15avif,
            src: intensiveCourses15jpg,
            webp: intensiveCourses15webp,
        },
        {
            avif: intensiveCourses16avif,
            src: intensiveCourses16jpg,
            webp: intensiveCourses16webp,
        },
        {
            avif: intensiveCourses17avif,
            src: intensiveCourses17jpg,
            webp: intensiveCourses17webp,
        },
        {
            avif: intensiveCourses18avif,
            src: intensiveCourses18jpg,
            webp: intensiveCourses18webp,
        },
        {
            avif: intensiveCourses19avif,
            src: intensiveCourses19jpg,
            webp: intensiveCourses19webp,
        },
        {
            avif: intensiveCourses20avif,
            src: intensiveCourses20jpg,
            webp: intensiveCourses20webp,
        },
    ],
    course03: [
        {
            avif: intensiveCourses21avif,
            src: intensiveCourses21jpg,
            webp: intensiveCourses21webp,
        },
        {
            avif: intensiveCourses22avif,
            src: intensiveCourses22jpg,
            webp: intensiveCourses22webp,
        },
        {
            avif: intensiveCourses23avif,
            src: intensiveCourses23jpg,
            webp: intensiveCourses23webp,
        },
        {
            avif: intensiveCourses24avif,
            src: intensiveCourses24jpg,
            webp: intensiveCourses24webp,
        },
        {
            avif: intensiveCourses25avif,
            src: intensiveCourses25jpg,
            webp: intensiveCourses25webp,
        },
        {
            avif: intensiveCourses26avif,
            src: intensiveCourses26jpg,
            webp: intensiveCourses26webp,
        },
        {
            avif: intensiveCourses27avif,
            src: intensiveCourses27jpg,
            webp: intensiveCourses27webp,
        },
        {
            avif: intensiveCourses28avif,
            src: intensiveCourses28jpg,
            webp: intensiveCourses28webp,
        },
    ],
    course04: [
        {
            avif: intensiveCourses29avif,
            src: intensiveCourses29jpg,
            webp: intensiveCourses29webp,
        },

        {
            avif: intensiveCourses31avif,
            src: intensiveCourses31jpg,
            webp: intensiveCourses31webp,
        },
        {
            avif: intensiveCourses32avif,
            src: intensiveCourses32jpg,
            webp: intensiveCourses32webp,
        },
        {
            avif: intensiveCourses33avif,
            src: intensiveCourses33jpg,
            webp: intensiveCourses33webp,
        },
        {
            avif: intensiveCourses34avif,
            src: intensiveCourses34jpg,
            webp: intensiveCourses34webp,
        },
        {
            avif: intensiveCourses35avif,
            src: intensiveCourses35jpg,
            webp: intensiveCourses35webp,
        },
        {
            avif: intensiveCourses36avif,
            src: intensiveCourses36jpg,
            webp: intensiveCourses36webp,
        },
        {
            avif: intensiveCourses37avif,
            src: intensiveCourses37jpg,
            webp: intensiveCourses37webp,
        },
        {
            avif: intensiveCourses38avif,
            src: intensiveCourses38jpg,
            webp: intensiveCourses38webp,
        },
        {
            avif: intensiveCourses30avif,
            src: intensiveCourses30jpg,
            webp: intensiveCourses30webp,
        },
    ],
    icon01: {
        avif: intensiveCourses39avif,
        src: intensiveCourses39png,
        webp: intensiveCourses39webp,
    },
    icon02: {
        avif: intensiveCourses40avif,
        src: intensiveCourses40png,
        webp: intensiveCourses40webp,
    },
    icon03: {
        avif: intensiveCourses41avif,
        src: intensiveCourses41png,
        webp: intensiveCourses41webp,
    },
    icon04: {
        avif: intensiveCourses42avif,
        src: intensiveCourses42png,
        webp: intensiveCourses42webp,
    },
    icon05: {
        avif: intensiveCourses43avif,
        src: intensiveCourses43png,
        webp: intensiveCourses43webp,
    },
}
export default imgs
