import * as React from "react"
import { Box, Flex, VStack, Button, Stack, List, ListItem, Text,  } from "@chakra-ui/react"
import { NavLink } from "react-router-dom"
import { usePrefix } from "../../contexts/prefix-provider"

import { ElmarketFooter } from "./elmarket-footer"

const copyright = "COPYRIGHT © 꿈을더하다 ALL RIGHTS RESERVED"

const footer = [
    {
        name:   "COMPANY",
        value:  "꿈을더하다",
        className: "company-name",
    },
    {
        name:   "OWNERS",
        value:  "안예람, 이수연",
        className: "company-owners",
    },
    {
        name:   "ADDRESS",
        // value:  "다산중앙로 123번길 22-26 헤리움1차 807호",
        value: "경기도 남양주시 다산중앙로145번길 15, 8층 802-05",
        className: "company-address",

    },
    {
        name:   "TEL",
        value:  "1533-7410",
        className: "company-tel",
    },

    {
        name:   "BUSINESS NO.",
        value:  "129-87-02182",
        className: "company-business-registration-number",
    },
    {
        name:   "MAIL ORDER NO.",
        value:  "2022-진접오남-0186",
        className: "company-mail-order-number",
    },
    {
        name:   "EMAIL",
        value:  "help@dream-more.kr",
        className: "company-support-email",
    },
    {
        name:   "PRIVACY",
        value:  "support@dream-more.kr",
        className: "company-privacy-email",
    },
    {
        name: "HOURS",
        value: "10:00~17:00(MON TO FRI)",
        className: "company-hours",
    }
]

export function Footer({ routes }) {

    const prefix = usePrefix()
    const { host } = window.location

    if (host.includes("elmarket.kr")) return <ElmarketFooter routes={routes}/>
    // if (host.includes("elmarket.kr") || host.includes("localhost")) return <ElmarketFooter routes={routes}/>

    return <Box
        as={"footer"}
        className={"footer"}
    >
        <VStack className={"footer-container sitemap"} spacing={0}>
            <Flex w={"full"} bg={"brand.400"}>
                <Stack
                    className={"sitemap_list menu_list"}
                    w={"full"}
                    direction={{ base: "column", lg: "row"}}
                    justify={{ base: "flex-start", lg: "center"}}
                    align="flex-start"
                    px={4}
                    py={6}
                    spacing={{base: 4, lg: 6}}>
                    {
                        routes
                            .map(({ path, label, labelStyle = {} }, idx) => <Button
                                className={"menu_item"}
                                key={idx}
                                as={NavLink}
                                to={prefix(path)}
                                fontFamily={"Montserrat"}
                                textTransform={"uppercase"}
                                fontWeight={"bold"}
                                variant={"link"}
                                shadow={"none"}
                                color={"white"}
                                {...labelStyle }>
                                { label }</Button>

                            )
                    }
                </Stack>
            </Flex>

            <VStack w={"full"} bg={"bg2.100"} p={4} pb={20}>
                <List display={"inline"} w={"full"} maxW={"3xl"} mx={"auto"}>
                    {
                        footer.map(({name, value, className}, i) =>
                            <ListItem key={i} display={{ base: "block", lg: "inline"}}>
                                <Text
                                    fontSize={"sm"}
                                    display={"inline"}
                                    px={2}
                                    fontFamily={"Montserrat"}
                                    fontWeight={"extrabold"}
                                    color={"brand.700"}
                                    wordBreak={"keep-all"}
                                >{ name }
                                    <Text
                                    as={"span"}
                                    fontSize={"sm"}
                                    color={"brand.500"}
                                    fontWeight={"normal"}
                                    wordBreak={"keep-all"}
                                    ml={2}
                                    className={className}
                                    >{ value }</Text>
                                </Text>
                            </ListItem>)
                    }
                </List>
                <Flex
                    className={"copyright-wrapper"}
                    w={"full"} justify={"center"} py={2}>
                    <Text
                        className={"copyright"}
                        fontSize={"xs"}
                        color={"brand.400"}
                    >{ copyright }</Text>
                </Flex>
            </VStack>
        </VStack>

    </Box>
}
