import { Center, useStyleConfig } from "@chakra-ui/react"
import { BeatLoader } from "react-spinners"

export function Loading(props) {
    const loaderStyles = useStyleConfig("LoadingStyle")

    return (
        <Center direction={"column"} {...props}>
            <Center className={"rise-loader-center"}>
                <BeatLoader {...loaderStyles} />
            </Center>
        </Center>
    )
}
