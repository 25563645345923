import { Image, Flex, VStack } from "@chakra-ui/react"

import placeholder from "./assets/logo-placeholder.jpg"

export function LogoPlaceholder({ ...rest }) {
    return <Flex
        align={"center"}
        mb={5}
        {...rest}>
        <VStack
            justify={"center"}
            mx={"auto"}
        >
            <Image
                src={placeholder}
                maxH={"60px"}
                alt={"logo-placeholder"} />
        </VStack>
    </Flex>
}