import { useState, useEffect } from "react"
import { Flex } from "@chakra-ui/react"
import { chain } from "lodash"

import { OptimizedReactPhotoCollageContainer } from "../../../components/collage/optimized-react-photo-collage-container"

const collageSettings = (testimonial, width, height) => {
    // console.log("collageSettings", testimonial)
    const photos = chain(testimonial.images)
        .map((t, ix) => ({ ...t, ix }))
        .sort((a) => (a.ix === testimonial.key_img ? -1 : 0))
        .map((t) => ({ img: {...t} }))
        .value()
    return {
        width,
        height,
        layout: [1, 4],
        photos,
        showNumOfRemainingPhotos: true,
    }
}

export function OptimizedCollageView({ testimonial, width, height }) {

    const [settings, setSettings] = useState(collageSettings(testimonial, width, height))

    useEffect(() => {
        setSettings(collageSettings(testimonial, width, height))
    }, [testimonial, width, height])
    //
    //
    // useEffect(() => {
    //     console.log(width, height)
    // }, [width, height])

    // console.log("OptimizedCollageView", testimonial, settings)

    return (
        <Flex>
            <OptimizedReactPhotoCollageContainer {...settings} />
        </Flex>
    )
}
