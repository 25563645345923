import process from "process"
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-567WWVC'
}

if (process?.env?.NODE_ENV !== "production") {
    tagManagerArgs.auth = "-xhjy78NYU0HWBMNpe2L4g"
    tagManagerArgs.preview = "env-20"
} else {
    // production code
    TagManager.initialize(tagManagerArgs)
}


// console.warn("Google TagManager has been disabled")