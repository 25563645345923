import * as React from "react"
import { Box, Heading, Highlight, Text, VStack } from "@chakra-ui/react"
import { Course } from "./course"

import imgs from "../../assets/intensive_courses/min"

const courseSection = {
    title: "다양한 심화과정 단과 클래스",
    description: "파트별로 세분화된 클래스로 교육을 듣고 수강생분들이 독립적으로 업체와 컨택하며 자신있게 공구판매를 할 수 있게끔 하는 심화교육입니다. 꿈을더하다가 원조인 클래스 커리큘럼, 유사강의에 주의하세요.",
}

const courses = [
    {
        no: "01",
        name: "공구 클래스",
        engName: "Sales Class",
        icon: imgs.icon01,
        days: [
            "“잇템”, “핫템” 상품 찾는 법",
            "공구하고 싶은 물건 업체에 컨택하는 법",
            "공구 물건 사진 촬영 및 상세글 작성해보기",
            "직접 진행하며 1:1 피드백 받기",
        ],
        images: imgs.course01
    },
    {
        no: "02",
        name: "도매 클래스",
        engName: "Wholesale Class",
        icon: imgs.icon02,
        days: [
            "도매처 소개 및 주문하는 법",
            "판매가 & 마진 설정하는 법",
            "도매거래처 관련 주의사항 숙지",
        ],
        images: imgs.course02
    },
    {
        no: "03",
        name: "사진 클래스",
        engName: "Photography Class",
        icon: imgs.icon03,
        days: [
            "사진촬영 및 보정 기초 다지기",
            "상품사진 편집",
            "동영상 촬영 및 편집",
            "화면녹화기능 활용",
            "나만의 브랜드 스타일 정하기",
        ],
        images: imgs.course03
    },
    {
        no: "04",
        name: "홍보물 클래스",
        engName: "Publicity Class",
        icon: imgs.icon04,
        days: [
            "반드시 알아야 할 어플 사용법 및 주의사항",
            "어플1을 통한 쉬운 홍보물 제작 실전편",
            "어플2를 통한 홍보물 제작(심화편)",
        ],
        images: imgs.course04
    },
    {
        no: "05",
        name: "쇼핑몰 세팅하기",
        engName: "Shopping Mall Settings",
        icon: imgs.icon05,
        days: [
            "1일차 나만의 쇼핑몰 꾸미기",
            "2일차 인스타에 판매하는 법",
            "3일차 판매꿀팁",
        ],
        footnotes: [
            "* 23.2월 오픈, 커리큘럼은 변동 될 수 있습니다."
        ],
        images: [

        ]
    },
]

export function IntensiveCourses() {
    return <Box
        as="section"
        bg={"bg2.50"}
        textAlign={"center"}
        py={{ base: 8, md: 12 }}
    >
        <VStack
            spacing={0} px={6} pb={6} maxW={"4xl"} mx={"auto"}>
            <Heading
                color={"brand.700"}
                fontSize={{ base: "3xl", md: "4xl"}}
                fontWeight={"bold"}
                letterSpacing={"tight"}
                lineHeight={1.2}
                mb={2}
            >
                <Highlight
                    query={"심화과정"}
                    styles={{ color: "orange.400" }}
                >{ courseSection.title }</Highlight>
            </Heading>
            <Text
                fontSize="md"
                color={"brand.700"}
                fontWeight={500}
                letterSpacing={"tight"}
                wordBreak={"keep-all"}
                px={1}
            >
                <Highlight
                    query={"꿈을더하다가 원조인 클래스 커리큘럼"}
                    styles={{ px: 1, bg: "orange.200", fontWeight: 500}}
                >
                    { courseSection.description }
                </Highlight>
            </Text>

        </VStack>
        <VStack
            w={"full"}
            maxW={"4xl"}
            mx={"auto"}>
            {
                courses.map((c, i) => <Course key={i} {...c} />)
            }
        </VStack>
    </Box>
}