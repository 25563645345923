import LocalizedStrings from "react-localization"

export function useMarketingStrings() {
    const strings = {
        ko: {
            auth: {
                loginPage: {
                    // headline: "Welcome back Dream Family!",
                    // title: "Dream More+",
                    headline: "",
                    subheadline: "",
                    description: "",

                },
                signupPage: {
                    title: "Join the Dream family!",
                    isMember: "이미 회원가입이 되어 있으세요?",
                    loginLink: "로그인 하기"
                },
            }
        },
        en: {
            auth: {
                loginPage: {
                    // headline: "Welcome back Dream Family!",
                    // title: "Dream More+",
                    headline: "",
                    subheadline: "",
                    description: ""
                },
                signupPage: {
                    title: "Join the Dream family!",
                    isMember: "Already have an account?",
                    loginLink: "Login here"
                },
            }
        }
    }

    const localizedStrings = new LocalizedStrings(strings)

    return { strings, localizedStrings }
}