import * as React from "react"
import * as yup from "yup"
import { useValidation } from "../validation/use-validation"
import { useAuth } from "../authentication/contexts/auth-provider"

export const MetadataContext = React.createContext(null)

export function MetadataProvider({ children }) {

    const { currentUser } = useAuth()
    const validation = useValidation()

    const addMetadata = React.useCallback((schema) => {
        const metadata = {
            createdAt: validation.defaultDate(),
            createdBy: validation.defaultString(currentUser.uid),
            updatedAt: validation.defaultDate(),
            updatedBy: validation.defaultString(currentUser.uid),
        }

        return yup.object().shape(metadata).concat(schema)
    }, [currentUser.uid, validation])

    return <MetadataContext.Provider value={addMetadata}>{ children }</MetadataContext.Provider>
}

export const useMetadata = () => React.useContext(MetadataContext)