import * as React from "react"
import {
    Box,
    Center,
    Flex,
    HStack,
    Link,
    Button,
    useStyleConfig,
    VisuallyHidden,
    useColorModeValue as mode
} from "@chakra-ui/react"
import { NavLink } from "react-router-dom"
import { MdMenu } from "react-icons/md"
import { IoChatbubbleEllipsesOutline, IoCloseSharp } from "react-icons/io5"


import { TextWithTinyLogoDark, } from "../../../dreammore/components/logo"
import { NavAction } from "../../../components/nav/nav-action"
import { kakao } from "../kakao/config"
import { usePrefix } from "../../contexts/prefix-provider"
import { useSite } from "../../../contexts/site-provider"


function DesktopNavHeader(props) {

    const { routes } = props

    const href = window.location.href
    const [chatUrl, setChatUrl] = React.useState(kakao.getChatUrl())

    const prefix = usePrefix()

    React.useEffect(() => {
        setChatUrl(kakao.getChatUrl())
    }, [href])

    const { variant } = useSite()
    const style = useStyleConfig("NavHeader", { variant })
    const buttonStyle = useStyleConfig("NavButtons", { variant })

    return (
        <Box
            className={"desktop-nav-header-container"}
            py="2"
            bg={mode('white', 'gray.800')}
            px="10"
            display={{
                base: 'none',
                xl: 'block',
            }}
            {...style.header}
        >
            <Flex
                className={"desktop-nav-header-flex"}
                justify="space-between"
                align="center"
                maxWidth="8xl"
                mx="auto">
                <HStack
                    className={"desktop-nav-header-hstack sitemap_list menu_list"}
                    w={"33%"}
                    role="navigation"
                    aria-label="Main navigation"
                    spacing="10"
                    fontWeight="semibold"
                    fontSize="sm"
                >
                    {
                        routes
                            .filter(({ footerOnly }) => !footerOnly)
                            .map(({ path, label, labelStyle = {} }, idx) => <Link
                                className={"menu_item"}
                                key={idx}
                                as={NavLink}
                                to={prefix(path)}
                                {...labelStyle }
                                {...buttonStyle}>
                                { label }</Link>

                            )
                    }
                </HStack>
                <NavLink
                    className={"nav-header-logo"}
                    to={prefix("/")}
                    rel="home"
                    w={"33%"}>
                    <TextWithTinyLogoDark imgProps={{ maxH: "35px" }}/>
                </NavLink>
                <HStack
                    className={"nav-header-actions"}
                    spacing="4"
                    w={"33%"}
                    justify={"flex-end"}>
                    <HStack
                        className={"nav-header-action-menu"}
                        spacing="4">
                        {/*<NavAction.Desktop {...items.user} as={NavLink} to={"/auth/signup"}/>*/}
                        <NavAction.Desktop
                            icon={IoChatbubbleEllipsesOutline}
                            href={chatUrl}/>
                    </HStack>
                </HStack>
            </Flex>
        </Box>
    )
}

function MobileNavHeader(props) {

    const { menu } = props
    const prefix = usePrefix()

    const href = window.location.href
    const [chatUrl, setChatUrl] = React.useState(kakao.getChatUrl())

    React.useEffect(() => {
        setChatUrl(kakao.getChatUrl())
    }, [href])

    const { variant } = useSite()
    const style = useStyleConfig("NavHeader", { variant })
    const buttonStyle = useStyleConfig("NavButtons", { variant })
    // console.log("style", style)

    // const [scrolling, setScrolling] = React.useState(false);
    // const [scrollTop, setScrollTop] = React.useState(65);
    //
    // React.useEffect(() => {
    //     function onScroll() {
    //         let currentPosition = window.pageYOffset;
    //         setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
    //     }
    //
    //     window.addEventListener("scroll", onScroll);
    //     return () => window.removeEventListener("scroll", onScroll);
    // }, [scrollTop]);

    return (<Flex
                className={"nav-header-container"}
                px="4"
                py="4"
                align="center"
                justify="space-between"
                display={{
                    base: 'flex',
                    xl: 'none',
                }}
                {...style.header}
            >
                <HStack
                    className={"nav-header-menu-button"}
                    spacing="3">
                    {!menu.isOpen && <Center w="8" h="8" as="button" type="button" onClick={menu.onOpen}>
                        <VisuallyHidden>Open Menu</VisuallyHidden>
                        <Box
                            className={"nav-header-menu-button-open"}
                            as={MdMenu} fontSize="3xl" color={"secondary.700"} {...buttonStyle} />
                    </Center>}
                    {menu.isOpen && <Center w="8" h="8" as="button" type="button" onClick={menu.onClose}>
                        <VisuallyHidden>Close Menu</VisuallyHidden>
                        <Box
                            className={"nav-header-menu-button-closed"}
                            as={IoCloseSharp} fontSize="3xl" color={"secondary.700"}  {...buttonStyle}/>
                    </Center>}

                </HStack>
                <Center
                    className={"nav-header-logo"}
                    as={NavLink}
                    type="button"
                    to={prefix("/")}>
                    <TextWithTinyLogoDark imgProps={{ maxH: "35px" }}/>
                </Center>
                <HStack className={"nav-header-actions"}>
                    {/*<Center w="8" h="8" flexShrink={0} as="button" type="button" onClick={() => {}}>*/}
                    {/*    <VisuallyHidden>Login</VisuallyHidden>*/}
                    {/*    <Box as={items.user.icon} fontSize="2xl" color={"secondary.700"}  {...buttonStyle }/>*/}
                    {/*</Center>*/}
                    <Center
                        className={"nav-header-actions-center"}
                        w="8" h="8" flexShrink={0}>
                        <VisuallyHidden>Contact Us</VisuallyHidden>
                        <Button
                            className={"nav-header-actions-contact-us"}
                            as={"a"}
                            variant={"link"}
                            shadow={"none"}
                            href={chatUrl}
                        >
                            <Box
                                className={"nav-header-actions-contact-us-icon"}
                                as={IoChatbubbleEllipsesOutline}
                                fontSize="3xl"
                                color={"secondary.700"}
                                {...buttonStyle}/>
                        </Button>
                    </Center>
                </HStack>
            </Flex>
    )
}

export const NavHeader = {
    Desktop: DesktopNavHeader,
    Mobile: MobileNavHeader,
}