import * as React from "react"
import { Box } from "@chakra-ui/react"
import { Helmet } from "react-helmet-async"

import { Hero } from "../components/hero/hero"
import { Award } from "../components/award/award"
import { Reviews } from "../components/reviews/reviews"
import { GrandOpening } from "../components/grand-opening/grand-opening"
import { Products } from "../components/products/products"
import { Recommendations } from "../components/recommendations/recommendations"
import { Features } from "../components/features/features"
import { Team } from "../components/team/team"
import { InterviewCorner } from "../components/interview-corner/interview-corner"
import { Instructors } from "../components/instructors/instructors"
import { SupportTeam } from "../components/support-team/support-team"
import { Classes } from "../components/classes/classes"
import { BlogList } from "../components/blogs/blog-list"

import { KakaoChannelButton } from "../components/kakao/kakao-channel-button"
import { ElmarketMovedPage } from "./elmarket-moved-page"


export function HomePage() {

    const { href, host } = window.location

    if (host.includes("elmarket.kr")
        // || host.includes("localhost")
    ) return <ElmarketMovedPage />

    return <>
        <Helmet prioritizeSeoTags>
            <title>꿈을더하다 - 공구교육끝판왕</title>
            <meta property={"og:url"} content={href} />
            <meta property="og:description"
                  content={"꿈을더하다 - 연필 한자루 팔아본적 없어도 가능한 공구마켓 육아맘 재택알바 도전"}/>
            <link rel="canonical" href="https://dream-more.kr" />
        </Helmet>
        <Box
        className={"home-page"}>
        <Hero/>
        <Box
            className={"home-page-container"}
            mx={"auto"}>
            <Award/>
            <Reviews/>
            <GrandOpening/>
            <Products/>
            <Recommendations/>
            <Features/>
            <Team/>
            <InterviewCorner/>
            <Instructors/>
            <SupportTeam/>
            <Classes/>
            <BlogList infiniteScroll={false}/>
        </Box>
        <KakaoChannelButton />
    </Box></>
}