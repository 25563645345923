import * as React from "react"
import { NavLink } from "react-router-dom"
import {
    Box,
    Flex,
    Text,
    Heading,
    LinkOverlay,
    Icon,
    Stack,
    StackDivider,
    AspectRatio,
    VStack,
} from "@chakra-ui/react"
import { format } from "date-fns"
import { ImHeart } from "react-icons/im"
import { isEmpty } from "lodash"

import { Author } from "./author"
import { SkeletonOptimizedImage } from "../../../components/optimized-image/skeleton-optimized-image"
import { usePrefix } from "../../contexts/prefix-provider"

const authors = {
    ElMarket: "엘마켓",
    Mozzimong: "모찌몽베베",
    Dream: "꿈을더하다",
}

export function BlogCard({ blog }) {
    const prefix = usePrefix()

    return (
        <Box
            as={"article"}
            className={"blog-container"}
            position={"relative"}
            overflow={"hidden"}
            borderColor={"gray.200"}
            transition={"all 0.2s ease 0s"}
            bgColor={"gray.50"}
            rounded={"md"}
            shadow={"md"}
            textAlign={"left"}
            wordBreak={"keep-all"}
            minH={"525px"}
        >
            <LinkOverlay
                className={"blog-link-overlay"}
                as={NavLink}
                to={prefix(`/blog/${blog.logNo}`)}
            >
                <Flex className={"blog-card"} direction={"column"} h={"full"}>
                    <AspectRatio ratio={1}>
                        <SkeletonOptimizedImage
                            isLazy
                            img={blog.image}
                            imgProps={{
                                alt: "blog_cover_image",
                                minW: "320px",
                                h: "auto",
                            }}
                        />
                    </AspectRatio>

                    <Box
                        className={"blog-details"}
                        direction={"column"}
                        py={[5, 6]}
                        px={3}
                        h={"full"}
                        flex={1}
                    >
                        <Flex
                            className={"blog-details-header"}
                            justify={"space-between"}
                            mb={2}
                        >
                            <Stack
                                className={"blog-tags"}
                                direction={["row", "column", "row"]}
                                pl={1}
                                spacing={[2, 1, 3]}
                                textTransform={"uppercase"}
                                letterSpacing={"tight"}
                                fontSize={"sm"}
                                fontWeight={"bold"}
                                mb={2}
                                color={"penny.400"}
                                divider={
                                    <StackDivider borderColor="gray.200" />
                                }
                            >
                                {!isEmpty(blog.tags) &&
                                    blog.tags.map((tag) => (
                                        <Text className={"blog-tag"} key={tag}>
                                            {tag}
                                        </Text>
                                    ))}
                            </Stack>
                            <Text
                                className={"blog-date"}
                                fontSize={"xs"}
                                letterSpacing={"tighter"}
                                color={"gray.600"}
                            >
                                {format(blog.date, "M월 d일 yyyy")}
                            </Text>
                        </Flex>
                        <Flex
                            justify={"space-between"}
                            direction={"column"}
                            h={"full"}
                        >
                            <VStack spacing={4} mb={8} align={"flex-start"}>
                                <Heading
                                    className={"blog-headline"}
                                    as={"h3"}
                                    fontWeight={"bold"}
                                    fontSize={["lg", null, null, "sm"]}
                                    lineHeight={"base"}
                                    color={"charcoal.700"}
                                >
                                    {blog.title}
                                </Heading>
                                <Text
                                    className={"blog-snippet"}
                                    fontSize={["base", null, null, "sm"]}
                                    overflow={"hidden"}
                                    textOverflow={"ellipsis"}
                                    wordBreak={"break-all"}
                                    sx={{
                                        display: "-webkit-box",
                                        WebkitBoxOrient: "vertical",
                                        WebkitLineClamp: "2",
                                    }}
                                >
                                    {blog.description}
                                </Text>
                            </VStack>
                            <Flex
                                className={"blog-footer"}
                                align={"baseline"}
                                justify={"space-between"}
                                fontSize={"sm"}
                                color={"gray.600"}
                                pb={4}
                            >
                                <Author
                                    className={"blog-author"}
                                    author={blog.author || authors[blog.market]}
                                />
                                <Text className={"blog-no-of-likes"}>
                                    {blog.views}{" "}
                                    <Icon as={ImHeart} color={"red.400"} />
                                </Text>
                            </Flex>
                        </Flex>
                    </Box>
                </Flex>
            </LinkOverlay>
        </Box>
    )
}
