import * as React from "react"
import { NavLink } from "react-router-dom"
import { Box, Button, Flex, SimpleGrid, VStack } from "@chakra-ui/react"
import Observer from "@researchgate/react-intersection-observer"
import {
    collection,
    limit,
    orderBy,
    query,
    startAfter,
} from "firebase/firestore"
import { useFirestoreInfiniteQuery } from "@react-query-firebase/firestore"
import { useFirestore } from "reactfire"
import { chain, isEmpty } from "lodash"

import { usePrefix } from "../../contexts/prefix-provider"

import { SectionHeader } from "../section/section-header"

import { TestimonialListItem } from "./testimonial-list-item"
import { TestimonialListItemSkeleton } from "./testimonial-list-item-skeleton"
import { TestimonialCard } from "./testimonial-card"
import { TestimonialCardSkeleton } from "./testimonial-card-skeleton"

import { Loading } from "../../../components/loading/loading"
import { ContactUsButton } from "../contact-us-button/contact-us-button"

import { data } from "./data/new-data"
import { ButtonContainer } from "../../../components/common/button-container"


export function TestimonialList({ infiniteScroll = true }) {
    const db = useFirestore()
    const prefix = usePrefix()

    const pageLimit = 18
    const [isVisible, setIsVisible] = React.useState(false)
    const [testimonials, setTestimonials] = React.useState(data)

    const testimonialsCollection = collection(db, "testimonials")
    const testimonialsQuery = query(
        testimonialsCollection,
        orderBy("date", "desc"),
        limit(pageLimit),
    )

    const items = useFirestoreInfiniteQuery(
        "testimonials",
        testimonialsQuery,
        (snapshot) => {
            const lastDocument = snapshot.docs[snapshot.docs.length - 1]
            if (!lastDocument) return
            return query(testimonialsQuery, startAfter(lastDocument))
        },
        {},
        {
            // enabled: false
        },
    )

    React.useEffect(() => {
        if (isEmpty(items.data)) return
        setTestimonials(
            chain(items.data.pages)
                .map((snapshot) => snapshot.docs)
                .flatten()
                .map((post) => ({ ...post.data() }))
                .value(),
        )
    }, [items.data])

    React.useEffect(() => {
        if (!infiniteScroll) return
        if (!isVisible) return
        if (items.isLoading) return
        if (items.isFetchingNextPage) return
        if (!items.hasNextPage) return
        items.fetchNextPage()
    }, [isVisible, items, infiniteScroll])

    // const show = () => true
    return (
        <Box
            className={"testimonials-section"}
            as="section"
            textAlign={"center"}
        >
            <Box
                className={"testimonials-container"}
                pt={{ base: 8, md: 12 }}
                pb={{ base: 0, md: 12 }}
                px={{ base: 0 }}
                maxW={"5xl"}
                mx={"auto"}
            >
                <SectionHeader
                    title={"꿈을더하다 후기"}
                    description={
                        "왕초보부터 공구판매왕까지 수강생들의 100% 리얼후기 확인해보세요."
                    }
                />

                <VStack
                    className={"testimonial-list-stack"}
                    spacing={1}
                    display={{ base: "flex", md: "none" }}
                >
                    {items.isLoading
                        ? Array.from(Array(20)).map((blog, idx) => (
                              <TestimonialListItemSkeleton key={idx} />
                          ))
                        : testimonials.map((testimonial, idx) => (
                              <TestimonialListItem
                                  key={idx}
                                  testimonial={testimonial}
                              />
                          ))}
                </VStack>
                <SimpleGrid
                    className={"blogs-list blog-list-grid"}
                    columns={[1, 2, 2, 3]}
                    spacing={[4]}
                    display={{ base: "none", md: "grid" }}
                    px={[6, 4, 4]}
                    // mt={["-40px", null, "-30px"]}
                >
                    {items.isLoading
                        ? Array.from(Array(20)).map((blog, idx) => (
                              <TestimonialCardSkeleton key={idx} />
                          ))
                        : testimonials.map((testimonial, idx) => (
                              <TestimonialCard
                                  key={idx}
                                  testimonial={testimonial}
                              />
                          ))}
                </SimpleGrid>
                {infiniteScroll ? (
                    <Observer onChange={(e) => setIsVisible(e.isIntersecting)}>
                        <Box w={"full"} minH={"1px"} my={3}>
                            <Loading
                                display={
                                    isVisible &&
                                    !items.isLoading &&
                                    items.hasNextPage
                                        ? "block"
                                        : "none"
                                }
                            />
                        </Box>
                    </Observer>
                ) : (
                    <Flex
                        maxW={"2xl"}
                        mx={"auto"}
                        pt={3}
                        px={{ base: 4, md: 0 }}
                        justify={"space-between"}
                    >
                        <Button
                            as={NavLink}
                            to={prefix("/tesimonials")}
                            minW={"48%"}
                            mb={2}
                        >
                            Read More
                        </Button>
                        <ContactUsButton
                            justify={"center"}
                            w={"auto"}
                            ml={0}
                            minW={"48%"}
                            btnProps={{ w: "full", size: "md" }}
                        >
                            성공후기 주인공이 되어보세요! 💬
                        </ContactUsButton>
                    </Flex>
                )}
            </Box>
            <ButtonContainer columns={1} px={[2,6,8]} pt={[0,4,6]} pb={{base: 4, md: 8}} maxW={"xl"}>
                <Button
                    className={"classes-cta-go-to-curriculum"}
                    as={NavLink}
                    to={prefix("/curriculum")}
                    fontSize={"1.2rem"}
                    fontWeight={"bold"}
                    colorScheme={"brand"}
                    variant={"ghost"}
                    bg={"white"}
                    rounded={"md"}
                    shadow={"md"}
                    w={"full"}
                    maxW={"xl"}
                >
                    커리큘럼 보러가기
                </Button>
                <ContactUsButton
                    w={"full"}
                    btnProps={{
                        w: "full",
                        rounded: "md",
                        shadow: "md",
                    }}
                >
                    성공후기 주인공이 되어보세요! 💬
                </ContactUsButton>
            </ButtonContainer>
        </Box>
    )
}
