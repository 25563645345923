import React from "react"
import {
    Outlet,
    // NavLink,
    Navigate,
} from "react-router-dom"

import { AuthLoading } from "./components/auth-loading"
import { RequireAuth } from "./components/require-auth"
import { AuthLayout } from "./auth-layout"

import { LoginPage } from "./login/login-page"
import { SignupForm } from "./signup/signup-form"
// import { ForgotPasswordPage } from "./forgotPassword/forgot-password-page"
// import { ResetPasswordPage } from "./resetPassword/reset-password-page"
// import { AuthActionPage } from "./authAction/auth-action-page"

import { SignupPage } from "./signup/signup-page"

export function ProtectedRoutes({ site, children }) {
    return (
        <AuthLoading site={site}>
            <RequireAuth site={site}>{children || <Outlet />}</RequireAuth>
        </AuthLoading>
    )
}

export const authRoutes = (defaultAuthRoute, ...children) => {
    return {
        path: "/auth",
        element: <AuthLayout />,
        children: [
            ...children,
            {
                index: true,
                element: <Navigate to={`/auth/${defaultAuthRoute}`} />,
            },
        ],
    }
}

export const loginRoutes = {
    path: "login",
    element: <Outlet />,
    children: [
        { index: true, element: <LoginPage /> },
        // { path: "action", element: <AuthActionPage/> },
        // { path: "reset", element: <ResetPasswordPage/> },
        // { path: "forgot", element: <ForgotPasswordPage/> }
    ],
}

export function signupRoutes(site) {
    const signupForm = site ? site.signupForm : <SignupForm />

    return {
        path: "signup",
        element: <SignupPage />,
        children: [
            {
                index: true,
                element: signupForm,
            },
        ],
    }
}
