import { parse, isDate } from "date-fns"
import { isNull } from "lodash"

const dateInputFormat = "yyyy-MM-dd"
const datetimeInputFormat = "yyyy-MM-dd'T'HH:mm"

export function parseDateString(value, originalValue, context) {
    if (context.spec.nullable && isNull(originalValue))
        return originalValue
    if (isDate(originalValue))
        return originalValue
    if (originalValue.toDate)
        return originalValue.toDate()
    if (parse(originalValue, dateInputFormat, new Date()).isValid())
        return parse(originalValue, dateInputFormat).toDate()
    return new Date()
}

export function parseDatetimeString(value, originalValue, context) {
    if (context.spec.nullable && isNull(originalValue))
        return originalValue
    if (isDate(originalValue))
        return originalValue
    if (originalValue.toDate)
        return originalValue.toDate()
    if (parse(originalValue, datetimeInputFormat, new Date()).isValid())
        return parse(originalValue, datetimeInputFormat).toDate()
    return new Date()
}

