import * as React from "react"
import { set, get } from "lodash"

export const AppContext = React.createContext(null)

export function AppProvider({ children }) {
    const state = React.useRef({})

    const setState = (key, value) => {
        set(state.current, key, value)
    }

    const getState = (key) => get(state.current, key)

    const context = React.useMemo(() => ({ state: state.current, getState, setState }), [])

    return (
        <AppContext.Provider
            value={context}
        >
            {children}
        </AppContext.Provider>
    )
}

export const useApp = () => React.useContext(AppContext)
