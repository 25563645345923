import * as React from "react"
import { Outlet } from "react-router-dom"
import { Box, useStyleConfig } from "@chakra-ui/react"

export function AuthLayout({ props }) {
    const styles = useStyleConfig("AuthLayoutStyle")
    return (
        <Box
            className={"auth-layout"}
            minH="100vh"
            py="12"
            px={{
                base: "4",
                lg: "8",
            }}
            __css={styles}
            {...props}
        >
            <Box maxW="xl" mx="auto" className={"auth-container"}>
                <Outlet />
            </Box>
        </Box>
    )
}
