import * as React from "react"
import { NavLink } from "react-router-dom"

import { Box, Button, Flex, SimpleGrid } from "@chakra-ui/react"
import { SectionHeader } from "../section/section-header"
import { ClassCard } from "./class-card"

import { usePrefix } from "../../contexts/prefix-provider"

import imgs from "../../assets/classes/min"

const classes = [
    {
        badge: "first",
        title: "공구 클래스",
        subtitle:
            "인기있는 공동 구매 상품은 어떻게 진행되는걸까? 상품 소싱부터 업체 컨택 방법까지 모두 알려드립니다.",
        image: imgs.sales,
        imgProps: {
            alt: "공구클래스",
            objectFit: "cover",
        },
    },
    {
        badge: "second",
        title: "도매 클래스",
        subtitle:
            "도매처 소개 및 주문하는 법, 판매가와 마진 설정하는 법에 대해 강사님의 명쾌한 강의로 쉽게 접근하실 수 있습니다.",
        image: imgs.wholesale,
        imgProps: {
            alt: "도매 클래스",
        },
    },
    {
        badge: "third",
        title: "사진 클래스",
        subtitle:
            "판매가 잘 될 수 있도록 제품 촬영 잘하는 방법 및 꿈을더하다에서 촬영대행까지 진행합니다.",
        image: imgs.photography,
        imgProps: {
            alt: "사진 클래스",
        },
    },
    {
        badge: "fourth",
        title: "홍보물 클래스",
        subtitle:
            "반드시 알아야 할 어플 사용법 및 누구나 쉽게 홍보물을 제작할 수 있도록 도와드립니다.",
        image: imgs.publicity,
        imgProps: {
            alt: "홍보물 클래스",
            objectPosition: "right 85%",
            objectFit: "contain",
        },
    },
]

export function Classes() {
    const prefix = usePrefix()

    return (
        <Box
            className={"classes-section"}
            as={"section"}
            bg={"bg2.400"}
            textAlign={"center"}
        >
            <Box
                className={"classes-container"}
                py={{ base: 8, md: 12 }}
                px={{ base: 4 }}
                mx={"auto"}
                maxW={"5xl"}
            >
                <SectionHeader
                    title={"다양한 클래스 운영"}
                    description={
                        "보다 편하고 쉽게 판매하실 수 있도록 꿈을더하다에서는 다양한 클래스를 운영하고 있습니다."
                    }
                    textProps={{ color: "white" }}
                />
                <SimpleGrid
                    className={"classes-list"}
                    columns={{ base: 1, md: 2, lg: 4 }}
                    spacing={{ base: 4, md: 2 }}
                >
                    {classes.map((c, i) => (
                        <ClassCard {...c} key={i} />
                    ))}
                </SimpleGrid>
                <Flex
                    className={"classes-cta-button-container"}
                    justify={"center"}
                    pb={{ base: 2, md: 4 }}
                    pt={{ base: 6, md: 12 }}
                    w={"full"}
                >
                    <Button
                        className={"classes-cta-go-to-curriculum"}
                        as={NavLink}
                        to={prefix("/curriculum")}
                        fontSize={"1.2rem"}
                        fontWeight={"bold"}
                        colorScheme={"brand"}
                        variant={"ghost"}
                        bg={"bg2.50"}
                        rounded={"md"}
                        w={"full"}
                        maxW={"xl"}
                        size={"lg"}
                    >
                        커리큘럼 보러가기
                    </Button>
                </Flex>
            </Box>
        </Box>
    )
}
