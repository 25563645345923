import { Flex, HStack, Text, Button } from "@chakra-ui/react"
import { Link as NavLink } from "react-router-dom"
import * as React from "react"

export function SignupAlreadyMember({ strings, loginRoute, from }) {
    return (
        <Flex justify={"space-around"}>
            <HStack spacing={2} align={"baseline"}>
                <Text
                    as={"span"}
                    mb="8"
                    align="center"
                    maxW="md"
                    fontWeight="medium"
                >
                    {strings.isMember}
                </Text>
                <Button
                    as={NavLink}
                    to={loginRoute}
                    size={"sm"}
                    state={{ from: { pathname: from } }}
                    // fontSize={"md"}
                    fontWeight={"medium"}
                    // variant={"ghost"}
                >
                    {strings.loginLink}
                </Button>
            </HStack>
        </Flex>
    )
}