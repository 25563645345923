import * as React from "react"
import { Box } from "@chakra-ui/react"
import { Helmet } from "react-helmet-async"

import { Hero } from "../components/hero/hero"
import { Curriculum } from "../components/curriculum/curriculum"
import { IntensiveCourses } from "../components/intensive-courses/intensive-courses"

import { KakaoChannelButton } from "../components/kakao/kakao-channel-button"


export function CurriculumPage() {

    const { href } = window.location

    return <>
        <Helmet prioritizeSeoTags>
            <title>꿈을더하다 - 공구교육 커리큘럼</title>
            <meta property={"og:url"} content={href} />
            <meta property="og:description" content={"왕초보도 공구 마스터가 되는 교육부터 케어플랜까지"}/>
            <link rel="canonical" href="https://dream-more.kr/curriculum" />
        </Helmet>
        <Box className={"curriculum-page"}>
        <Hero/>
        <Box mx={"auto"}>
            <Curriculum/>
            <IntensiveCourses />
        </Box>
        <KakaoChannelButton />
    </Box></>
}

export default CurriculumPage
