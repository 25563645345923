export const globalStyles = {
    styles: {
        global: {
            "s, strike": {
                textDecoration: "none",
                position: "relative"
            },

            "s::before, strike::before": {
                top: "50%", /*tweak this to adjust the vertical position if it's off a bit due to your font family */
                background: "var(--chakra-colors-brand-400)", /*this is the color of the line*/
                opacity: .7,
                content: "''",
                width: "110%",
                position: "absolute",
                height: ".1em",
                borderRadius: ".1em",
                left: "-5%",
                whiteSpace: "nowrap",
                display: "block",
                transform: "rotate(-15deg)"
            },

            "s.straight::before, strike.straight::before": {
                transform: "rotate(0deg)", left: "-1%", width: "102%",
            }

        }
    }
}