import React from "react"
import * as yup from "yup"

import { parseDateString } from "./validation-parse-date"
import { validationStrings } from "./validation-strings"

export function useValidation() {
    const name = "validation"

    const strings = React.useRef({})
    const setStrings = (localized) => {
        strings.current = localized
        // console.log("Validation strings set", strings.current)
    }

    const constants = { strings: validationStrings }

    const defaultString = (defaultValue = "") =>
        yup.string().trim().default(defaultValue)

    const defaultSearchableString = (defaultValue = "") =>
        defaultString(defaultValue).meta({
            disableGlobalFilter: false,
        })

    const defaultRequiredString = (defaultValue = "") =>
        defaultString(defaultValue).required(strings.current.required)

    const defaultRequiredSearchableString = (defaultValue = "") =>
        defaultString(defaultValue).required(strings.current.required).meta({
            disableGlobalFilter: false,
        })

    const defaultImg = () =>
        yup
            .object()
            .shape({
                bucket: defaultString(),
                src: defaultString(),
            })
            .meta({ image: true })

    const defaultDate = (now) =>
        yup
            .date()
            .transform(parseDateString)
            .default(now || new Date())

    const defaultHiddenDate = (now) =>
        defaultDate(now).meta({
            fieldHidden: true,
        })

    const defaultRequiredDate = (now) =>
        defaultDate(now).required(strings.required)

    const defaultNullableDate = (now) =>
        yup
            .date()
            .nullable()
            .transform(parseDateString)
            .default(now || new Date())

    const minPasswordLength = 6

    const password = () =>
        yup
            .string()
            .trim()
            .required(strings.current.required)
            .min(
                minPasswordLength,
                strings.current.formatString(
                    strings.current.password.tooShort,
                    { min: minPasswordLength },
                ),
            )
            .default("")

    const confirmPassword = () =>
        yup
            .string()
            .oneOf(
                [yup.ref("password"), null],
                strings.current.passwordsDontMatch,
            )
            .required(strings.current.required)
            .default("")

    const mobilePrefixes = ["010"]

    const koreanMobile = () =>
        yup
            .string()
            .default("")
            .required("")
            .test(
                "is-right-length",
                strings.current.mobile.isLength,
                (value) => value.replace(/[- ]/g, "").length === 11,
            )
            .test(
                "starts-with-prefix",
                strings.current.formatString(
                    strings.current.mobile.startsWith,
                    mobilePrefixes.join(" or "),
                ),
                (value) =>
                    mobilePrefixes.some((prefix) => value.startsWith(prefix)),
            )

    return {
        name,
        constants,
        setStrings,
        defaultString,
        defaultSearchableString,
        defaultRequiredString,
        defaultRequiredSearchableString,
        defaultImg,
        defaultDate,
        defaultHiddenDate,
        defaultRequiredDate,
        defaultNullableDate,
        defaults: {
            string: defaultString,
            searchableString: defaultSearchableString,
            requiredString: defaultRequiredString,
            requiredSearchableString: defaultRequiredSearchableString,
            date: defaultDate,
            hiddenDate: defaultHiddenDate,
            requiredDate: defaultRequiredDate,
            nullableDate: defaultNullableDate,
            img: defaultImg,
            mobile: koreanMobile,
            password,
            confirmPassword,
        },
        password,
        confirmPassword,
        koreanMobile,
    }
}
