import awards00avif from "./joongang_masthead.avif"
import awards00jpg from "./joongang_masthead.jpg"
import awards00webp from "./joongang_masthead.webp"
import awards01avif from "./중앙일보_꿈을더하다_2022_올해의_우수브랜드_대상.avif"
import awards01jpg from "./중앙일보_꿈을더하다_2022_올해의_우수브랜드_대상.jpg"
import awards01webp from "./중앙일보_꿈을더하다_2022_올해의_우수브랜드_대상.webp"
import awards02avif from "./중앙일보_우수브랜드_대상_1위_PC.avif"
import awards02png from "./중앙일보_우수브랜드_대상_1위_PC.png"
import awards02webp from "./중앙일보_우수브랜드_대상_1위_PC.webp"
import awards03avif from "./중앙일보 우수브랜드 대상 1위 - PC2.avif"
import awards03jpg from "./중앙일보 우수브랜드 대상 1위 - PC2.jpg"
import awards03webp from "./중앙일보 우수브랜드 대상 1위 - PC2.webp"
import awards04avif from "./중앙일보_우수브랜드_대상_1위_모바일.avif"
import awards04jpg from "./중앙일보_우수브랜드_대상_1위_모바일.jpg"
import awards04webp from "./중앙일보_우수브랜드_대상_1위_모바일.webp"

export const imgs = {
    masthead: {
        avif: awards00avif,
        src: awards00jpg,
        webp: awards00webp,
    },
    article: {
        avif: awards01avif,
        src: awards01jpg,
        webp: awards01webp,
    },
    pc: {
        avif: awards02avif,
        src: awards02png,
        webp: awards02webp,
    },
    pc2: {
        avif: awards03avif,
        src: awards03jpg,
        webp: awards03webp,
    },
    mobile: {
        avif: awards04avif,
        src: awards04jpg,
        webp: awards04webp,
    },
}
export default imgs
