import { Box, Heading, Flex, Text, useStyleConfig } from "@chakra-ui/react"

export function AuthPageHeader({ title, description, ...rest }) {
    const styles = useStyleConfig("AuthPageHeaderStyle")

    return title || description ? (
        <Box {...rest} __css={styles}>
            {title && (
                <Heading
                    textAlign="center"
                    size="sm"
                    fontWeight="extrabold"
                    mb={2}
                >
                    {title}
                </Heading>
            )}
            {description && (
                <Flex justify={"space-around"} mb={6} w={"full"}>
                    <Text
                        as={"span"}
                        align="center"
                        maxW="md"
                        fontWeight="medium"
                    >
                        {description}
                    </Text>
                </Flex>
            )}
        </Box>
    ) : null
}
