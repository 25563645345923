export const validationStrings = {
    ko: {
        validation: {
            required: "필수",
            mustAgree: "동의 버튼을 눌러주세요.",
            passwordsDontMatch: "위 비밀번호와 일치하지 않습니다.",
            password: {
                tooShort: "비밀번호는 영문,숫자포함 {min}글자 이상입니다."
            },
            mobile: {
                startsWith:  "{0}으로 시작하는 번호를 입력해주세요.",
                isLength: "휴대폰 번호를 확인해 주세요."
            }
        }

    },
    en: {
        validation : {
            required: "Required",
            mustAgree: "Must agree to the terms",
            passwordsDontMatch: "Passwords do not match",
            password: {
                tooShort: "Password must be at least {min} characters"
            },
            mobile: {
                startsWith: "Must start with {0}",
                isLength: "Too long or short"
            }
        }

    },

}