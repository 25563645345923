const baseStyle = {
  _focus: {
    boxShadow: 'none',
  },
  _focusVisible: {
    boxShadow: 'outline',
  },
}

export const closeButton = {
  baseStyle,
}

export default closeButton
