import * as React from "react"
import { Box,
    // Button, Flex
} from "@chakra-ui/react"
// import { NavLink } from "react-router-dom"
// import { usePrefix } from "../../contexts/prefix-provider"

import { SectionHeader } from "../section/section-header"
import { HorizontalScroll } from "../../../components/scroll/horizontal-scroll"

import { reviewAssets as imgs } from "../../assets/reviews/min"

// import r01 from "../../assets/reviews/main01.jpg"
// import r02 from "../../assets/reviews/main02.jpg"
// import r03 from "../../assets/reviews/main03.jpg"
// import r04 from "../../assets/reviews/main04.jpg"
// import r05 from "../../assets/reviews/main05.jpg"
// import r06 from "../../assets/reviews/main06.jpg"
//
// import r07 from "../../assets/reviews/main07.jpg"
// import r08 from "../../assets/reviews/main08.jpg"
// import r09 from "../../assets/reviews/main09.jpg"
// import r10 from "../../assets/reviews/main10.jpg"
// import r11 from "../../assets/reviews/main11.jpg"
//
// import r12 from "../../assets/reviews/main12.jpg"
// import r13 from "../../assets/reviews/main13.jpg"
// import r14 from "../../assets/reviews/main14.jpg"
// import r15 from "../../assets/reviews/main15.jpg"
//
// const items = [
//     r01, r04, r03, r02, r05,
//     r06, r07, r08, r09, r10,
//     r11, r12, r13, r14, r15 ]

export function Reviews() {

    // const prefix = usePrefix()

    return <Box
        className={"reviews-section"}
        as={"section"}
        bg={"white"}
        textAlign={"center"}
        py={{ base: 8, md: 12 }}


    >
        <Box
            className={"reviews-container"}
            maxW={"4xl"}
            mx={"auto"}>
            <SectionHeader
                title={"수강생의 100% 리얼 후기"}
                description={"꿈을더하다는 오직 수강생의 성과로 증명합니다."}
                px={{ base: 6 }}
            />
            <HorizontalScroll
                className={"reviews-image-gallery"}
                items={imgs}
                aspect="portrait"
                w={"78vw"}
                maxW={"350px"}
                pl={{ base: 4 }}
            />
            {/*<Flex*/}
            {/*    className={"classes-cta-button-container"}*/}
            {/*    justify={"center"}*/}
            {/*    pb={{ base: 8, md: 4 }}*/}
            {/*    pt={{ base: 6, md: 12 }}*/}
            {/*    px={{base: 4}}*/}
            {/*    w={"full"}>*/}
            {/*    <Button*/}
            {/*        className={"classes-cta-go-to-testimonials"}*/}
            {/*        as={NavLink}*/}
            {/*        to={prefix("/testimonials")}*/}
            {/*        fontSize={"1.2rem"}*/}
            {/*        fontWeight={"bold"}*/}
            {/*        colorScheme={"secondary"}*/}
            {/*        // variant={"ghost"}*/}
            {/*        bg={"secondary.300"}*/}
            {/*        rounded={"md"}*/}
            {/*        shadow={"md"}*/}
            {/*        size={"lg"}*/}
            {/*        w={"full"} maxW={"xl"}>*/}
            {/*        더 많은 후기보기*/}
            {/*    </Button>*/}
            {/*</Flex>*/}
        </Box>
    </Box>
}