import { Stack, Text, Box, HStack } from "@chakra-ui/react"

export function CardHeaderTitleWithImage(props) {
    const { title, description, img, logoOnLeft, centerLogo, ...rest } = props
    // console.log("CardHeaderTitleWithImage", props)
    return <HStack
        // spacing="4"
        spacing={0}
        flexDirection={logoOnLeft ? "row-reverse": "row"}
        justify={centerLogo ? "space-around" : "space-between" }
        align={"flex-start"}
        {...rest}
    >
        { (title || description ) ? <Stack spacing="1">
            { title && <Text fontSize="lg" fontWeight="bold">
                { title }
            </Text> }
            { description && <Text color="muted" fontSize="sm">
                { description }
            </Text> }
        </Stack> : null }
        <Box>{ img }</Box>
    </HStack>
}