import * as React from "react"
import { VStack, Image, Highlight, Text, Heading } from "@chakra-ui/react"



export function Feature({ img, title, highlight, description }) {
    return <VStack
        className={"feature-container"}
        p={3}
        py={4}
        spacing={0}
        borderColor={"white"}
        rounded={"md"}
        bg={"whiteAlpha.100"}
        minH={"230px"}
        borderStyle={"solid"}
        borderWidth={"3px"}>
        <Image
            className={"feature-image"}
            src={img}
            h={"45px"}
            alt={title}
        />
        <Heading
            className={"feature-title"}
            as={"h5"}
            lineHeight={2.5}
            fontSize={"1.5rem"}
            color={"white"}
            wordBreak={"keep-all"}>
            <Highlight
                className={"feature-title-highlight"}
                query={highlight}
                styles={{ color: "yellow.400"}}>
                { title }
            </Highlight>
        </Heading>
        <Text
            className={"feature-description"}
            lineHeight={1.3}
            color={"white"}
            wordBreak={"keep-all"}>{ description }</Text>
    </VStack>

}