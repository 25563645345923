import {
    Box,
    Container,
    useColorModeValue,
    useStyleConfig,
} from "@chakra-ui/react"

export function Card({ children, ...rest }) {
    const styles = useStyleConfig("Card")
    // console.log("Card", styles)

    return (
        <Container maxW="3xl" {...rest} px={0}>
            <Box
                boxShadow={useColorModeValue("sm", "sm-dark")}
                borderRadius="sm"
                p={{ base: "4", md: "6" }}
                // borderTopWidth="4px"
                {...styles}
            >
                {children}
            </Box>
        </Container>
    )
}
