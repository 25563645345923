import * as React from "react"
import { Box, Stack, HStack, VStack, Icon, Text, Flex, AspectRatio } from "@chakra-ui/react"
import { FaQuoteRight, FaQuoteLeft } from "react-icons/fa"
import { SkeletonOptimizedImage } from "../../../components/optimized-image/skeleton-optimized-image"
import { SectionHeader } from "../section/section-header"

import imgs from "../../assets/team/min"

export function Team() {
    return <Box
        className={"team-section"}
        as={"section"}
        bg={"bg2.50"}
        textAlign={"center"}
        py={{ base: 8, md: 12 }}
        px={{ base: 4 }}>
        <Box
            className={"team-section-container"}
            mx={"auto"}
            maxW={"4xl"}>
            <SectionHeader
                title={"혁신적인 교육 법인 꿈을더하다"}
                description={"업계 최초 전문 강사제를 도입하여 체계적이고 정확한 교육을 실천합니다."}
            />
            <Stack
                className={"team-intro"}
                direction={{ base: "column", lg: "row" }}
                w={"full"}
                justify={{ lg: "space-between" }}
                spacing={{ base: 4, lg: 8 }}>

                <Flex
                    className={"team-intro-image-container"}
                    justify={"center"}
                    w={"full"}>
                    <AspectRatio ratio={1} w={"350px"}>
                        <SkeletonOptimizedImage
                            img={imgs.team}
                            imgProps={{
                                className: "team-intro-image",
                                alt: "꿈을더하다 대표회의",
                                rounded: "md",
                                w: "full",
                                h: "auto",
                                maxW: "450px",
                                maxH: "450px",
                            }}/>
                    </AspectRatio>

                </Flex>

                <VStack
                    className={"team-intro-text-container"}
                    textAlign={"left"}>
                    <HStack
                        className={"team-intro-text-quote-container"}
                        textAlign={"left"}
                        py={4}
                        position={"relative"}>
                        <Box
                            className={"team-intro-text-quote-mark"}
                            position={"absolute"}
                            top={"16px"}
                            left={0}>
                            <Icon
                                as={FaQuoteLeft}
                                w={"24px"}
                                h={"24px"}
                                color={"brand.500"}/>
                        </Box>
                        <Text
                            className={"team-intro-text-quote-title"}
                            px={8}
                            letterSpacing={"tighter"}
                            fontWeight={"extrabold"}
                            fontSize={"1.4rem"}
                            lineHeight={1.2}
                        >어떻게 하면 수강생 모두가 공구마켓으로 돈을 벌 수 있을까?</Text>
                        <Box
                            className={"team-intro-text-quote-mark"}
                            position={"absolute"}
                            bottom={"16px"}
                            right={0}>
                            <Icon
                                as={FaQuoteRight}
                                w={"24px"}
                                h={"24px"}
                                color={"brand.500"}/>
                        </Box>
                    </HStack>
                    <VStack
                        maxW={"2xl"}
                        className={"team-intro-text-description-container"}
                        px={1}>
                        <Text
                            className={"team-intro-text-description"}
                            fontSize={"md"}>
                            인스타마켓을 직접 운영하면서 많은 수강생들에게 판매 방법을 교육해 왔던 두 명이 손을 잡아 혁신적인 법인 교육체 (주)꿈을더하다를 설립하였습니다.</Text>
                        <Text
                            className={"team-intro-text-description"}
                            fontSize={"md"}>
                            2년 동안 약 500여명과 진행했던 1:1컨설팅 경험을 바탕으로 저희가 습득한 것들을 모두 나누고자 업계 최초로 단계별 강사님의 1:1레슨 및 그룹레슨을 도입하였고
                            매일 쏟아지는 핫한 공구상품을 논스탑으로 제공해 드리고 있습니다. 특히나 나만의 쇼핑몰까지 제공해 드리니 인스타를 활용한 판매는 더욱 간편해졌죠.</Text>
                        <Text
                            className={"team-intro-text-description"}
                            fontSize={"md"}>
                            누군가는 땀 흘리며 벌 수 있는 금액을 누군가는 틈틈이 휴대폰으로 손가락 움직이며 벌어갑니다. 바로 꿈을더하다 안에서요!</Text>
                        <Text
                            className={"team-intro-text-description"}
                            fontSize={"md"}>
                            인스타 하나로 방구석에서 밥벌이 하는 기술!
                            딱 한 번의 배움이면 충분합니다.
                            공구마켓에 도전하실 여러분들을 기다립니다.
                        </Text>
                    </VStack>
                </VStack>
            </Stack>
        </Box>
    </Box>
}