import * as React from "react"
import { Outlet } from "react-router-dom"
import { Box, useStyleConfig } from "@chakra-ui/react"

import { ScrollToTop } from "../../components/scroll-to-top"

export function MarketingLayout({ children }) {
    const style = useStyleConfig("AppLayout")

    return (
        <ScrollToTop className={"scroll-to-top"}>
            <Box className={"payments-layout"} minH={"100dvh"} sx={style}>
                {children || <Outlet />}
            </Box>
        </ScrollToTop>
    )
}