import * as React from "react"
import { Box, Text, Button, HStack, useStyleConfig } from "@chakra-ui/react"
import { NavLink } from "react-router-dom"


export function LoginNotMemberYet({ strings, link, from }) {

    const style = useStyleConfig("LoginNotMemberYet")

    return <Box mx="auto" align={"center"} mt={1} __css={style}>
        <HStack w={"full"} justify={"center"} spacing={1}>
            <Text as="span" fontSize={"md"} fontWeight={"bolder"}>{strings.notMemberYet}</Text>
            <Button
                as={NavLink}
                to={link}
                size={"sm"}
                state={{ from : { pathname: from }}}
                fontSize={"md"}
                variant={"ghost"}>{strings.signupButton}</Button>
        </HStack>
    </Box>
}