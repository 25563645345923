import { isNull, isUndefined, isEmpty } from "lodash"

import { useAuth } from "../contexts/auth-provider"
import { useConstants } from "../../contexts/constants-provider"
import { AppLoading } from "../../components/loading/app-loading"


export function AuthLoading({ children }) {
    const { currentUser, claims } = useAuth()
    const constants = useConstants()

    const { requiredClaims } = constants
    const currentUserHasntLoaded = isUndefined(currentUser)
    const doesntRequireClaims = isEmpty(requiredClaims)
    const requiresClaims = !doesntRequireClaims
    const claimsHaventLoaded = isNull(claims)

    console.log("currentUser", currentUser)

    return (currentUserHasntLoaded || (currentUser && requiresClaims && claimsHaventLoaded))
        ?
        <AppLoading />
        : ( children || null )
}