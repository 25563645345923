import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    useStyleConfig,
} from "@chakra-ui/react"

export function LoginError({ error, variant }) {
    const styles = useStyleConfig("AlertStyle", { variant })
    const message = error.message || "Something went wrong. Sorry."

    return (
        <Alert status="error" {...styles}>
            <AlertIcon />
            {error.title ? <AlertTitle mr={2}>{error.title}</AlertTitle> : null}
            <AlertDescription>{message}</AlertDescription>
        </Alert>
    )
}
