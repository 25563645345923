import * as React from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { Box, chakra, Stack, Text } from "@chakra-ui/react"
import { isEmpty } from "lodash"

import { ShellSidebarLink } from "./shell-sidebar-link"
// import { ShellSidebarProtectedLink } from "./shell-sidebar-protected-link"

export function ShellSidebarLinkGroup({ group, menuItems, onClose }) {
    const navigate = useNavigate()
    return (
        <Box w={"full"} mb={4} className={"shell-sidebar-group-container"} px={2}>
            {group && (
                <Text
                    className={"shell-sidebar-group-name"}
                    fontSize="xs"
                    fontFamily={"Montserrat"}
                    color={"muted"}
                    textTransform="uppercase"
                    mb={{ base: 3, lg: 2 }}
                >
                    {group}
                </Text>
            )}
            <Stack
                className={"shell-sidebar-group-list"}
                spacing={{ base: 3, lg: 4 }}
                as="ul"
                listStyleType="none"
            >
                {menuItems.map((item, ix) => {
                    const buttonProps = item.href
                        ? { as: "a", href: item.href }
                        : {
                            as: NavLink,
                            to: item.path,
                            onClick() {
                                onClose()
                                navigate(item.path)
                            },
                        }
                    return (
                        <chakra.li
                            key={ix}
                            pl={1}
                            className={"shell-sidebar-group-list-item"}
                        >
                            {isEmpty(item.requiredClaims.access) ? (
                                <ShellSidebarLink
                                    key={ix}
                                    label={item.label}
                                    {...buttonProps}
                                />
                            ) :
                                null
                                // (<ShellSidebarProtectedLink
                                //     key={ix}
                                //     menuItem={item}
                                //     label={item.label}
                                //     onClose={onClose}
                                // />)
                            }
                        </chakra.li>
                    )
                })}
            </Stack>
        </Box>
    )
}