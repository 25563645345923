export function useMarketingMenuItems() {
    const menuItems = [
        {
            group: "",
            label: "Home",
            labelStyle: {},
            path: "/",
            sidebar: true,
            footerOnly: false,
            requiredClaims: {
                access: [],
                display: [],
            },
        },
        {
            group: "",
            label: "Curriculum",
            labelStyle: {},
            path: "/curriculum",
            sidebar: true,
            footerOnly: false,
            requiredClaims: {
                access: [],
                display: [],
            },
        },
        {
            group: "",
            label: "성공스토리",
            labelStyle: {fontFamily: "'Noto Sans KR'"},
            path: "/testimonials",
            sidebar: true,
            footerOnly: false,
            requiredClaims: {
                access: [],
                display: [],
            },
        },
        {
            group: "",
            label: "Blog",
            labelStyle: {},
            path: "/blog",
            sidebar: true,
            footerOnly: false,
            requiredClaims: {
                access: [],
                display: [],
            },
        },
        {
            group: "",
            label: "Agreement",
            labelStyle: {},
            path: "/terms",
            sidebar: false,
            footerOnly: true,
            requiredClaims: {
                access: [],
                display: [],
            },
        },
        {
            group: "",
            label: "Privacy Policy",
            labelStyle: {},
            path: "/terms",
            sidebar: false,
            footerOnly: true,
            requiredClaims: {
                access: [],
                display: [],
            },
        },
    ]

    return { menuItems }
}