import classes00avif from "./공구클래스.avif"
import classes00jpg from "./공구클래스.jpg"
import classes00webp from "./공구클래스.webp"
import classes01avif from "./도매_클래스.avif"
import classes01jpg from "./도매_클래스.jpg"
import classes01webp from "./도매_클래스.webp"
import classes02avif from "./사진_클래스.avif"
import classes02jpg from "./사진_클래스.jpg"
import classes02webp from "./사진_클래스.webp"
import classes03avif from "./홍보물_클래스.avif"
import classes03jpg from "./홍보물_클래스.jpg"
import classes03webp from "./홍보물_클래스.webp"

export const imgs = {
    sales: {
        avif: classes00avif,
        src: classes00jpg,
        webp: classes00webp
    },
    wholesale: {
        avif: classes01avif,
        src: classes01jpg,
        webp: classes01webp
    },
    photography: {
        avif: classes02avif,
        src: classes02jpg,
        webp: classes02webp
    },
    publicity: {
        avif: classes03avif,
        src: classes03jpg,
        webp: classes03webp
    }
}
export default imgs