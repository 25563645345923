import * as React from "react"
import { Box } from "@chakra-ui/react"
import { Helmet } from "react-helmet-async"
import { useParams } from "react-router-dom"
import { Hero } from "../components/hero/hero"
import { BlogPost } from "../components/blog-post/blog-post"
import { KakaoChannelButton } from "../components/kakao/kakao-channel-button"

export function BlogPostPage() {
    const { id } = useParams()
    const { href } = window.location

    return <>
        <Helmet>
            <meta property={"og:url"} content={href} />
            <title>꿈을더하다 - 블로그</title>
            <meta property="og:description"
                  content={"연필 한자루 팔아본 적 없는 육아맘도, 인스타 하나로 협찬 받으며 공구마켓을 운영할 수 있습니다. 지금 바로 도전해보세요!"}/>
            <link rel="canonical" href={`https://dream-more.kr/blog/${id}`} />
        </Helmet>
        <Box display={{base: "none", md: "block"}}>
            <Hero />
        </Box>
        <BlogPost/>
        <KakaoChannelButton/>
    </>
}
