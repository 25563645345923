import recommendations00avif from "./워크라이프밸런스.avif"
import recommendations00jpg from "./워크라이프밸런스.jpg"
import recommendations00webp from "./워크라이프밸런스.webp"
import recommendations01avif from "./재택근무.avif"
import recommendations01jpg from "./재택근무.jpg"
import recommendations01webp from "./재택근무.webp"

export const imgs = {
    wlb: {
        avif: recommendations00avif,
        src: recommendations00jpg,
        webp: recommendations00webp,
    },
    wfh: {
        avif: recommendations01avif,
        src: recommendations01jpg,
        webp: recommendations01webp,
    },
}
export default imgs
