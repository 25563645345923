import { mode } from "@chakra-ui/theme-tools"

export const authThemes = {
    colors: {

    },
    components: {
        AuthLayoutStyle: {
            baseStyle: props => ({
                background: mode('gray.50', 'inherit')(props),
            })
        },
        AuthPageHeaderStyle: {},
        AuthSignupPageStyle: {},
        LoginNotMemberYet: {
            baseStyle: {
                // color: "white"
            }
        },
    }
}