import * as React from "react"
import { Box, Button, SimpleGrid, VStack } from "@chakra-ui/react"
import { NavLink } from "react-router-dom"
import Observer from "@researchgate/react-intersection-observer"
import { collection, limit, orderBy, query, where, startAfter } from "firebase/firestore"
import { useFirestoreInfiniteQuery } from "@react-query-firebase/firestore"
import { useFirestore } from "reactfire"
import { isEmpty, chain } from "lodash"

import { SectionHeader } from "../section/section-header"
import { BlogListItem } from "./blog-list-item"
import { BlogCard } from "./blog-card"

import { Loading } from "../../../components/loading/loading"
import { BlogListItemSkeleton } from "./blog-list-item-skeleton"
import { BlogCardSkeleton } from "./blog-card-skeleton"
import { usePrefix } from "../../contexts/prefix-provider"
import { ContactUsButton } from "../contact-us-button/contact-us-button"

export function BlogList({ infiniteScroll = true }) {

    const db = useFirestore()
    const prefix = usePrefix()

    const pageLimit = 18
    const [ isVisible, setIsVisible ] = React.useState(false)

    const [ posts, setPosts ] = React.useState([])

    const postsCollection = collection(db, "blogs-v2")

    const postsQuery = query(postsCollection,
        where("isHidden", "==", false),
        orderBy("date", "desc"),
        limit(pageLimit))

    const blogs = useFirestoreInfiniteQuery("blogs-v2", postsQuery, (snapshot) => {
        const lastDocument = snapshot.docs[snapshot.docs.length - 1]
        if (!lastDocument) return
        return query(postsQuery, startAfter(lastDocument))
    }, {}, {})

    React.useEffect(() => {
        if (isEmpty(blogs.data)) return
        setPosts(chain(blogs.data.pages)
            .map(snapshot => snapshot.docs)
            .flatten()
            .map(post => ({ ...post.data(), date: post.data().date.toDate() }))
            .value())
    }, [ blogs.data ])

    React.useEffect(() => {
        if (!infiniteScroll) return
        if (!isVisible) return
        if (blogs.isLoading) return
        if (blogs.isFetchingNextPage) return
        if (!blogs.hasNextPage) return
        blogs.fetchNextPage()
    }, [ isVisible, blogs, infiniteScroll])

    // console.log("blogs", blogs)

    if (isEmpty(posts)) return null

    return <Box
        className={"blogs-section"}
        as="section"
        textAlign={"center"}
    >
        <Box
            className={"blogs-container"}
            pt={{ base: 8, md: 12 }}
            pb={{ base: 0, md: 12 }}
            px={{ base: 0 }}
            maxW={"5xl"}
            mx={"auto"}
        >
            <SectionHeader
                title={"꿈을더하다 블로그"}
                description={"꿈을더하다 블로그에는 그 동안 쌓인 노하우 및 기록, 정보들로 가득합니다!"}
            />

            <VStack
                className={"blog-list-stack"}
                spacing={1}
                display={{ base: "flex", md: "none" }}>
                {
                    blogs.isLoading
                        ?
                        Array.from(Array(20)).map((blog, idx) => <BlogListItemSkeleton key={idx}/>)
                        :
                        posts.map((blog, idx) => <BlogListItem key={idx} blog={blog}/>)
                }
            </VStack>
            <SimpleGrid
                className={"blogs-list blog-list-grid"}
                columns={[ 1, 2, 2, 3 ]}
                spacing={[ 4 ]}
                display={{ base: "none", md: "grid" }}
                px={[ 6, 4, 4 ]}
                // mt={["-40px", null, "-30px"]}
            >
                {
                    blogs.isLoading
                        ?
                        Array.from(Array(20)).map((blog, idx) => <BlogCardSkeleton key={idx}/>)
                        :
                        posts.map((blog, idx) => <BlogCard key={idx} blog={blog}/>)
                }
            </SimpleGrid>
            {
                infiniteScroll
                    ?
                    <Observer onChange={e => setIsVisible(e.isIntersecting)}>
                        <Box
                            w={"full"}
                            minH={"1px"}
                            my={6}
                        >
                            <Loading display={
                                isVisible && !blogs.isLoading && blogs.hasNextPage
                                    ? "block" : "none"}/>
                        </Box>
                    </Observer>
                    :
                    <VStack
                        maxW={"2xl"}
                        mx={"auto"}
                        pt={2}
                        px={{ base: 4, md: 0 }}
                        justify={"space-between"}
                        spacing={0}
                    >
                        <Button as={NavLink} to={prefix("/blog")} minW={"48%"} mb={2} size={"lg"} w={"full"} rounded={"md"} shadow={"md"}>Read More</Button>
                        <ContactUsButton
                            justify={"center"}
                            w={"full"}
                            ml={0}
                            minW={"48%"}

                            btnProps={{ w: "full", size: "lg", rounded: "md", shadow: "md" }}>
                            바로 상담하기 💬
                        </ContactUsButton>
                    </VStack>
            }

        </Box>
    </Box>
}