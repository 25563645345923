import { Box, Stack, Divider } from "@chakra-ui/react"
import { Form, Formik } from "formik"
import { SubmitButton } from "formik-chakra-ui"
import { EmailInputControl } from "../../components/forms/email-input-control"
import { PasswordControl } from "../../components/forms/password-control"

import { Card } from "../../components/cards/card"
import { CardHeaderTitleWithImage } from "../../components/cards/card-header-title-with-image"
import { LoginError } from "./login-error"

export function LoginForm({
    schema,
    onSubmit,
    strings,
    error,
    setError,
    forgotRoute,
    title,
    description,
    logo,
    logoOnLeft,
    centerLogo,
}) {
    return (
        <Card>
            {(title || description || logo) && (
                <>
                    <CardHeaderTitleWithImage
                        title={title}
                        description={description}
                        img={logo}
                        logoOnLeft={logoOnLeft}
                        centerLogo={centerLogo}
                    />
                    <Divider mb={3} />
                </>
            )}
            <Formik
                initialValues={schema.cast()}
                onSubmit={onSubmit}
                validationSchema={schema}
            >
                {(props) => {
                    // console.log("formik", props)
                    return (
                        <Box as={Form} pt={1}>
                            <Stack spacing="6">
                                {error.message ? (
                                    <LoginError error={error} />
                                ) : null}
                                <EmailInputControl
                                    name={"email"}
                                    label={strings.emailLabel}
                                    inputProps={{
                                        autoFocus: true,
                                        onBlur: (e) => {
                                            setError("")
                                            props.handleBlur(e)
                                        },
                                    }}
                                    schema={schema}
                                />
                                <PasswordControl
                                    name={"password"}
                                    label={strings.passwordLabel}
                                    forgotLink={forgotRoute}
                                />
                                <SubmitButton
                                    size="lg"
                                    fontSize="md"
                                    colorScheme={"brand"}
                                >
                                    {strings.submitButton}
                                </SubmitButton>
                            </Stack>
                        </Box>
                    )
                }}
            </Formik>
        </Card>
    )
}
