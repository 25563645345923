import * as React from "react"
import { useIsVisible } from "react-is-visible"
import { Flex } from "@chakra-ui/react"
import { last } from "lodash"

import { Dot } from "../components/scroll/dot"

export function Dots({ images, isActive }) {
    return (
        <Flex
            className={"scroll-menu-dots-container"}
            w={"full"}
            justify={"center"}
            py={3}
            pr={{ base: 4 }}
        >
            {images.map((img, i) => (
                <Dot key={i} isActive={i === isActive} />
            ))}
        </Flex>
    )
}

export function useHorizontalScroll() {
    const [active, setActive] = React.useState(0)

    const ref = React.useRef()
    const isVisible = useIsVisible(ref, { once: true })

    const onScroll = (context) => {
        const firstItem = context.getItemByIndex(0)
        if (context.isItemVisible(firstItem)) {
            setActive(0)
            return
        }

        let [firstIndex] = last(context.visibleItems).split("-")
        firstIndex = Number.parseInt(firstIndex, 10)
        if (firstIndex !== active) {
            setActive(firstIndex)
        }
    }

    const getScrollMenuProps = () => ({
        onScroll,
    })

    return { ref, active, setActive, isVisible, getScrollMenuProps, Dots }
}
