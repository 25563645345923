import * as React from "react"
import { Box, useDisclosure, useBreakpointValue } from "@chakra-ui/react"
import { Outlet } from "react-router-dom"
import { AnimatePresence } from "framer-motion"

import { MarketingLayout } from "./marketing-layout"
import { MarketingNav } from "../components/nav/marketing-nav"
import { Footer } from "../components/footer/footer"

import { PrefixProvider } from "../contexts/prefix-provider"

export function MarketingApp({ routes, prefix = "", children }) {

    const menu = useDisclosure()
    const headerHeight = useBreakpointValue({ base: "64px", xl: "48px" })

    const newContext = { menu, routes, header: { height: headerHeight } }

    const createOutlet = () => {
        if (children) {
            const childrenWithProps = React.Children.map(children, (child, ix) => {
                // Checking isValidElement is the safe way and avoids a typescript
                // error too.
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, { key: ix, ...newContext })
                }
                return child
            })

            return <Box className={"marketing-app"}>{childrenWithProps}</Box>
        }
        return <Outlet context={newContext}/>
    }

    const setPrefix = React.useCallback(link => {
        // console.log(link, prefix)
        return link === "/" && prefix ? prefix : `${prefix}${link}`
    }, [prefix])

    return (
        <MarketingLayout>
            <PrefixProvider value={setPrefix}>
                <AnimatePresence>
                    <MarketingNav menu={menu} routes={routes} header={{ height: headerHeight }}/>
                </AnimatePresence>
                <Box
                    className={"marketing-layout-container"}
                    bg={"bg2.50"}
                    pt={headerHeight}>
                    {createOutlet()}
                </Box>
                <Footer routes={routes}/>
            </PrefixProvider>
        </MarketingLayout>
    )
}