import * as React from "react"
import {
    chakra,
    Box,
    Image,
    Flex,
    useBreakpointValue,
    VStack,
    Badge,
    Accordion,
    AccordionItem,
    AccordionPanel,
    AccordionButton,
    Button,
} from "@chakra-ui/react"
import { DownloadIcon } from "@chakra-ui/icons"

import { SectionHeader } from "../section/section-header"
import { SkeletonOptimizedImage } from "../../../components/optimized-image/skeleton-optimized-image"
import { OptimizedImage } from "../../../components/optimized-image/optimized-image"

import imgs from "../../assets/awards/min"

export function Award() {
    const subtitle = "창립 2년만에 공구교육 1위달성!"

    const img = useBreakpointValue({
        base: imgs.mobile,
        md: imgs.pc,
    })

    const imgProps = useBreakpointValue({
        base: {
            // w: "350px",
            // h: "424px",
        },
        md: {},
    })

    return (
        <Box
            className={"award-section"}
            as={"section"}
            mx={"auto"}
            py={{ base: 4, md: 6 }}
            maxW={"4xl"}
        >
            <SectionHeader subtitle={subtitle} pb={{ base: 6, md: 10 }} />
            <Flex justify={"center"} px={4}>
                <SkeletonOptimizedImage
                    img={img}
                    skeletonProps={{ mb: { base: 2, md: 4 } }}
                    imgProps={{
                        className: "award-image",
                        shadow: "lg",
                        mx: "auto",
                        mb: { base: 2, md: 4 },
                        ...imgProps,
                    }}
                />
            </Flex>

            <Flex
                className={"award-article-container"}
                w={"full"}
                justify={"center"}
            >
                <Accordion
                    className={"award-article-accordian"}
                    w={"full"}
                    maxW={"xl"}
                    allowToggle
                >
                    <AccordionItem
                        className={"award-article-accordian-item"}
                        border={"none"}
                    >
                        <Box
                            className={
                                "award-article-accordian-button-container"
                            }
                            px={4}
                        >
                            <AccordionButton
                                className={"award-article-accordian-button"}
                                bg={"white"}
                                _hover={{
                                    bg: "white",
                                }}
                                variant={"link"}
                                rounded={"md"}
                                shadow={"md"}
                                px={8}
                            >
                                <Flex
                                    className={
                                        "award-article-accordian-button-stack"
                                    }
                                    // as="span"
                                    // flex="1"
                                    w={"full"}
                                    textAlign="left"
                                    align={"center"}
                                    // position={"relative"}
                                    justify={"space-between"}
                                >
                                    <Badge
                                        className={
                                            "award-article-accordian-button-new-badge"
                                        }
                                        // top={"-14px"}
                                        // left={"-14px"}
                                        // position={"absolute"}
                                        h={"26px"}
                                        size={"sm"}
                                        rounded={"lg"}
                                        colorScheme={"green"}
                                        borderColor={"green.500"}
                                        borderStyle={"solid"}
                                        borderWidth={"1px"}
                                        fontWeight={"bold"}
                                    >
                                        NEW
                                    </Badge>
                                    <OptimizedImage
                                        img={imgs.masthead}
                                        imgProps={{
                                            className:
                                                "award-article-accordian-button-joongang-logo",
                                            h: "30px",
                                            display: "inline-block",
                                            alt: "중앙일보 로고",
                                        }}
                                    />
                                    {/*<chakra.picture>*/}
                                    {/*    <Image as="source" srcSet={imgs.masthead.avif} type="image/avif" />*/}
                                    {/*    <Image as="source" srcSet={imgs.masthead.webp} type="image/webp"/>*/}
                                    {/*    <Image*/}
                                    {/*        className={"award-article-accordian-button-joongang-logo"}*/}
                                    {/*        src={imgs.masthead.src}*/}
                                    {/*        h={"30px"}*/}
                                    {/*        display={"inline-block"}*/}
                                    {/*        alt={"중앙일보 로고"}*/}
                                    {/*    />*/}
                                    {/*</chakra.picture>*/}

                                    <Button
                                        rightIcon={<DownloadIcon />}
                                        as={"span"}
                                        size={"sm"}
                                        bg={"bg2.300"}
                                        className={
                                            "award-article-accordian-button-text"
                                        }
                                        letterSpacing={"-.03rem"}
                                        fontSize={"16px"}
                                        rounded={"lg"}
                                        shadow={"none"}
                                        fontWeight={"bold"}
                                    >
                                        기사 읽기
                                    </Button>
                                </Flex>
                                {/*<AccordionIcon/>*/}
                            </AccordionButton>
                        </Box>
                        <AccordionPanel
                            className={"award-article-accordian-panel"}
                        >
                            <VStack
                                className={
                                    "award-article-accordian-panel-stack"
                                }
                                w={"full"}
                                justify={"center"}
                            >
                                <chakra.picture>
                                    <Image
                                        as="source"
                                        srcSet={imgs.article.avif}
                                        type="image/avif"
                                    />
                                    <Image
                                        as="source"
                                        srcSet={imgs.article.webp}
                                        type="image/webp"
                                    />
                                    <Image
                                        className={"award-article-image"}
                                        alt={"중앙일보 기사"}
                                        pt={2}
                                        shadow={"lg"}
                                        src={imgs.article.src}
                                    />
                                </chakra.picture>

                                {/*<Link*/}
                                {/*    target={"_blank"}*/}
                                {/*    color={"twitter.700"}*/}
                                {/*    py={3}*/}
                                {/*    isExternal*/}
                                {/*    textDecoration={"underline"}*/}
                                {/*    href={"https://www.joongang.co.kr/article/25121184"}>*/}
                                {/*    https://www.joongang.co.kr/article/25121184*/}
                                {/*    <ExternalLinkIcon mx={1} mb={"5px"}/>*/}
                                {/*</Link>*/}
                            </VStack>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </Flex>
        </Box>
    )
}
