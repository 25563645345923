import * as React from "react"

import { Box, AspectRatio, VStack, Text, Flex, useBreakpointValue } from "@chakra-ui/react"
import { CallToActionStack } from "../call-to-action/call-to-action"
import { OptimizedImage } from "../../../components/optimized-image/optimized-image"

import { LogoLight } from "../../../dreammore/components/logo"

import { imgs as arrows } from "../../../components/assets/hero/min"
import imgs from "../../assets/hero/min"

export function Hero() {

    const heroSplash = useBreakpointValue({ base: imgs.sm, md: imgs.md, lg: imgs.lg, xl: imgs.xl })

    const ratio = { base: .84, sm: 1.4, md: 16 / 9, lg: 2.0, xl: 2.54 }


    return <Box
        as={"header"}
        className={"hero"}
    >
        <AspectRatio
            className={"hero-container"}
            ratio={ratio}>
            <Box
                className="hero-wrapper"
                position={"relative"} w={"full"} h={"full"}>
                <Box
                    className="hero-bg-container"
                    position={"absolute"} w={"full"}>
                    <AspectRatio
                        className="hero-bg-wrapper"
                        ratio={ratio}>
                        <OptimizedImage
                            img={heroSplash}
                            pictureProps={{ width: "full" }}
                            imgProps={{
                                className: "hero-bg-image",
                                w: "full",
                                h: "full",
                                alt: "꿈을더하다 이미지",
                                overflow: "hidden",
                                objectFit: "cover",
                                objectPosition: "right 60%",
                            }}
                        />
                        {/*<chakra.picture w={"full"}>*/}
                        {/*    <Image*/}
                        {/*        as={"source"}*/}
                        {/*        srcSet={heroSplash.avif}*/}
                        {/*        type={"image/avif"}*/}
                        {/*    />*/}
                        {/*    <Image*/}
                        {/*        as={"source"}*/}
                        {/*        srcSet={heroSplash.webp}*/}
                        {/*        type={"image/webp"}*/}
                        {/*    />*/}

                        {/*    <Image*/}
                        {/*        className={"hero-bg-image"}*/}
                        {/*        src={heroSplash.src}*/}
                        {/*        w={"full"}*/}
                        {/*        h={"full"}*/}
                        {/*        alt="꿈을더하다 이미지"*/}
                        {/*        overflow={"hidden"}*/}
                        {/*        objectFit="cover"*/}
                        {/*        objectPosition={"right 60%"}/>*/}
                        {/*</chakra.picture>*/}
                    </AspectRatio>
                </Box>

                <Box
                    className="hero-bg-overlay-container"
                    position={"absolute"} w={"full"}>
                    <AspectRatio
                        className="hero-bg-overlay-wrapper"
                        ratio={ratio}>
                        <Box
                            className="hero-bg-overlay"
                            w={"100vw"} bg={'brand.700'} opacity={.5}/>
                    </AspectRatio>
                </Box>
                <Box
                    className={"hero-arrow-box-container"}
                    // maxW={"8xl"}
                    w={"full"}
                    h={"10px"}
                    position={"absolute"}
                    top={0}>
                    <Box
                        className={"hero-arrow-box-wrapper"}
                        position={"absolute"}
                        right={0}
                        top={0}
                        py={1}
                        px={{ base: 3, lg: 6, xl: 6 }}>
                        <VStack className={"hero-arrow-box-vstack"}>
                            <OptimizedImage
                                img={arrows.arrowToUpWhite}
                                imgProps={{
                                    className: "hero-arrow-box-img",
                                    alt: "click here arrow",
                                    w: "80px",
                                    h: "auto",
                                }}
                            />
                            <Text
                                className={"hero-arrow-box-text"}
                                letterSpacing={"tight"}
                                fontWeight={"bold"}
                                bg={"kakao"}
                                color={"gray.700"}
                                fontSize={{ base: "md", md: "xl" }} px={2}>
                                무료상담하기!
                            </Text>
                        </VStack>
                    </Box>
                </Box>

                <Box
                    className={"hero-logo-container"}
                    position={"absolute"}
                    w={"full"}>
                    <AspectRatio
                        className={"hero-logo-wrapper"}
                        ratio={ratio}>
                        <Box
                            w={"100vw"}
                            // maxW={"3xl"}
                            h="full"
                            mx={"auto"}>
                            <VStack
                                className={"hero-logo-and-copy-vstack"}
                                justify={"space-between"}
                                h={"full"}
                                w={"full"}
                                pt={{ base: 6, lg: 12 }}
                                pb={{ base: 4, lg: 12 }}
                                align={"flex-start"}>
                                <Flex
                                    className={"hero-logo-img-light-container"}
                                    w={"full"}
                                    px={{ base: 6, md: 8, lg: 12 }}
                                    mx={"auto"}
                                    maxW={"6xl"}
                                >
                                    <LogoLight
                                        className={"hero-logo-img-light"}
                                        imgProps={{ maxH: { base: "65px", md: "80px" } }}/>
                                </Flex>

                                <VStack
                                    className={"hero-copy-cta-vstack"}
                                    w={"full"}
                                    spacing={{ base: 5, }}
                                >
                                    <VStack
                                        className={"hero-copy-vstack"}
                                        align={"center"}
                                        w={"full"}
                                        textAlign={"center"}
                                        py={{ base: 2, md: 10 }}
                                        px={{ base: 6, md: 8 }}
                                        spacing={{ base: 2, md: 4 }}
                                        bg={"blackAlpha.400"}
                                    >
                                        <Text
                                            as={"h2"}
                                            className={"hero-copy-text description"}
                                            fontFamily={"'Noto Sans KR'"}
                                            fontSize={{ base: "1.0rem", md: "1.5rem" }}
                                            fontWeight={400}
                                            color={"white"}
                                            lineHeight={1.2}
                                            maxW={"3xl"}
                                        >
                                            연필 한자루 팔아본 적 없는 육아맘도, 인스타 하나로 협찬 받으며 공구마켓을 운영할 수 있습니다
                                        </Text>
                                        <Text
                                            className={"hero-copy-text description"}
                                            fontSize={{ base: "1.2rem", md: "1.5rem" }}
                                            letterSpacing={"tighter"}
                                            color={"white"}
                                            lineHeight={1.2}
                                            fontWeight={600}
                                            maxW={"3xl"}
                                        >
                                            집에서 틈틈이 일하며{" "}
                                            <Text as="span" display={{ base: "inline-block", md: "none" }}><br/></Text>
                                            <Text as="span" color={"kakao"}>
                                                놀라운 워라벨</Text>을 경험해보세요.
                                        </Text>
                                    </VStack>
                                    <Flex
                                        className={"hero-cta-button-container"}
                                        w={"full"}
                                        px={{ base: 4, lg: 8 }}
                                        justify={"center"}
                                    >
                                        <CallToActionStack/>
                                    </Flex>
                                </VStack>
                            </VStack>
                        </Box>
                    </AspectRatio>
                </Box>
            </Box>
        </AspectRatio>
    </Box>
}