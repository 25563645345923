import { ChakraProvider, extendTheme } from "@chakra-ui/react"
import { withProse } from '@nikolovlazar/chakra-ui-prose'
// import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui"

import { createSiteTheme } from "./index"

import "@fontsource/montserrat"
import "@fontsource/inter/variable.css"
import "@fontsource/noto-sans-kr"

export function useThemes(plugins) {
    return plugins.map(plugin => plugin.themes)
}

export function ChakraUiTheme({ themes, children }) {

    const getTheme = name => themes
        .reduce(
            (o, theme) => ({...o, ...(theme[name] || {})}),
            {})
    // console.log("themes", themes)
    const siteTheme = createSiteTheme({
        fonts: getTheme("fonts"),
        colors: getTheme("colors"),
        components: getTheme("components")
    })

    const extendedTheme = extendTheme(siteTheme,
        withProse({
        baseStyle: {

        }
    }))

    const theme = createSiteTheme(extendedTheme)

    // console.log("siteTheme", theme)


    return <ChakraProvider theme={theme}>
        {/*<ThemeEditorProvider>*/}
            {children}
        {/*</ThemeEditorProvider>*/}
    </ChakraProvider>

}