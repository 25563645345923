import hero00avif from "./hand-drawn-doodle-arrow-to-left-png-21635250571meui8uutqb.avif"
import hero00png from "./hand-drawn-doodle-arrow-to-left-png-21635250571meui8uutqb.png"
import hero00webp from "./hand-drawn-doodle-arrow-to-left-png-21635250571meui8uutqb.webp"
import hero01avif from "./hand-drawn-doodle-arrow-to-left-png-white.avif"
import hero01png from "./hand-drawn-doodle-arrow-to-left-png-white.png"
import hero01webp from "./hand-drawn-doodle-arrow-to-left-png-white.webp"

export const imgs = {
    arrowToLeftBlack: {
        avif: hero00avif,
        src: hero00png,
        webp: hero00webp,
    },
    arrowToUpWhite: {
        avif: hero01avif,
        src: hero01png,
        webp: hero01webp,
    },
}
export default imgs
