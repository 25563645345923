import * as React from "react"
import { useLocation, Navigate } from "react-router-dom"
import { isEmpty } from "lodash"
import { useAuth } from "../contexts/auth-provider"
import { useConstants } from "../../contexts/constants-provider"

export function RequireAuth({ children }) {
    const auth = useAuth()
    const constants = useConstants()
    const location = useLocation()

    // console.log("RequireAuth", constants)
    const requiredClaims = constants.auth.requiredClaims

    const requiresClaims = !isEmpty(requiredClaims)
    const hasClaims = auth?.claims && requiredClaims.some(claim => auth.claims[claim])
    const doesntHaveClaims = !hasClaims

    const defaultAuthRoute = constants.auth.defaultAuthRoute === "signup"
        ? constants.auth.signupRoute : constants.auth.loginRoute

    // console.log("defaultAuth", defaultAuthRoute)

    if (!auth.currentUser ||
        (auth.currentUser && requiresClaims && doesntHaveClaims)) {
        return <Navigate to={ defaultAuthRoute } state={{ from: location }} replace />;
    }

    return children
}